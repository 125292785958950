import { gtm as e, GTMProducts as d, GTMProductTower as r } from "@cvccorp-engines/components";
import { d as c } from "../dayjs-CxYy674R.js";
const D = (t) => {
  const a = c(t.calendarDates.startDate), o = c(t.calendarDates.endDate);
  return {
    checkin_date: e.formatDate(a.toDate()),
    checkout_date: e.formatDate(o.toDate()),
    duration_days: o.diff(a, "days").toString(),
    product: d.RESORT,
    product_tower: t.destination === "true" ? r.INTERNATIONAL : r.NATIONAL
  };
};
export {
  D as formatStateToGTM
};
