import a from "react";
import { Pesqinc as l } from "@cvccorp-engines/components";
import { useEngineBusingContext as m } from "../../../context/EngineBusingContext.js";
import u from "./useFetchSingleBusing.js";
const g = ({
  searchType: e,
  ...t
}) => {
  const {
    pesqincLocationSelected: n,
    setPesqincLocationSelected: o
  } = m(), {
    options: i,
    onSearch: r,
    renderOption: s,
    onSelect: c
  } = u({
    searchType: e,
    onSelect: o
  });
  return /* @__PURE__ */ a.createElement(l, { ...t, onSearch: r, options: i, renderOption: s, onSelect: c, value: n[e.toLowerCase()] });
};
g.displayName = "SingleBusingCharter";
export {
  g as default
};
