import { Skeleton, Grid } from "@cvccorp-components/chui-react-components";
import styled from "@emotion/styled";

export const NavigationContainer = styled(Grid)`
    &&& {
        gap: 24px;
        overflow: hidden;
        flex-wrap: nowrap;
        margin-bottom: 2rem;
    }
`;

export const IconSkeleton = styled(Skeleton)`
    height: 20px;
    width: 20px;
`;

export const NavigationItemContainer = styled(Grid)`
    flex: 1;
    max-width: 120px;
    gap: 2px;
`;

export const EngineFieldSkeletonContainer = styled(Grid)`
    gap: 12px;
    overflow: hidden;
    flex-wrap: nowrap;
`;

export const EngineFieldSkeleton = styled(Skeleton)`
    flex: 1;
    height: 54px;
`;
