import { useCallback as e } from "react";
function c({
  errors: t
}) {
  return {
    getErrorMessage: e((i) => {
      var a, n;
      switch (i) {
        case "startDate":
          return (a = t == null ? void 0 : t.calendarDates) != null && a.startDate ? "Preencha a data de ida" : "";
        case "endDate":
          return (n = t == null ? void 0 : t.calendarDates) != null && n.endDate ? "Preencha a data de volta" : "";
        case "destination":
          return t != null && t.destination ? "Preencha o campo de destino" : t != null && t.originDestination ? " " : "";
        case "origin":
          return t != null && t.origin ? "Preencha o campo de origem" : t != null && t.originDestination ? "Origem e Destino não podem ser iguais" : "";
        default:
          return "";
      }
    }, [t])
  };
}
export {
  c as default
};
