import t from "react";
import { d as r } from "../../../dayjs-CxYy674R.js";
import { useEngineBusingContext as o } from "../../context/EngineBusingContext.js";
import m from "./CalendarPicker/CalendarPicker.js";
import d from "./CalendarRangePicker/CalendarRangePicker.js";
const i = () => {
  const {
    isOneWay: n
  } = o(), e = r().add(1, "days").toDate(), a = r().add(180, "days").toDate();
  return n ? /* @__PURE__ */ t.createElement(m, { minDate: e, maxDate: a }) : /* @__PURE__ */ t.createElement(d, { minDate: e, maxDate: a });
};
i.displayName = "Calendar";
export {
  i as default
};
