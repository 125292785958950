import { getBaseHostname as Y, formatMonthToParam as _, formatDateToParam as Q, generateSearchParams as s, encodeSearchURL as p } from "@cvccorp-engines/components";
import { calcChildAges as a } from "@cvccorp-engines/pax";
import { removeEmptyValueSearch as m } from "./removeEmptyValueSearch.js";
function ei(i) {
  var g, n, c, e, y, D, h, l, O, I, u, C, S, T, A, f, L, j, x, P, z, Z, b, r, R, U, k, w, M, v, B, E, H, V, $, q, F, G, J, K, N;
  const d = new URL(`${Y()}/pacotes/search`), W = a(i.pax), t = {
    Rooms: String((g = i.pax) == null ? void 0 : g.length),
    Adults: (c = (n = i.pax) == null ? void 0 : n.map) == null ? void 0 : c.call(n, (o) => o.adult).join(","),
    Children: (y = (e = i.pax) == null ? void 0 : e.map) == null ? void 0 : y.call(e, (o) => o.children).join(","),
    ChildAges: W,
    SearchType: i.searchType,
    Classes: "eco",
    ...i.isMonthDates ? {
      Dates: _(i.calendarDates)
    } : {
      CheckIn: Q(i.calendarDates[0].toString()),
      CheckOut: Q(i.calendarDates[1].toString())
    },
    ...i == null ? void 0 : i.location
  };
  i.searchType === "originAndDestination" && Object.assign(t, {
    IataOri: (D = i.origin) == null ? void 0 : D.IATA,
    OriginId: String((h = i == null ? void 0 : i.origin) == null ? void 0 : h.id),
    OriginDesc: (l = i.origin) == null ? void 0 : l.description,
    CityOrigin: (O = i.origin) == null ? void 0 : O.city,
    StateOrigin: (I = i.origin) == null ? void 0 : I.state,
    CountryOrigin: (u = i.origin) == null ? void 0 : u.country,
    ZoneIdOrigin: ((C = i.origin) == null ? void 0 : C.masterCode) || (i == null ? void 0 : i.zoneIdOrigin) || "",
    OriginType: ((S = i.origin) == null ? void 0 : S.type) || "",
    IataDes: (T = i.destination) == null ? void 0 : T.IATA,
    DestinationId: String((A = i.destination) == null ? void 0 : A.id),
    DestinationDesc: (f = i.destination) == null ? void 0 : f.description,
    DestinationLat: ((L = i.destination) == null ? void 0 : L.latitude) || (i == null ? void 0 : i.destinationLat) || "",
    DestinationLon: ((j = i.destination) == null ? void 0 : j.longitude) || (i == null ? void 0 : i.destinationLon) || "",
    City: (x = i.destination) == null ? void 0 : x.city,
    State: (P = i.destination) == null ? void 0 : P.state,
    Country: (z = i.destination) == null ? void 0 : z.country,
    ZoneId: ((Z = i.destination) == null ? void 0 : Z.masterCode) || (i == null ? void 0 : i.zoneId) || "",
    DestinationType: ((b = i.destination) == null ? void 0 : b.type) || "",
    Classes: "eco"
  }), i.searchType === "origin" && Object.assign(t, {
    IataOri: (r = i.origin) == null ? void 0 : r.IATA,
    OriginId: String((R = i.origin) == null ? void 0 : R.id),
    OriginDesc: (U = i.origin) == null ? void 0 : U.description,
    CityOrigin: (k = i.origin) == null ? void 0 : k.city,
    StateOrigin: (w = i.origin) == null ? void 0 : w.state,
    CountryOrigin: (M = i.origin) == null ? void 0 : M.country,
    ZoneIdOrigin: ((v = i.origin) == null ? void 0 : v.masterCode) || (i == null ? void 0 : i.zoneIdOrigin) || "",
    OriginType: ((B = i.origin) == null ? void 0 : B.type) || ""
  }), i.searchType === "destination" && Object.assign(t, {
    IataDes: (E = i.destination) == null ? void 0 : E.IATA,
    DestinationId: String((H = i.destination) == null ? void 0 : H.id),
    DestinationDesc: (V = i.destination) == null ? void 0 : V.description,
    DestinationLat: (($ = i.destination) == null ? void 0 : $.latitude) || (i == null ? void 0 : i.destinationLat),
    DestinationLon: ((q = i.destination) == null ? void 0 : q.longitude) || (i == null ? void 0 : i.destinationLon),
    City: (F = i.destination) == null ? void 0 : F.city,
    State: (G = i.destination) == null ? void 0 : G.state,
    Country: (J = i.destination) == null ? void 0 : J.country,
    ZoneId: ((K = i.destination) == null ? void 0 : K.masterCode) || (i == null ? void 0 : i.zoneId) || "",
    DestinationType: ((N = i.destination) == null ? void 0 : N.type) || ""
  });
  const X = s(t);
  return d.search = m(X.toString()), p(d);
}
export {
  ei as default
};
