import { useContent as c, useCroct as r } from "@croct/plug-react";
const l = () => {
  try {
    const o = c("packages-motor", {
      initial: {
        showPackagev2: !1
      },
      fallback: {
        showPackagev2: !1
      }
    }), {
      showPackagev2: e
    } = o, t = r();
    return {
      showPackagev2: e,
      onSearchButtonClick: async () => {
        if (t)
          try {
            await t.track("goalCompleted", {
              goalId: "redirect-to-packages"
            });
          } catch (a) {
            console.error(a);
          }
      }
    };
  } catch {
    return {
      showPackagev2: !1,
      onSearchButtonClick: () => Promise.resolve()
    };
  }
};
export {
  l as default
};
