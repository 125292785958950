import r from "react";
import { Typography as p } from "@cvccorp-components/chui-react-components";
import "../../FilledSearch-CbEpjqut.js";
import { m as i } from "../../OutlineCalendar2-C7zMqPY5.js";
import "../../OutlineDataTransferHorizontal-CdkXjr4c.js";
import { d as e } from "../../dayjs-CxYy674R.js";
import { useTheme as l } from "@emotion/react";
import { Container as n } from "./styled.js";
const s = ({
  initialDate: o,
  finalDate: m,
  dateFormat: t
}) => {
  const a = l().colors.support.primary;
  return /* @__PURE__ */ r.createElement(n, null, /* @__PURE__ */ r.createElement(i, { size: "sm", color: a }), /* @__PURE__ */ r.createElement(p, { variant: "paragraph", scale: 3, color: "support.primary" }, e(o.toString()).format(t), " - ", e(m.toString()).format(t)));
};
s.displayName = "DateIntervalLabel";
export {
  s as default
};
