import d, { createContext as m, useState as f, useCallback as c, useEffect as O, useMemo as g, useContext as v } from "react";
import { getRecommendedDestinations as C, getToursLocations as T } from "@cvccorp-engines/api";
const u = m({}), x = ({
  children: p
}) => {
  const [r, a] = f([]), n = c(async () => {
    const t = await C();
    if (!(t != null && t.length)) return;
    const e = t.map((o) => ({
      label: o.name,
      value: {
        zoneId: o.masterCode,
        description: o.name
      }
    }));
    a(e);
  }, []), i = c(async (t) => {
    const {
      zones: e
    } = await T(t);
    if (!(e != null && e.length)) return;
    const o = e.map((s) => ({
      label: s.description,
      value: {
        zoneId: s.masterCode,
        description: s.description
      }
    }));
    a(o);
  }, []);
  O(() => {
    n();
  }, [n]);
  const l = g(() => ({
    options: r,
    getOptions: i,
    getInitialOptions: n
  }), [r, i, n]);
  return /* @__PURE__ */ d.createElement(u.Provider, { value: l }, p);
};
x.displayName = "ToursOptionsProvider";
const I = () => v(u);
export {
  x as default,
  I as useToursOptions
};
