import r, { createContext as o, useMemo as i, useContext as u } from "react";
const n = o({});
function s({
  children: e
}) {
  const t = i(() => ({}), []);
  return /* @__PURE__ */ r.createElement(n.Provider, { value: t }, e);
}
s.displayName = "EngineProvider";
function c() {
  const e = u(n);
  if (!e)
    throw new Error("useEngine must be used within an EngineProvider");
  return e;
}
export {
  s as default,
  c as useEngine
};
