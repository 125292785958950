import { useMemo as n } from "react";
import { useEngineSearchHistory as i, LocalStorageKeys as d } from "@cvccorp-engines/components";
import { d as s } from "../../dayjs-CxYy674R.js";
import { FlightOptions as c } from "../../store/FlightStore.types.js";
const p = () => {
  const a = i(d.LAST_SEARCH_FLIGHTS);
  return n(() => ({
    ...a,
    searchHistory: a.searchHistory.map((t) => ({
      ...t,
      calendarDates: {
        startDate: s(t.calendarDates.startDate).toDate(),
        endDate: t.calendarDates.endDate && t.choice === c.GOING_AND_BACK ? s(t.calendarDates.endDate).toDate() : void 0
      },
      multidestinations: t.multidestinations ? Object.entries(t.multidestinations).reduce((r, [o, e]) => ({
        ...r,
        [o]: {
          ...e,
          calendarDates: e.calendarDates ? {
            startDate: s(e.calendarDates.startDate).toDate()
          } : void 0
        }
      }), {}) : void 0
    }))
  }), [a]);
};
export {
  p as default
};
