import { FormContainer as t } from "@cvccorp-engines/components";
import i from "@emotion/styled";
const e = "hotelEngine_container__expanded", o = i.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    gap: 12px;
    container-type: inline-size;
    align-items: flex-start;
    @container (min-width: 899px) {
        display: grid;
        grid-template-columns: 1fr 1fr 120px;
        gap: 12px;
    }
`, p = i.div`
    margin-left: ${({
  theme: n
}) => n.spacing.size[5]};
    container-type: inline-size;
    @container (max-width: 1024px) {
        margin: 0;
    }
`, s = i("div")(() => ({
  display: "flex",
  gap: 8
})), m = i.div`
    margin-left: ${({
  theme: n
}) => n.spacing.size[5]};
    container-type: inline-size;

    @container (max-width: 1024px) {
        margin: 0;
    }
`, d = i(t)`
    & .${e} {
        @container (min-width: 899px) {
            grid-template-columns: 1fr 1fr 180px;
        }
    }
`;
export {
  p as ButtonContainer,
  o as Container,
  d as Form,
  e as HOTEL_CONTAINER_EXPANDED_CLASSNAME,
  s as MobileContainer,
  m as ResortButtonContainer
};
