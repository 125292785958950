import { validateTravelers as A } from "@cvccorp-engines/pax";
import { FlightOptions as E } from "../store/FlightStore.types.js";
function j(n) {
  var D, l, g, m;
  let i = !1;
  const I = () => Object.entries(n.multidestinations).reduce((T, [x, t]) => {
    var h, N, C;
    const y = !t.destination, f = !t.origin, p = !((h = t.calendarDates) != null && h.startDate), u = ((N = t.origin) == null ? void 0 : N.city) === ((C = t.destination) == null ? void 0 : C.city);
    return i = y || f || p || u, {
      ...T,
      [Number(x)]: {
        destination: y,
        origin: f,
        originDestination: u,
        calendarDates: {
          startDate: p,
          endDate: !1
        }
      }
    };
  }, {}), s = !n.destination, o = !n.classe, e = !n.origin, a = !(n.choice !== E.GOING_AND_BACK || (D = n.calendarDates) != null && D.endDate), c = !((l = n.calendarDates) != null && l.startDate), [r] = A(n.pax), d = ((g = n.origin) == null ? void 0 : g.city) === ((m = n.destination) == null ? void 0 : m.city);
  i = s || e || a || c || o || r || d;
  const O = n.choice === E.MULTIPLE_DESTINATION ? I() : {};
  return [i, {
    destination: s,
    origin: e,
    originDestination: d,
    multidestinations: O,
    classe: o,
    pax: r,
    calendarDates: {
      endDate: a,
      startDate: c
    }
  }];
}
export {
  j as default
};
