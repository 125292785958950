import o, { useEffect as P } from "react";
import { getCarsLocations as M } from "@cvccorp-engines/api";
import { useQueryParam as t, EngineQueryParams as e, formatDateToParam as T, CollapsableEngineContainer as R, DestinationLabel as H, TripDateLabel as d, DateFormat as D, CollapsableEngineButton as K } from "@cvccorp-engines/components";
import { d as m } from "../../dayjs-CxYy674R.js";
import U from "../../EngineCar.js";
import { useCarsStore as Y } from "../../store/CarsStore.js";
import { mapCarsLocationsToCarLocationOptions as $ } from "../../utils/mapCarsLocationsToCarLocationOptions.js";
import { EngineContainer as F, Grid as v } from "./styled.js";
const x = (f) => {
  const {
    setPickupLocation: l,
    setReturnLocation: u
  } = Y(), g = t(e.CHECKIN, T((/* @__PURE__ */ new Date()).toString())), I = t(e.CHECKOUT, T(m(/* @__PURE__ */ new Date()).add(7, "days").toString())), L = t(e.CUSTOM_LOCATION, "false"), N = t(e.IATA_ORI, ""), O = t(e.IATA_DES, ""), S = t(e.STATE), _ = t(e.COUNTRY), A = t(e.CITY), h = t(e.CHECKIN_TIME, "10:00"), w = t(e.CHECKOUT_TIME, "10:00"), r = t(e.ORIGIN_DESC, ""), E = t(e.DESTINATION_DESC, ""), C = L.toLowerCase() === "false", c = async (n) => {
    var i, s;
    const a = await M(n);
    return (i = a.locations) != null && i.length ? (s = $(a.locations)[0]) == null ? void 0 : s.value : void 0;
  }, y = async () => {
    try {
      if (!C && r && E) {
        const [n, a] = await Promise.all([c(r), c(E)]);
        n && l(n), a && u(a);
      } else if (r) {
        const n = await c(r);
        n && (l(n), u(n));
      }
    } catch {
    }
  };
  P(() => {
    y();
  }, []);
  const b = () => C ? {
    name: A,
    address: `${S}, ${_}`
  } : {
    name: `${N} e ${O}`
  }, p = (n, a) => {
    const i = a.split(":");
    if (i.length !== 2) return m(n).set("hours", 10);
    const [s, k] = i;
    return m(n).set("hours", Number(s)).set("minutes", Number(k));
  };
  return /* @__PURE__ */ o.createElement(R, { ...f, renderExpandedEngine: () => /* @__PURE__ */ o.createElement(F, null, /* @__PURE__ */ o.createElement(U, null)) }, /* @__PURE__ */ o.createElement(v, { container: !0, direction: "row", alignItems: "center" }, /* @__PURE__ */ o.createElement(H, { ...b() }), /* @__PURE__ */ o.createElement(d, { date: p(g, h).toDate(), dateFormat: D.DAY_MONTH_AND_TIME }), /* @__PURE__ */ o.createElement(d, { date: p(I, w).toDate(), dateFormat: D.DAY_MONTH_AND_TIME }), /* @__PURE__ */ o.createElement(K, null)));
};
x.displayName = "EngineCarCollapsable";
export {
  x as default
};
