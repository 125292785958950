import { getBaseHostname as h, formatDateToParam as c, encodeSearchURL as m } from "@cvccorp-engines/components";
import { PaxQueryParams as r } from "../constants/PaxQueryParam.js";
function g(a) {
  var t, n, s, o;
  const e = new URL(`${h()}/seguro-viagem/search`);
  e.searchParams.append("startDate", c((t = a.calendarDates) == null ? void 0 : t.startDate)), e.searchParams.append("endDate", c((n = a.calendarDates) == null ? void 0 : n.endDate)), e.searchParams.append("location", ((s = a.destination) == null ? void 0 : s.description) || ""), e.searchParams.append("zoneId", String((o = a.destination) == null ? void 0 : o.masterCode));
  const d = Array(a.pax.elderly).fill(r.ELDERLY), l = Array(a.pax.adult).fill(r.ADULT), i = Array(a.pax.children).fill(r.CHILDREN), p = d.concat(l, i);
  return e.searchParams.append("paxs", String(p)), m(e);
}
export {
  g as default
};
