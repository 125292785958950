"use client";
import e from "react";
import { L as C } from "../../index-C86aQ7OP.js";
const R = (n) => {
  const {
    max: t,
    min: a,
    origin: o,
    errors: r,
    children: i,
    destination: s,
    dates: l = [],
    isMult: m = !1,
    hidePax: c = !1,
    onRemove: f,
    onReverse: u,
    onDateClear: d,
    onDateChange: g,
    onOriginChange: h,
    onDestinationChange: D,
    firstInputRef: p
  } = n;
  return /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement(C, { onRemove: f, errors: r, dates: l, onDateClear: d, onDateChange: g, max: t, min: a, hidePax: c, isMult: m, origin: o, destination: s, onDestinationChange: D, onOriginChange: h, onReverse: u, firstInputRef: p }), i);
};
export {
  R as default
};
