const c = (a) => a.map((o) => ({
  label: o.description,
  value: {
    iata: o.IATA,
    zoneId: o.masterCode,
    description: o.description,
    type: o.type,
    officeHours: o.officeHours,
    city: o.city,
    state: o.state,
    country: o.country
  }
}));
export {
  c as mapCarsLocationsToCarLocationOptions
};
