import n from "@emotion/styled";
const a = n.div`
    box-sizing: border-box;
    box-shadow: ${({
  theme: i
}) => i.shadow.light[300]};
    z-index: ${({
  theme: i
}) => i.zIndex.default};
    background: ${(i) => i.theme.colors.neutral.white};
    width: 100%;
    margin: 0px auto;
    border-radius: 16px;
    container-type: inline-size;

    padding: ${({
  theme: i
}) => i.spacing.size[8]};

    @media (min-width: 600px) {
        position: relative;
    }

    @container (max-width: 899px) {
        padding-inline: ${({
  theme: i
}) => i.spacing.size[5]};
        padding-block: ${({
  theme: i
}) => i.spacing.size[5]};
    }

    .chui-engines-navigation {
        margin-bottom: ${({
  theme: i
}) => i.spacing.size[8]};
    }
`;
export {
  a as Container
};
