import A, { createContext as _, useCallback as h, useMemo as C } from "react";
import { useIsomorphicLayoutEffect as M } from "@cvccorp-components/chui-react-components";
import { useQueryParam as O, EngineQueryParams as H, gtm as N, redirectToSearch as B, DateFormat as S } from "@cvccorp-engines/components";
import { d } from "../../dayjs-CxYy674R.js";
import I from "../../hooks/useResortSearchHistory/useResortSearchHistory.js";
import { useResortsStore as Q } from "../../store/ResortsStore/ResortsStore.js";
import "@cvccorp-engines/pax";
import "@cvccorp-engines/api";
import "@cvccorp-components/chui-react-components/lib/chuiDate";
import w from "../../utils/resortFormatUrlToSearch.js";
import L from "../../utils/resortValidateChildrenPaxDate.js";
import { formatStateToGTM as U } from "../../utils/resortFormatStateToGTM.js";
const Y = _(void 0), R = (n) => ({
  startDate: d(n.startDate).format(S.YEAR_MONTH_DATE_TIMEZONE),
  endDate: d(n.endDate).format(S.YEAR_MONTH_DATE_TIMEZONE)
});
function Z(n) {
  var u, x;
  const {
    setErrors: m,
    setErrorsPax: l,
    setDestination: T,
    setCalendarDates: P,
    setPax: v,
    ...e
  } = Q(), {
    searchHistory: D,
    addValue: p
  } = I(), y = O(H.SHOW_RESORTS, void 0);
  M(() => {
    if (D.length && !y) {
      const {
        destination: a,
        calendarDates: t,
        pax: r
      } = D[0];
      T(a), v(r), d(/* @__PURE__ */ new Date()).isAfter(t.startDate) || P(R(t));
    }
  }, []);
  const f = h(() => {
    var c, s;
    let a = !1;
    const t = !e.pax, r = !e.destination, o = !((c = e.calendarDates) != null && c.endDate), i = !((s = e.calendarDates) != null && s.startDate);
    return a = r || o || i || t, [a, {
      destination: r,
      pax: t,
      calendarDates: {
        endDate: o,
        startDate: i
      }
    }];
  }, [(u = e.calendarDates) == null ? void 0 : u.endDate, (x = e.calendarDates) == null ? void 0 : x.startDate, e.destination, e.pax]), E = h(() => {
    var s;
    const a = (s = e.calendarDates) == null ? void 0 : s.endDate, [t, r] = f(), [o, i] = L(e.pax, a);
    if (m({
      ...r,
      pax: o
    }), l(i), t || o)
      return;
    const c = w(e).replaceAll("+,+", ", ").replaceAll("+", " ");
    p({
      calendarDates: R(e.calendarDates),
      destination: e.destination,
      pax: e.pax
    }), N.onEngineSearch(U(e)), B(c);
  }, [e, m, l, f, p]), g = C(() => ({
    handleSearch: E
  }), [E]);
  return /* @__PURE__ */ A.createElement(Y.Provider, { ...n, value: g });
}
Z.displayName = "EngineResortsProvider";
export {
  Y as EngineResortsContext,
  Z as default
};
