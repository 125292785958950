import a from "../../libs/fetcher.js";
import n from "../../utils/QueryString.js";
const p = async (t, e) => {
  try {
    const {
      data: r
    } = await a.get("/api/hotels/pesqinc", {
      params: {
        productType: t,
        q: n.text(e).replaceSpace().normalizeNFD().toString()
      }
    });
    return r.locations.sort((o, i) => i.hits - o.hits), r;
  } catch {
    throw new Error("Error in pesqInc");
  }
};
export {
  p as pesqIncService
};
