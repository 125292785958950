import x, { createContext as D, useCallback as S, useMemo as E, useContext as g } from "react";
import { useIsomorphicLayoutEffect as h } from "@cvccorp-components/chui-react-components";
import { useParsedLocalStorage as M, LocalStorageKeys as P, useQueryParam as v, EngineQueryParams as A, gtm as L, redirectToSearch as O } from "@cvccorp-engines/components";
import { d as R, f as w } from "../formatUrlToSearch-CWK6pv8X.js";
import { useToursStore as I } from "../store/ToursStore.js";
import { filterSelectedDestinations as U } from "../utils/filterSelectedDestinations.js";
import { formatStateToGTM as _ } from "../utils/formatStateToGTM.js";
import { useToursOptions as k } from "./ToursOptionsContext/ToursOptionContext.js";
const p = D({});
function q(o) {
  const {
    setErrors: d,
    setAllDestiny: T,
    setDate: y,
    setDestinations: c,
    ...t
  } = I(), [e, u] = M(P.LAST_SEARCH_TOURS), {
    options: n
  } = k(), i = v(A.ZONE_IDS);
  h(() => {
    e && !i && (T(e.allDestiny), c(e.destinations), R(/* @__PURE__ */ new Date()).isAfter(e.date) || y(e.date));
  }, []), h(() => {
    n.length && !t.destinations.length && i && c(U(i, n));
  }, [n]);
  const l = S(() => {
    var f;
    let r = !1;
    const s = !((f = t.destinations) != null && f.length), a = !t.date;
    return r = s || a, [r, {
      destinations: s,
      date: a
    }];
  }, [t.date, t.destinations]), m = S(() => {
    const [r, s] = l();
    if (d(s), r) return;
    const a = w(t).replaceAll("+,+", ", ").replaceAll("+", " ");
    u({
      allDestiny: t.allDestiny,
      date: t.date,
      destinations: t.destinations
    }), L.onEngineSearch(_(t)), O(a);
  }, [d, t, l, u]), C = E(() => ({
    handleSearch: m
  }), [m]);
  return /* @__PURE__ */ x.createElement(p.Provider, { ...o, value: C });
}
q.displayName = "MotorToursProvider";
function Z() {
  const o = g(p);
  if (!o.handleSearch) throw new Error("Chamada do hook useMotorToursContext inválida! Certifique-se que ele esta sendo chamado dentro do contexto MotorToursContext");
  return o;
}
export {
  q as default,
  Z as useMotorToursContext
};
