import e, { useState as m } from "react";
import { PaxPackages as s } from "@cvccorp-engines/pax";
import { useEngineBusingCharterContext as i, defaultPaxValues as u } from "../../context/EngineBusingCharterContext.js";
import c from "../Calendar/Calendar.js";
import { P as f } from "../../../Pesqinc-Bt78zI5Z.js";
import { EngineContainer as p } from "./DefaultLocationForm.styles.js";
const x = () => {
  const {
    paxValue: n,
    setPaxValue: a,
    isExcursion: r
  } = i(), [t, o] = m(!1);
  return /* @__PURE__ */ e.createElement(p, { shortSize: r }, /* @__PURE__ */ e.createElement(f, null), /* @__PURE__ */ e.createElement(s, { maxAge: 17, open: t, value: n, onToggle: () => o(!t), onClear: () => a(u), onConfirm: (l) => {
    a(l), o(!1);
  }, variant: "busing-charter" }), /* @__PURE__ */ e.createElement(c, null));
};
x.displayName = "DefaultLocationForm";
export {
  x as default
};
