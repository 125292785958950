import u, { createContext as m, useState as f, useRef as p, useEffect as M, useCallback as d, useMemo as b, useContext as v } from "react";
import { useResponsive as C, useIsomorphicLayoutEffect as x } from "@cvccorp-components/chui-react-components";
import "../../context/ErrorContext/ErrorContext.js";
import { useCollapsableEngine as E } from "../../context/CollapsableEngineContext/CollapsableEngineContext.js";
const n = m({}), F = ({
  children: a
}) => {
  const [o, t] = f(!1), e = p(), {
    setValue: s
  } = E(), {
    isMobile: r
  } = C();
  x(() => {
    r && s && t(!0);
  }, [r]), M(() => {
    o && (e != null && e.current) && e.current.click();
  }, [o, e]);
  const i = d((l) => {
    t(l), !l && s && s(!1);
  }, [s]), c = b(() => ({
    isModalOpen: o,
    setIsModalOpen: i,
    firstInputRef: e
  }), [o, e, i]);
  return /* @__PURE__ */ u.createElement(n.Provider, { value: c }, a);
};
F.displayName = "FormMobileProvider";
const y = () => v(n);
export {
  F as default,
  y as useFormMobile
};
