import E, { useState as D, useEffect as A, useCallback as B } from "react";
import P from "@cvccorp-components/chui-react-components/components/Autocomplete";
import { r as w } from "../../../../OutlinePinMap-hG5rf3UN.js";
import { getBusingCharterLocations as N } from "@cvccorp-engines/api";
import { useError as x } from "@cvccorp-engines/components";
import { useEngineBusingCharterContext as G } from "../../../context/EngineBusingCharterContext.js";
import { getBusingCharterParamsForLogs as j } from "../../../utils/getBusingParamsForLogs.js";
const T = (n) => n.map((s) => ({
  label: s.description,
  value: s
})), K = ({
  searchType: n,
  isUniqueDirection: s,
  onSelect: O
}) => {
  const [R, g] = D([]), {
    pesqincLocationSelected: t,
    isAtlas: l
  } = G(), {
    throwError: a
  } = x(), v = async () => {
    var u, f, o, I;
    const r = {
      type: n,
      uniqueDirection: s,
      isAtlas: l
    };
    n === "ORIGIN" && ((u = t == null ? void 0 : t.destination) != null && u.id) && (r.locationId = (f = t == null ? void 0 : t.destination) == null ? void 0 : f.id), n === "DESTINATION" && ((o = t == null ? void 0 : t.origin) != null && o.id) && (r.locationId = (I = t == null ? void 0 : t.origin) == null ? void 0 : I.id);
    const m = await N(r);
    return T(m.content);
  };
  return A(() => {
    (async () => {
      if (!R.length && (n === "ORIGIN" && (t != null && t.origin) || n === "DESTINATION" && (t != null && t.destination))) {
        const u = [];
        return t != null && t.origin && u.push({
          label: t == null ? void 0 : t.origin.name,
          value: t == null ? void 0 : t.origin
        }), t != null && t.destination && u.push({
          label: t == null ? void 0 : t.destination.name,
          value: t == null ? void 0 : t.destination
        }), g(u);
      }
      const m = await v();
      g(m);
    })();
  }, [n, t]), {
    onSearch: B(
      async (r) => {
        var f, o, I, b;
        const m = j(), u = (/* @__PURE__ */ new Date()).getTime();
        try {
          const d = {
            type: n,
            searchString: r,
            uniqueDirection: s,
            isAtlas: l
          };
          n === "ORIGIN" && ((f = t == null ? void 0 : t.destination) != null && f.id) && (d.locationId = (o = t == null ? void 0 : t.destination) == null ? void 0 : o.id), n === "DESTINATION" && ((I = t == null ? void 0 : t.origin) != null && I.id) && (d.locationId = (b = t == null ? void 0 : t.origin) == null ? void 0 : b.id);
          const i = await N(d);
          if (!(i != null && i.totalElements)) {
            const C = (/* @__PURE__ */ new Date()).getTime() - u;
            return Object.assign(m, {
              message: `EMPTYRESULT ${r}`,
              duration: C,
              durationUnit: "milisseconds"
            }), g([]), a == null ? void 0 : a(m);
          }
          return g(T(i.content));
        } catch {
          const i = (/* @__PURE__ */ new Date()).getTime() - u;
          return Object.assign(m, {
            message: `ERROR ${r}`,
            duration: i,
            durationUnit: "milisseconds",
            level: 4
          }), g([]), a == null ? void 0 : a(m);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [s, n]
    ),
    options: R,
    renderOption: ({
      option: r
    }) => {
      var m;
      return /* @__PURE__ */ E.createElement(P.Option, { fullWidth: !0, label: r == null ? void 0 : r.label, supportText: (m = r == null ? void 0 : r.value) == null ? void 0 : m.description, onSelect: () => O(r.value, n), addonBefore: /* @__PURE__ */ E.createElement(w, null) });
    },
    onSelect: (r) => {
      O(r, n);
    }
  };
};
export {
  K as default
};
