import { Divider as t } from "@cvccorp-components/chui-react-components";
import n from "@emotion/styled";
const r = n.div`
    display: grid;
    width: 100%;
    container-type: inline-size;
    grid-template-columns: repeat(1, 1fr);
    column-gap: ${({
  theme: i
}) => i.spacing.size[5]};
    grid-gap: ${({
  theme: i
}) => i.spacing.size[5]};

    @container (min-width: 899px) {
        display: grid;
        grid-template-columns: 1fr 300px;
        column-gap: ${({
  theme: i
}) => i.spacing.size[5]};
        grid-gap: initial;
    }
`, o = n.div`
    margin-left: ${({
  theme: i
}) => i.spacing.size[5]};
    container-type: inline-size;
    @container (max-width: 1024px) {
        margin: 0;
    }
`, p = n.div`
    display: grid;
    width: 100%;
    container-type: inline-size;
    grid-template-columns: repeat(1, 1fr);
    column-gap: ${({
  theme: i
}) => i.spacing.size[5]};
    grid-gap: ${({
  theme: i
}) => i.spacing.size[5]};

    @container (min-width: 899px) {
        display: grid;
        grid-template-columns: 1fr 300px;
        column-gap: ${({
  theme: i
}) => i.spacing.size[5]};
        grid-gap: initial;
    }
`, s = n.div`
    margin-left: ${({
  theme: i
}) => i.spacing.size[5]};
    container-type: inline-size;
    @container (max-width: 1024px) {
        margin: 0;
    }
`, d = n.div(({
  theme: i
}) => ({
  display: "flex",
  gap: "8px",
  [`@media (max-width: ${i.breakpoints.layoutGridMd})`]: {
    flexDirection: "column"
  }
})), g = n(t)(({
  theme: i
}) => ({
  height: 32,
  [`@media (max-width: ${i.breakpoints.layoutGridMd})`]: {
    display: "none"
  }
}));
export {
  o as BusingButtonContainer,
  s as BusingCharterButtonContainer,
  p as BusingCharterMotorContainer,
  r as BusingMotorContainer,
  d as ContainerNavigation,
  g as Divider
};
