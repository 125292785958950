import l from "react";
import { InputDatePicker as m } from "@cvccorp-engines/components";
import { useEngineBusingContext as s } from "../../../context/EngineBusingContext.js";
const c = ({
  minDate: r,
  maxDate: o
}) => {
  var n;
  const {
    setCalendarDateSelected: t,
    calendarDateSelected: e,
    errors: a
  } = s();
  return /* @__PURE__ */ l.createElement(m, { value: e == null ? void 0 : e[0], label: "Escolha a data", onChange: (i) => t([new Date(i)]), error: (n = a == null ? void 0 : a.dates) != null && n.startDate ? "Preencha a data" : void 0, onClear: () => t([]), min: r, max: o });
};
c.displayName = "CalendarRangePicker";
export {
  c as default
};
