import E, { createContext as g, useState as u, useEffect as R, useCallback as x, useMemo as A, useContext as D } from "react";
import { DEFAULT_ROOM as d } from "../../../constants/defaultValues.js";
import M from "../../../validations/childrenPaxDate.js";
const t = g({});
function O(C) {
  const {
    children: f,
    infantAge: n,
    variant: a,
    rooms: e = [{
      ...d
    }],
    maxAge: s
  } = C, [c, r] = u(e), [m, P] = u();
  R(() => {
    r(e);
  }, [e]);
  const l = x((o) => {
    const [v, p] = M(o);
    P(v ? p : void 0), r(o);
  }, []), i = x(() => {
    const o = e ? [...e] : [{
      ...d
    }];
    r(o);
  }, [e]), h = A(() => ({
    maxAge: s,
    infantAge: n,
    variant: a,
    rooms: c,
    errors: m,
    onChangeRooms: l,
    onClearRooms: i
  }), [m, l, i, c, n, a, s]);
  return /* @__PURE__ */ E.createElement(t.Provider, { value: h }, f);
}
O.displayName = "PaxProvider";
const F = t.Consumer;
function L() {
  return D(t);
}
export {
  F as PaxConsumer,
  O as default,
  L as usePax
};
