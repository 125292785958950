import { memo, useEffect, useState } from "react";
import { ChuiThemeProvider } from "@cvccorp-components/chui-react-components/provider/ChuiThemeProvider";
import "@cvccorp-components/chui-react-components/assets/css/chui.css";
import { AssistedSaleProvider } from "@attentions-components/hooks";
import fetcher from "@cvccorp-engines/api";
import type { ProductTypes } from "@cvccorp-engines/components";
import { EngineNavigationProvider } from "@cvccorp-engines/navigation";
import type { NavigationItemConfig } from "@cvccorp-engines/navigation";
import { EnginesConfigProvider } from "@cvccorp-engines/provider";
import EngineSkeleton from "./components/EngineSkeleton";
import EngineWrapper from "./components/EngineWrapper";
import { getEngineItems } from "./services/engine";

fetcher.defaults.baseURL = process.env.REACT_APP_CMS_BASE_HOSTNAME;

interface IAppProps {
    product: ProductTypes;
}

const App = ({ product }: IAppProps) => {
    const [{ isLoading, navigationItems }, setData] = useState<{
        isLoading: boolean;
        navigationItems: Array<NavigationItemConfig>;
    }>({
        navigationItems: [],
        isLoading: true,
    });

    const fetchEngineItems = async () => {
        setData({
            isLoading: false,
            navigationItems: await getEngineItems(),
        });
    };

    useEffect(() => {
        fetchEngineItems();
    }, []);

    return (
        <ChuiThemeProvider>
            <EngineNavigationProvider product={product} navigationType="button" navigationItems={navigationItems}>
                <AssistedSaleProvider>
                    <EnginesConfigProvider>{isLoading ? <EngineSkeleton /> : <EngineWrapper />}</EnginesConfigProvider>
                </AssistedSaleProvider>
            </EngineNavigationProvider>
        </ChuiThemeProvider>
    );
};

export default memo(App);
