import n, { useRef as j, useEffect as q, useMemo as w } from "react";
import { useResponsive as z } from "@cvccorp-components/chui-react-components";
import { useFormMobile as B, useAutoFocus as H, useFieldErrors as K, getCalendarDatesAsDate as Q, InputGroup as U } from "@cvccorp-engines/components";
import { PaxPackages as V } from "@cvccorp-engines/pax";
import { usePackageFullStoreStore as R, defaultPackageFullStorePax as X } from "../../store/EnginePackageFullStore/EnginePackageFullStore.js";
import Y from "../PackageFullCalendarPicker/CalendarPicker.js";
import b from "../PackageFullSingleAffreightment/SingleAffreightment.js";
import { LocationAndDateWrapper as Z } from "./LocationAndDate.styles.js";
function ie(F) {
  const {
    max: y,
    min: A,
    origin: r,
    destination: l,
    isMult: h = !1,
    onOriginChange: a,
    onDestinationChange: s
  } = F, {
    pax: L = X,
    errors: t,
    openPax: M,
    clearPax: O,
    setPax: I,
    setOpenPax: m
  } = R(), {
    firstInputRef: T
  } = B(), {
    isMobile: f
  } = z(), {
    register: c,
    focusField: p
  } = H(["destination", "datePicker"]), {
    getErrorMessage: o
  } = K({
    errors: t
  }), u = j(null);
  q(() => {
    c("datePicker", {
      el: u.current,
      action: f ? "focus" : "click"
    });
  }, [c, f]);
  const d = R((e) => Q({
    startDate: e.calendarDates,
    endDate: ""
  })), C = w(() => {
    var e, i;
    return {
      endDate: (e = d[1]) == null ? void 0 : e.toJSON(),
      startDate: (i = d[0]) == null ? void 0 : i.toJSON()
    };
  }, [d]), J = (e) => {
    a == null || a(e), p("destination");
  }, N = (e) => {
    s == null || s(e), p("datePicker");
  };
  function g(e, i) {
    var P, D, x, k, E, S;
    return (P = e == null ? void 0 : e.city) != null && P.name ? ((x = (D = e == null ? void 0 : e.city) == null ? void 0 : D.state) == null ? void 0 : x.name) + ", " + ((S = (E = (k = e == null ? void 0 : e.city) == null ? void 0 : k.state) == null ? void 0 : E.country) == null ? void 0 : S.name) : (e == null ? void 0 : e.name) ?? i;
  }
  const W = g(r, "Origem"), G = g(l, "Destino");
  return /* @__PURE__ */ n.createElement(n.Fragment, null, /* @__PURE__ */ n.createElement(U, { hasError: t.origin || t.destination || t.originDestination, fullWidth: !0, status: t.origin || t.destination || t.originDestination ? "danger" : void 0 }, /* @__PURE__ */ n.createElement(b, { supportText: o("origin"), error: o("origin"), placeholder: "De onde você vai sair?", label: W, value: r, type: "origin", onSelect: J, inputProps: {
    inputRef: T
  } }), /* @__PURE__ */ n.createElement(b, { supportText: o("destination"), placeholder: "Para onde vai?", error: o("destination"), value: l, originId: r == null ? void 0 : r.id, type: "destination", onSelect: N, label: G, isDestination: !0, inputProps: {
    inputRef: (e) => c("destination", {
      action: "focus",
      el: e
    })
  } })), /* @__PURE__ */ n.createElement(Z, { isMult: h }, /* @__PURE__ */ n.createElement(V, { infantAge: 1, variant: "package-full", value: L, error: t.pax, calendarDates: C, open: M, onToggle: m, onClear: O, onConfirm: (e) => {
    I(e), m(!1);
  } }), /* @__PURE__ */ n.createElement(Y, { max: y, min: A, calendarPickerRef: u })));
}
export {
  ie as default
};
