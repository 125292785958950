import R, { createContext as I, useCallback as T, useMemo as q, useContext as L } from "react";
import { useIsomorphicLayoutEffect as U } from "@cvccorp-components/chui-react-components";
import { gtm as Y, redirectToSearch as Z, DateFormat as y } from "@cvccorp-engines/components";
import { d } from "../../dayjs-CxYy674R.js";
import b from "../../hooks/usePackageSearchHistory/usePackageSearchHistory.js";
import j from "../../hooks/useTestAB/useTestAB.js";
import { usePackageStore as F } from "../../store/PackageStore/PackageStore.js";
import { formatStateToGTM as G } from "../../utils/formatStateToGTM.js";
import V from "../../utils/formatUrlToSearch.js";
import v from "../../utils/validateChildrenPaxDate.js";
const B = I(void 0), A = (a) => ({
  startDate: d(a.startDate).format(y.YEAR_MONTH_DATE_TIMEZONE),
  endDate: d(a.endDate).format(y.YEAR_MONTH_DATE_TIMEZONE)
});
function z(a) {
  var h, P;
  const {
    setErrors: l,
    setErrorsPax: m,
    setDestination: _,
    setCalendarDates: w,
    setPax: N,
    setOrigin: O,
    ...t
  } = F(), {
    searchHistory: D,
    addValue: f
  } = b(), {
    showPackagev2: p,
    onSearchButtonClick: g
  } = j();
  U(() => {
    if (!t.destination && D.length) {
      const e = D[0];
      _(e.destination), O(e.origin), N(e.pax), d(/* @__PURE__ */ new Date()).isAfter(e.calendarDates.startDate) || w(A(e.calendarDates));
    }
  }, []);
  const u = T(() => {
    var C, k, M, S;
    let e = !1;
    const r = !t.pax || v(t.pax)[0], n = !t.destination, o = !t.origin, s = !((C = t.calendarDates) != null && C.endDate), i = !((k = t.calendarDates) != null && k.startDate), E = ((M = t.origin) == null ? void 0 : M.city) === ((S = t.destination) == null ? void 0 : S.city);
    return e = n || o || s || i || r || E, [e, {
      destination: n,
      origin: o,
      pax: r,
      originDestination: E,
      calendarDates: {
        endDate: s,
        startDate: i
      }
    }];
  }, [(h = t.calendarDates) == null ? void 0 : h.endDate, (P = t.calendarDates) == null ? void 0 : P.startDate, t.destination, t.origin, t.pax]), x = T(async () => {
    var c;
    const e = (c = t.calendarDates) == null ? void 0 : c.endDate, [r, n] = u(), [o, s] = v(t.pax, e);
    if (l({
      ...n,
      pax: o
    }), m(s), await g(), r || o)
      return;
    const i = V(t, p).replaceAll("+,+", ", ").replaceAll("+", " ");
    f({
      calendarDates: A(t.calendarDates),
      origin: t.origin,
      destination: t.destination,
      pax: t.pax
    }), Y.onEngineSearch(G(t)), Z(i);
  }, [t, u, l, m, g, p, f]), H = q(() => ({
    handleSearch: x
  }), [x]);
  return /* @__PURE__ */ R.createElement(B.Provider, { ...a, value: H });
}
z.displayName = "MotorPackageProvider";
function rt() {
  const a = L(B);
  if (!a) throw new Error("Chamada do hook useMotorPackageContext inválida! Certifique-se que ele esta sendo chamado dentro do contexto MotorPackageContext");
  return a;
}
export {
  z as default,
  rt as useMotorPackageContext
};
