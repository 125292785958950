import o from "react";
import { Container as t } from "./Engine.styled.js";
function i(e) {
  const {
    children: n,
    ...r
  } = e;
  return /* @__PURE__ */ o.createElement(t, { ...r }, n);
}
i.displayName = "EngineBox";
export {
  i as default
};
