import e from "react";
import { u as l, b as a } from "../../EngineBookingPackagesContext-D4tagYeS.js";
import { Wrapper as m } from "./Calendar.styles.js";
import d from "./CalendarMonthPicker/CalendarMonthPicker.js";
import "./CalendarMonthPicker/CalendarMonthPickerSwitch.js";
import i from "./CalendarRangePicker/CalendarRangePicker.js";
const t = () => {
  const {
    isMonthPicker: r
  } = l(), n = a().add(1, "days").toDate(), o = a().add(330, "days").toDate();
  return r ? /* @__PURE__ */ e.createElement(d, null) : /* @__PURE__ */ e.createElement(i, { minDate: n, maxDate: o });
};
t.displayName = "GetCalendar";
const s = () => /* @__PURE__ */ e.createElement(m, null, /* @__PURE__ */ e.createElement(t, null));
s.displayName = "Calendar";
export {
  s as default
};
