import i, { useMemo as t } from "react";
import { ESearchTypes as n } from "@cvccorp-engines/api";
import { InputGroup as a } from "@cvccorp-engines/components";
import { useEngineBusingCharterContext as s } from "../../../context/EngineBusingCharterContext.js";
import r from "../SingleBusingCharter/SingleBusingCharter.js";
const m = () => {
  const {
    errors: e
  } = s(), o = t(() => e.sameLocation ? {
    origin: "Origem e Destino não podem ser iguais",
    destination: "Origem e Destino não podem ser iguais"
  } : {
    origin: e.origin ? "Preencha o campo de origem" : void 0,
    destination: e.destination ? "Preencha o campo de destino" : void 0
  }, [e]);
  return /* @__PURE__ */ i.createElement(a, { status: e != null && e.origin || e != null && e.destination || e.sameLocation ? "danger" : void 0 }, /* @__PURE__ */ i.createElement(r, { searchType: n.ORIGIN, label: "Origem", placeholder: "De onde vai sair?", error: o.origin }), /* @__PURE__ */ i.createElement(r, { searchType: n.DESTINATION, label: "Destino", placeholder: "Para onde vai?", error: o.destination }));
};
m.displayName = "PesqincFull";
export {
  m as default
};
