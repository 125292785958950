import { d as m } from "../dayjs-CxYy674R.js";
import { CARS_LOCATIONS_DAY_OF_WEEK as a } from "../constants/carsWeekdayParser.js";
import { workingTime as f } from "./workingTime.js";
function g(n, o) {
  if (!n) return [];
  const t = m(n).day(), r = a[t];
  if (!o) return [];
  const e = o.find(({
    daysOfWeek: i
  }) => i === r);
  return e ? f({
    closingTime: e.closingTime,
    openingTime: e.openingTime
  }).map((i) => ({
    label: i,
    value: i
  })) : [];
}
export {
  g as default
};
