import i from "react";
import { useWindow as r, useResponsive as a, Button as u } from "@cvccorp-components/chui-react-components";
import "../../context/ErrorContext/ErrorContext.js";
import { useCollapsableEngine as c } from "../../context/CollapsableEngineContext/CollapsableEngineContext.js";
const p = ({
  className: n
}) => {
  const {
    value: o,
    toggle: e
  } = c(), t = r(), {
    isMobile: l
  } = a();
  if (!e) return null;
  const s = () => {
    !o && !l && t.scrollTo(0, 0), e();
  };
  return /* @__PURE__ */ i.createElement(u, { variant: "outline", color: "secondary", size: "sm", onClick: s, className: n }, o ? "Cancelar" : "Alterar");
};
p.displayName = "CollapsableEngineButton";
export {
  p as default
};
