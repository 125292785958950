import l from "react";
import { OptionsBar as p } from "@cvccorp-engines/components";
import { NAVIGATION_CONFIG as N, NAVIGATION_PRODUCTS as t, NAVIGATION_LINKS as a, useEngineNavigation as d } from "@cvccorp-engines/navigation";
const i = N.get(t.BUSING_CHARTER), r = N.get(t.BUSING), u = [{
  product: i.id,
  value: a[t.BUSING_CHARTER],
  label: i.label
}, {
  product: r.id,
  value: a[t.BUSING],
  label: r.label
}], G = ({
  navigationType: c
}) => {
  const {
    currentProduct: n = r.id,
    setCurrentProduct: s
  } = d();
  return /* @__PURE__ */ l.createElement(p, { variant: c, options: u, value: n, onChange: (o) => {
    const e = u.find((I) => I.value === o);
    s(e == null ? void 0 : e.product);
  }, isChecked: (o) => o.product === n });
};
G.displayName = "BusingOptionsBar";
export {
  G as default
};
