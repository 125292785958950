import r, { useState as l, useEffect as i } from "react";
import { Wrapper as o, Loading as u } from "./InlineSVG.styles.js";
function f(c) {
  const {
    src: n
  } = c, [e, t] = l({
    content: null,
    error: !1
  });
  return i(() => {
    (async () => {
      try {
        const a = await fetch(n);
        if (a.ok) {
          const s = await a.text();
          t({
            content: s,
            error: !1
          });
        } else
          t({
            content: null,
            error: !0
          });
      } catch {
        t({
          content: null,
          error: !0
        });
      }
    })();
  }, [n]), e.error ? /* @__PURE__ */ r.createElement(o, { "aria-hidden": "true" }) : e.content ? /* @__PURE__ */ r.createElement(o, { "aria-hidden": "true", dangerouslySetInnerHTML: {
    __html: e.content
  } }) : /* @__PURE__ */ r.createElement(u, { "aria-label": "carregando", size: "md", color: "secondary" });
}
f.displayName = "InlineSvg";
export {
  f as default
};
