import e from "react";
import { DesktopContainer as l } from "./FormContainer.styles.js";
import p from "./FormMobileProvider.js";
import u from "./FormProvider.js";
const c = ({
  formId: t,
  onSearch: r,
  renderMobile: o,
  renderDesktop: m,
  className: n
}) => {
  const a = (i) => {
    i.preventDefault(), r();
  };
  return /* @__PURE__ */ e.createElement(u, { formId: t }, /* @__PURE__ */ e.createElement("form", { id: t, onSubmit: a, "data-testid": t, className: n }, /* @__PURE__ */ e.createElement(l, null, m()), /* @__PURE__ */ e.createElement(p, null, o())));
};
c.displayName = "FormContainer";
export {
  c as default
};
