import F, { createContext as M, useState as f, useCallback as V, useMemo as W, useContext as k } from "react";
import { useIsomorphicLayoutEffect as L } from "@cvccorp-components/chui-react-components";
import { getParams as e, EngineQueryParams as a, useParsedLocalStorage as j, LocalStorageKeys as p, gtm as Q, redirectToSearch as z } from "@cvccorp-engines/components";
import { d as h } from "../../dayjs-CxYy674R.js";
import { formatAgeToDate as J, formatPaxForService as X, DEFAULT_BUSING as Y } from "@cvccorp-engines/pax";
import Z from "../utils/formatGTMPayload.js";
import $ from "../utils/formatUrlToSearch.js";
const _ = M(null), q = (m) => {
  const {
    children: y,
    isAtlas: C,
    searchV2: N = !0
  } = m, [D, c] = f(() => e("ForwardOnly") === "true" || !1), [t, G] = f(() => {
    const r = {
      origin: void 0,
      destination: void 0
    };
    return e(a.ORIGIN_ID) && (r.origin = {
      name: e(a.ORIGIN_DESC),
      id: e(a.ORIGIN_ID)
    }), e(a.DESTINATION_ID) && (r.destination = {
      name: e(a.DESTINATION_DESC),
      id: e(a.DESTINATION_ID)
    }), r;
  }), [i, T] = f(() => {
    let r = Y;
    return (e(a.ADULTS) || e(a.CHILDREN) || e(a.CHILDREN_AGES)) && (r = {
      adult: Number(e(a.ADULTS)) || 1,
      children: Number(e(a.CHILDREN)) || 0,
      childrenDateOfBirth: e(a.CHILDREN_AGES).length ? e(a.CHILDREN_AGES).split(",").map((n) => h(String(J(n))).toString()) : []
    }), r;
  }), [x, O] = f([]), [s, S] = f(() => {
    const r = [];
    return e(a.CHECKIN) && (r[0] = h(e(a.CHECKIN)).toDate()), e(a.CHECKOUT) && (r[1] = h(e(a.CHECKOUT)).toDate()), r;
  }), [A, P] = f({
    origin: !1,
    destination: !1,
    sameLocation: !1,
    pax: !1,
    dates: {
      startDate: !1,
      endDate: !1
    }
  }), [d = [], H] = j(p.LAST_SEARCH_BUSING);
  L(() => {
    if (d != null && d.length) {
      const r = d[0];
      if (t != null && t.origin || E(r.origin, "ORIGIN"), t != null && t.destination || E(r.destination, "DESTINATION"), (!e(a.ADULTS) || !e(a.CHILDREN) || !e(a.CHILDREN_AGES)) && T(r.pax), e("ForwardOnly") || c(r.isOneWay), !s.length) {
        const n = r.calendarDates.map((o) => h(o).toDate());
        S(n.length ? n : []);
      }
    }
  }, []);
  const E = (r, n) => {
    O([]), G((o) => ({
      ...o,
      [n.toLowerCase()]: r
    }));
  }, U = (r) => P((n) => ({
    ...n,
    ...r
  })), v = V(() => {
    var B, R;
    const r = [!1, {}], n = !(t != null && t.origin), o = !(t != null && t.destination), l = (t == null ? void 0 : t.origin) && (t == null ? void 0 : t.destination) && ((B = t == null ? void 0 : t.origin) == null ? void 0 : B.name) === ((R = t == null ? void 0 : t.destination) == null ? void 0 : R.name), g = !(X([i]).length && i.childrenDateOfBirth.length === i.children), u = !s.length, b = s.length !== 2;
    return D ? (r[0] = [n, o, l, g, u].some((I) => I), r[1] = {
      origin: n,
      destination: o,
      sameLocation: l,
      pax: g,
      dates: {
        startDate: s.length < 1,
        endDate: !1
      }
    }) : (r[0] = [n, o, l, g, b].some((I) => I), r[1] = {
      origin: n,
      destination: o,
      sameLocation: l,
      pax: g,
      dates: {
        startDate: s.length < 1,
        endDate: s.length < 2
      }
    }), r;
  }, [D, t, i, s]), w = () => {
    const [r, n] = v(), o = i.childrenDateOfBirth.length < i.children;
    if (P({
      ...n,
      pax: o
    }), r || o) return;
    const l = {
      origin: t == null ? void 0 : t.origin,
      destination: t == null ? void 0 : t.destination,
      pax: i,
      calendarDates: s,
      isOneWay: D
    }, g = $(l), u = Z(l);
    H([l, ...d]), Q.onEngineSearch(u), z(g);
  }, K = W(() => ({
    pesqincLocationSelected: t,
    setPesqincLocationSelected: E,
    calendarDateSelected: s,
    setCalendarDateSelected: S,
    errors: A,
    setErrors: U,
    paxValue: i,
    setPaxValue: T,
    startDate: x,
    setStartDate: O,
    handleSearch: w,
    isOneWay: D,
    setIsOneWay: c,
    isAtlas: C,
    persistedBusing: d,
    searchV2: N
  }), [t, s, A, i, x, D, C, d, N]);
  return /* @__PURE__ */ F.createElement(_.Provider, { value: K }, y);
};
q.displayName = "EngineBusingProvider";
function it() {
  const m = k(_);
  if (!m) throw new Error("Chamada do hook useEngineBusingContext inválida! Certifique-se que ele esta sendo chamado dentro do contexto EngineBusingContext");
  return m;
}
export {
  _ as EngineBusingContext,
  q as EngineBusingProvider,
  it as useEngineBusingContext
};
