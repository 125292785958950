import e, { Children as C, isValidElement as E, cloneElement as c } from "react";
import { EnginesConfigProvider as p } from "@cvccorp-engines/provider";
import "../../context/ErrorContext/ErrorContext.js";
import f, { useCollapsableEngine as u } from "../../context/CollapsableEngineContext/CollapsableEngineContext.js";
import { Container as g, CollapsableContainer as v, EngineContainer as b } from "./styled.js";
const o = () => {
}, l = (n) => {
  const {
    renderContentAfter: a = o,
    renderContentBefore: i = o,
    renderExpandedEngine: d,
    children: m
  } = n, {
    value: t
  } = u(), s = C.map(m, (r) => E(r) ? c(r, {
    className: "engine-motor"
  }) : r);
  return /* @__PURE__ */ e.createElement(g, null, t ? void 0 : /* @__PURE__ */ e.createElement(v, null, i(), s, a()), /* @__PURE__ */ e.createElement(p, { searchButton: {
    color: "secondary"
  } }, t ? /* @__PURE__ */ e.createElement(b, null, d()) : void 0));
};
l.displayName = "Content";
const x = (n) => /* @__PURE__ */ e.createElement(f, null, /* @__PURE__ */ e.createElement(l, { ...n }));
x.displayName = "CollapsableEngineContainer";
export {
  x as default
};
