import e from "react";
import { DEFAULT_ROOM as x } from "../../constants/defaultValues.js";
import g from "../../utils/formatPaxPackages.js";
import C from "../Dropdown/Dropdown.js";
import d from "./PaxContent/PaxContent.js";
import E, { PaxConsumer as k } from "./PaxProvider/PaxProvider.js";
function A(m) {
  const {
    calendarDates: r,
    value: i = [x],
    error: n,
    open: l,
    onToggle: f,
    onConfirm: a,
    onClear: o,
    infantAge: c = 0,
    variant: p,
    maxAge: s
  } = m;
  return /* @__PURE__ */ e.createElement(E, { rooms: i, endDate: r == null ? void 0 : r.endDate, infantAge: c, variant: p, maxAge: s }, /* @__PURE__ */ e.createElement(k, null, ({
    rooms: t,
    onClearRooms: u,
    errors: P
  }) => /* @__PURE__ */ e.createElement(C, { open: l, onToggle: f, error: n, supportText: n ? "Preencha os ocupantes corretamente" : "", onClickOutside: () => {
    a(t);
  }, onConfirm: () => {
    P || a(t);
  }, onClear: () => {
    o == null || o(), u();
  }, label: g(t), renderContent: () => /* @__PURE__ */ e.createElement(d, null) })));
}
A.displayName = "PaxPackages";
export {
  A as default
};
