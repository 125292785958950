import i, { createContext as l, useMemo as d, useContext as f } from "react";
import g from "@cvccorp-engines/date";
const m = {
  color: "primary"
}, o = {
  searchButton: m,
  travelInsurance: {
    maxDate: g().add(730, "days").toDate(),
    minDateRange: 0
  }
}, a = l(o);
function v(s) {
  const {
    children: u,
    travelInsurance: t = {},
    searchButton: n,
    ...r
  } = s, e = C() ?? o, c = d(() => ({
    ...e,
    searchButton: {
      ...e.searchButton,
      ...n
    },
    travelInsurance: {
      ...e.travelInsurance,
      ...t
    },
    ...r
  }), [r, e, t, n]);
  return /* @__PURE__ */ i.createElement(a.Provider, { value: c }, u);
}
v.displayName = "EnginesConfigProvider";
function C() {
  return f(a);
}
export {
  v as default,
  C as useEnginesConfigContext
};
