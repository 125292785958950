import s from "../../libs/fetcher.js";
import "../../libs/cookies.js";
import { getHeadersBusing as e } from "../../resolver/getHeadersBusing.js";
const d = async ({
  searchString: o = "",
  qty: r = "10",
  locationId: t,
  searchV2: a,
  isAtlas: i
}) => {
  try {
    const {
      data: n
    } = await s.get(`/api/dynamic-busing/${a ? "v2" : "v1"}/cvc/road/locations`, {
      headers: e(i),
      params: {
        ...o && {
          name: o,
          description: o
        },
        ...t && {
          id: t
        },
        quant: r
      }
    });
    return n;
  } catch {
    throw new Error("Error in Busing Locations");
  }
};
export {
  d as getBusingLocations
};
