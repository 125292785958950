import { getParams as e } from "@cvccorp-engines/components";
const o = () => {
  const t = {
    iata: e("IataOri", ""),
    description: e("OriginDesc", ""),
    zoneId: Number(e("OriginZoneId", "0")),
    type: e("OriginType", ""),
    city: e("City", ""),
    state: e("State", ""),
    country: e("Country", ""),
    officeHours: []
  };
  return t.description ? t : void 0;
}, i = () => e("CustomLocation") === "true", r = () => {
  const t = {
    iata: e("IataDes", ""),
    description: e("DestinationDesc", ""),
    zoneId: Number(e("DestZoneId", "0")),
    type: e("DestinationType", ""),
    city: "",
    state: "",
    country: "",
    officeHours: []
  };
  return i() ? t.description ? void 0 : t : t.description ? t : void 0;
}, c = () => {
  const t = e("CheckIn");
  return t ? t.concat("T03:00:00.000Z") : "";
}, s = () => {
  const t = e("CheckInTime", "");
  return t.split(":").length === 2 ? t : "";
}, u = () => {
  const t = e("CheckOut") ?? "";
  return t ? t.concat("T03:00:00.000Z") : "";
}, l = () => {
  const t = e("CheckoutTime", "") ?? "";
  return t.split(":").length === 2 ? t : "";
};
export {
  i as getCarsCustomLocationInitialValue,
  r as getCarsDestinationInitialValue,
  o as getCarsOriginInitialValue,
  c as getCarsPickupDateInitialValue,
  s as getCarsPickupTimeInitialValue,
  u as getCarsReturnDateInitialValue,
  l as getCarsReturnTimeInitialValue
};
