import { create as t } from "zustand";
import { getPackagePaxInitialValue as o, getPackageStartDateInitialValue as s, getPackageEndDateInitialValue as l, getPackageOriginInitialValue as d, getPackageDestinationInitialValue as g } from "../../utils/getPackageInitialValue.js";
const i = {
  destination: !1,
  origin: !1,
  originDestination: !1,
  calendarDates: {
    endDate: !1,
    startDate: !1
  },
  pax: !1
}, D = [{
  adult: 2,
  children: 0,
  childrenDateOfBirth: []
}], P = t((e) => ({
  pax: o(),
  calendarDates: {
    startDate: s(),
    endDate: l()
  },
  origin: d(),
  destination: g(),
  errors: i,
  errorsPax: {},
  openPax: !1,
  setDestination: (r) => e((a) => ({
    destination: r,
    errors: {
      ...a.errors,
      destination: i.destination,
      originDestination: i.originDestination
    }
  })),
  setOrigin: (r) => e((a) => ({
    origin: r,
    errors: {
      ...a.errors,
      origin: i.origin,
      originDestination: i.originDestination
    }
  })),
  setCalendarDates: (r) => e((a) => ({
    calendarDates: r,
    errors: {
      ...a.errors,
      calendarDates: i.calendarDates
    }
  })),
  setPax: (r) => e((a) => ({
    pax: r,
    errorsPax: {},
    errors: {
      ...a.errors,
      pax: !1
    }
  })),
  setErrors: (r) => e(() => ({
    errors: r
  })),
  setErrorsPax: (r) => e(() => ({
    errorsPax: r
  })),
  setOpenPax: (r) => e(() => ({
    openPax: r
  })),
  resetTempPax() {
    return e((r) => ({
      errorsPax: {},
      errors: {
        ...r.errors,
        pax: !1
      }
    }));
  },
  clearPax: () => e((r) => ({
    pax: D,
    errors: {
      ...r.errors,
      pax: !1
    },
    errorsPax: {}
  })),
  clearDates: () => e((r) => ({
    calendarDates: {
      endDate: void 0,
      startDate: void 0
    },
    errors: {
      ...r.errors,
      calendarDates: i.calendarDates
    }
  })),
  reverse() {
    return e((r) => {
      var a, n;
      return {
        destination: r.origin,
        origin: r.destination,
        errors: {
          ...r.errors,
          destination: (a = r.errors) == null ? void 0 : a.origin,
          origin: (n = r.errors) == null ? void 0 : n.destination
        }
      };
    });
  }
}));
export {
  D as defaultPackagePax,
  P as usePackageStore
};
