import g, { useState as B, useEffect as _, useCallback as C } from "react";
import A from "@cvccorp-components/chui-react-components/components/Autocomplete";
import { ESearchTypes as b, getBookingPackagesLocations as G } from "@cvccorp-engines/api";
import { useError as D, getParams as k } from "@cvccorp-engines/components";
import { u as M } from "../../../EngineBookingPackagesContext-D4tagYeS.js";
import { getBookingPackagesParamsForLogs as L } from "../../../utils/getBookingPackagesParamsForLogs.js";
function h() {
  return h = Object.assign ? Object.assign.bind() : function(i) {
    for (var n = 1; n < arguments.length; n++) {
      var t = arguments[n];
      for (var e in t) ({}).hasOwnProperty.call(t, e) && (i[e] = t[e]);
    }
    return i;
  }, h.apply(null, arguments);
}
function y(i, n) {
  if (i == null) return {};
  var t = {};
  for (var e in i) if ({}.hasOwnProperty.call(i, e)) {
    if (n.includes(e)) continue;
    t[e] = i[e];
  }
  return t;
}
var U = {
  size: {
    xs: "0.75rem",
    sm: "1rem",
    md: "1.25rem",
    lg: "1.5rem",
    xl: "2rem",
    xxl: "2.5rem"
  }
}, c = U.size, z = {
  xsmall: c.xs,
  small: c.sm,
  medium: c.md,
  large: c.lg,
  xlarge: c.xl,
  xxlarge: c.xxl
}, F = ["size", "className"], I = function(n) {
  var t, e, a = n.size, s = a === void 0 ? "medium" : a, l = n.className, x = l === void 0 ? "" : l, m = y(n, F), r = m, u = ["chui-icon", x].join(" "), o = r.color || "#000014", v = h({}, m, {
    color: void 0
  }), d = c[s] || z[s];
  return /* @__PURE__ */ g.createElement("svg", h({
    xmlns: "http://www.w3.org/2000/svg",
    fill: o,
    viewBox: "0 0 24 24",
    width: (t = m.width) != null ? t : d,
    height: (e = m.height) != null ? e : d,
    className: u
  }, v), /* @__PURE__ */ g.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "m9.924 7.938 1.003 1.775c.232.4-.05.934-.566.934H7.776c-.401 0-.774-.132-1.075-.397L5.421 9.14C4.81 8.597 3.99 8.3 3.146 8.3h-.143a.03.03 0 0 0-.015.004.1.1 0 0 0-.016.013.1.1 0 0 0-.008.019l.001.014v.001l1.342 3.898c.222.653.867 1.108 1.6 1.108h2.925q.06-.016.124-.016h10.677c.772 0 1.402-.605 1.402-1.347 0-.74-.63-1.347-1.402-1.347h-3.431a.67.67 0 0 1-.445-.174L11.58 6.7h-.001a1.05 1.05 0 0 0-.723-.273c-.8 0-1.317.822-.932 1.51m-.107 6.344H5.91c-1.15 0-2.165-.71-2.517-1.745l-1.34-3.895c-.216-.623.273-1.27.952-1.27h.143c1.072 0 2.132.377 2.927 1.089l1.279 1.105c.114.102.25.154.423.154h2.057l-.757-1.34v-.002c-.732-1.306.25-2.877 1.78-2.877.52 0 1.005.181 1.384.525l4.093 3.694h3.302c1.303 0 2.365 1.02 2.365 2.273s-1.062 2.274-2.366 2.274h-3.303l-4.106 3.709c-.38.345-.884.525-1.385.525-1.53 0-2.512-1.571-1.78-2.877v-.001zm1.094.006-1.003 1.776c-.385.688.132 1.51.932 1.51.265 0 .529-.096.723-.272V17.3l3.346-3.021-3.988-.009zm5.521-4.479-.008-.007z",
    clipRule: "evenodd"
  }));
};
I.displayName = "OutlinePlaneRight";
var H = ["size", "className"], T = function(n) {
  var t, e, a = n.size, s = a === void 0 ? "medium" : a, l = n.className, x = l === void 0 ? "" : l, m = y(n, H), r = m, u = ["chui-icon", x].join(" "), o = r.color || "#000014", v = h({}, m, {
    color: void 0
  }), d = c[s] || z[s];
  return /* @__PURE__ */ g.createElement("svg", h({
    xmlns: "http://www.w3.org/2000/svg",
    fill: o,
    viewBox: "0 0 24 24",
    width: (t = m.width) != null ? t : d,
    height: (e = m.height) != null ? e : d,
    className: u
  }, v), /* @__PURE__ */ g.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M12 5.777a3.695 3.695 0 1 0 0 7.39 3.695 3.695 0 0 0 0-7.39M9.307 9.472a2.695 2.695 0 1 1 5.39 0 2.695 2.695 0 0 1-5.39 0",
    clipRule: "evenodd"
  }), /* @__PURE__ */ g.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M12 2.221a7.24 7.24 0 0 0-7.25 7.25c0 2.436 1.155 4.988 2.478 7.11 1.331 2.133 2.876 3.901 3.747 4.785l.002.002a1.433 1.433 0 0 0 2.021 0c.87-.87 2.422-2.612 3.76-4.738 1.332-2.114 2.493-4.672 2.493-7.158a7.24 7.24 0 0 0-7.25-7.25m-6.25 7.25A6.24 6.24 0 0 1 12 3.222a6.24 6.24 0 0 1 6.251 6.25c0 2.204-1.042 4.567-2.339 6.627a28 28 0 0 1-3.62 4.563.433.433 0 0 1-.607.001c-.83-.843-2.325-2.553-3.608-4.61-1.292-2.072-2.327-4.429-2.327-6.58",
    clipRule: "evenodd"
  }));
};
T.displayName = "OutlinePinMap";
const V = {
  airport: /* @__PURE__ */ g.createElement(I, null),
  location: /* @__PURE__ */ g.createElement(T, null)
}, w = (i) => (i == null ? void 0 : i.filter(({
  IATA: t
}) => !!t.trim())).map((t, e) => {
  var s;
  const a = {
    ...t,
    ...(t == null ? void 0 : t.id) && {
      id: Number(t.id)
    },
    key: e,
    name: `${t.city}, ${t.state}`,
    address: t.description,
    type: (t == null ? void 0 : t.type) === "CITY" ? "location" : (s = t == null ? void 0 : t.type) == null ? void 0 : s.toLowerCase()
  };
  return {
    label: a.name,
    value: a
  };
}), X = ({
  searchType: i,
  isUniqueDirection: n,
  onSelect: t
}) => {
  const {
    pesqincLocationSelected: e
  } = M(), [a, s] = B(() => i === "DESTINATION" && e.destination ? [...w([e.destination])] : i === "ORIGIN" && e.origin ? [...w([e.origin])] : []), {
    throwError: l
  } = D();
  return _(() => {
    const r = [];
    Object.keys(e).map((u) => {
      const o = e[u];
      o && (a.find((d) => d.value.id === o.id) || r.push(o));
    }), s([...a, ...w(r)]);
  }, [e]), {
    onSearch: C(
      async (r) => {
        var d, O, $, N, E, P;
        const u = k("packageGroup") ? String(k("packageGroup")).toUpperCase() : "PACKAGE", o = L({
          packageGroup: u,
          player: null
        }), v = (/* @__PURE__ */ new Date()).getTime();
        try {
          const p = {
            type: i,
            searchString: r,
            uniqueDirection: n
          };
          i === b.ORIGIN && ((d = e == null ? void 0 : e.destination) != null && d.id) && (p.locationId = (O = e == null ? void 0 : e.destination) == null ? void 0 : O.id), i === b.DESTINATION && (($ = e == null ? void 0 : e.origin) != null && $.id) && (p.locationId = (N = e == null ? void 0 : e.origin) == null ? void 0 : N.id);
          const f = await G(p);
          if (!((E = f == null ? void 0 : f.meta) != null && E.total) || !((P = f.locations) != null && P.length)) {
            const j = (/* @__PURE__ */ new Date()).getTime() - v;
            return Object.assign(o, {
              message: `EMPTYRESULT ${r}`,
              duration: j,
              durationUnit: "milisseconds"
            }), s([]), l == null ? void 0 : l(o);
          }
          return s(w(f.locations));
        } catch (p) {
          const R = (/* @__PURE__ */ new Date()).getTime() - v;
          return Object.assign(o, {
            message: `ERROR ${r}`,
            duration: R,
            durationUnit: "milisseconds",
            level: 4,
            errorResponse: p
          }), s([]), l == null ? void 0 : l(o);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [n, i]
    ),
    options: a,
    renderOption: ({
      option: r
    }) => {
      var u;
      return /* @__PURE__ */ g.createElement(A.Option, { fullWidth: !0, label: r == null ? void 0 : r.label, supportText: (u = r == null ? void 0 : r.value) == null ? void 0 : u.description, onSelect: () => t(r.value, i), addonBefore: V[r.value.type], style: {
        display: "flex"
      } });
    },
    onSelect: (r) => {
      t(r, i);
    }
  };
};
export {
  X as default
};
