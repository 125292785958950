import n from "react";
import { Switch as a } from "@cvccorp-components/chui-react-components";
import { u as o } from "../../../EngineBookingPackagesContext-D4tagYeS.js";
const i = () => {
  const {
    isMonthPicker: e,
    setIsMonthPicker: t
  } = o();
  return /* @__PURE__ */ n.createElement(a, { checked: e, onChange: t, label: "Ainda não defini as datas" });
};
i.displayName = "CalendarMonthPickerSwitch";
export {
  i as default
};
