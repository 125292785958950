import t from "react";
import { d as a } from "../../../dayjs-CxYy674R.js";
import { useEngineBusingCharterContext as o } from "../../context/EngineBusingCharterContext.js";
import m from "./CalendarPicker/CalendarPicker.js";
import s from "./CalendarRangePicker/CalendarRangePicker.js";
const d = () => {
  const {
    isExcursion: n,
    isOriginAndDestination: i
  } = o(), e = a().toDate(), r = a().add(1, "years").toDate();
  return n ? null : i ? /* @__PURE__ */ t.createElement(m, { minDate: e, maxDate: r }) : /* @__PURE__ */ t.createElement(s, { minDate: e, maxDate: r });
};
d.displayName = "Calendar";
export {
  d as default
};
