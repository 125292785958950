import A, { useMemo as st } from "react";
import { Button as ut } from "@cvccorp-components/chui-react-components";
import { EngineBookingPackages as ct } from "@cvccorp-engines/booking-packages";
import { useToggle as ft, useQueryParam as L, EngineQueryParams as H, CollapsableEngineContainer as dt, TripDestination as lt, DateIntervalLabel as ht, DateFormat as mt } from "@cvccorp-engines/components";
import { countTotalTravelersQueryParams as yt, PaxShort as pt } from "@cvccorp-engines/pax";
import { EngineContainer as $t, Grid as Mt } from "./styled.js";
var R = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function tt(g) {
  return g && g.__esModule && Object.prototype.hasOwnProperty.call(g, "default") ? g.default : g;
}
var nt = { exports: {} };
(function(g, W) {
  (function(v, h) {
    g.exports = h();
  })(R, function() {
    var v = 1e3, h = 6e4, f = 36e5, _ = "millisecond", $ = "second", y = "minute", w = "hour", k = "day", C = "week", i = "month", s = "quarter", m = "year", c = "date", B = "Invalid Date", P = /^(\d{4})[-/]?(\d{1,2})?[-/]?(\d{0,2})[Tt\s]*(\d{1,2})?:?(\d{1,2})?:?(\d{1,2})?[.:]?(\d+)?$/, j = /\[([^\]]+)]|Y{1,4}|M{1,4}|D{1,2}|d{1,4}|H{1,2}|h{1,2}|a|A|m{1,2}|s{1,2}|Z{1,2}|SSS/g, K = {
      name: "en",
      weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"),
      months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"),
      ordinal: function(t) {
        var e = ["th", "st", "nd", "rd"], r = t % 100;
        return "[" + t + (e[(r - 20) % 10] || e[r] || e[0]) + "]";
      }
    }, U = function(t, e, r) {
      var n = String(t);
      return !n || n.length >= e ? t : "" + Array(e + 1 - n.length).join(r) + t;
    }, z = {
      s: U,
      z: function(t) {
        var e = -t.utcOffset(), r = Math.abs(e), n = Math.floor(r / 60), a = r % 60;
        return (e <= 0 ? "+" : "-") + U(n, 2, "0") + ":" + U(a, 2, "0");
      },
      m: function d(t, e) {
        if (t.date() < e.date()) return -d(e, t);
        var r = 12 * (e.year() - t.year()) + (e.month() - t.month()), n = t.clone().add(r, i), a = e - n < 0, o = t.clone().add(r + (a ? -1 : 1), i);
        return +(-(r + (e - n) / (a ? n - o : o - n)) || 0);
      },
      a: function(t) {
        return t < 0 ? Math.ceil(t) || 0 : Math.floor(t);
      },
      p: function(t) {
        return {
          M: i,
          y: m,
          w: C,
          d: k,
          D: c,
          h: w,
          m: y,
          s: $,
          ms: _,
          Q: s
        }[t] || String(t || "").toLowerCase().replace(/s$/, "");
      },
      u: function(t) {
        return t === void 0;
      }
    }, I = "en", x = {};
    x[I] = K;
    var q = "$isDayjsObject", X = function(t) {
      return t instanceof Z || !(!t || !t[q]);
    }, J = function d(t, e, r) {
      var n;
      if (!t) return I;
      if (typeof t == "string") {
        var a = t.toLowerCase();
        x[a] && (n = a), e && (x[a] = e, n = a);
        var o = t.split("-");
        if (!n && o.length > 1) return d(o[0]);
      } else {
        var l = t.name;
        x[l] = t, n = l;
      }
      return !r && n && (I = n), n || !r && I;
    }, M = function(t, e) {
      if (X(t)) return t.clone();
      var r = typeof e == "object" ? e : {};
      return r.date = t, r.args = arguments, new Z(r);
    }, u = z;
    u.l = J, u.i = X, u.w = function(d, t) {
      return M(d, {
        locale: t.$L,
        utc: t.$u,
        x: t.$x,
        $offset: t.$offset
      });
    };
    var Z = function() {
      function d(e) {
        this.$L = J(e.locale, null, !0), this.parse(e), this.$x = this.$x || e.x || {}, this[q] = !0;
      }
      var t = d.prototype;
      return t.parse = function(e) {
        this.$d = function(r) {
          var n = r.date, a = r.utc;
          if (n === null) return /* @__PURE__ */ new Date(NaN);
          if (u.u(n)) return /* @__PURE__ */ new Date();
          if (n instanceof Date) return new Date(n);
          if (typeof n == "string" && !/Z$/i.test(n)) {
            var o = n.match(P);
            if (o) {
              var l = o[2] - 1 || 0, p = (o[7] || "0").substring(0, 3);
              return a ? new Date(Date.UTC(o[1], l, o[3] || 1, o[4] || 0, o[5] || 0, o[6] || 0, p)) : new Date(o[1], l, o[3] || 1, o[4] || 0, o[5] || 0, o[6] || 0, p);
            }
          }
          return new Date(n);
        }(e), this.init();
      }, t.init = function() {
        var e = this.$d;
        this.$y = e.getFullYear(), this.$M = e.getMonth(), this.$D = e.getDate(), this.$W = e.getDay(), this.$H = e.getHours(), this.$m = e.getMinutes(), this.$s = e.getSeconds(), this.$ms = e.getMilliseconds();
      }, t.$utils = function() {
        return u;
      }, t.isValid = function() {
        return this.$d.toString() !== B;
      }, t.isSame = function(e, r) {
        var n = M(e);
        return this.startOf(r) <= n && n <= this.endOf(r);
      }, t.isAfter = function(e, r) {
        return M(e) < this.startOf(r);
      }, t.isBefore = function(e, r) {
        return this.endOf(r) < M(e);
      }, t.$g = function(e, r, n) {
        return u.u(e) ? this[r] : this.set(n, e);
      }, t.unix = function() {
        return Math.floor(this.valueOf() / 1e3);
      }, t.valueOf = function() {
        return this.$d.getTime();
      }, t.startOf = function(e, r) {
        var n = this, a = !!u.u(r) || r, o = u.p(e), l = function(T, O) {
          var E = u.w(n.$u ? Date.UTC(n.$y, O, T) : new Date(n.$y, O, T), n);
          return a ? E : E.endOf(k);
        }, p = function(T, O) {
          return u.w(n.toDate()[T].apply(n.toDate("s"), (a ? [0, 0, 0, 0] : [23, 59, 59, 999]).slice(O)), n);
        }, D = this.$W, S = this.$M, b = this.$D, Y = "set" + (this.$u ? "UTC" : "");
        switch (o) {
          case m:
            return a ? l(1, 0) : l(31, 11);
          case i:
            return a ? l(1, S) : l(0, S + 1);
          case C:
            var N = this.$locale().weekStart || 0, Q = (D < N ? D + 7 : D) - N;
            return l(a ? b - Q : b + (6 - Q), S);
          case k:
          case c:
            return p(Y + "Hours", 0);
          case w:
            return p(Y + "Minutes", 1);
          case y:
            return p(Y + "Seconds", 2);
          case $:
            return p(Y + "Milliseconds", 3);
          default:
            return this.clone();
        }
      }, t.endOf = function(e) {
        return this.startOf(e, !1);
      }, t.$set = function(e, r) {
        var n, a = u.p(e), o = "set" + (this.$u ? "UTC" : ""), l = (n = {}, n[k] = o + "Date", n[c] = o + "Date", n[i] = o + "Month", n[m] = o + "FullYear", n[w] = o + "Hours", n[y] = o + "Minutes", n[$] = o + "Seconds", n[_] = o + "Milliseconds", n)[a], p = a === k ? this.$D + (r - this.$W) : r;
        if (a === i || a === m) {
          var D = this.clone().set(c, 1);
          D.$d[l](p), D.init(), this.$d = D.set(c, Math.min(this.$D, D.daysInMonth())).$d;
        } else l && this.$d[l](p);
        return this.init(), this;
      }, t.set = function(e, r) {
        return this.clone().$set(e, r);
      }, t.get = function(e) {
        return this[u.p(e)]();
      }, t.add = function(e, r) {
        var n, a = this;
        e = Number(e);
        var o = u.p(r), l = function(b) {
          var Y = M(a);
          return u.w(Y.date(Y.date() + Math.round(b * e)), a);
        };
        if (o === i) return this.set(i, this.$M + e);
        if (o === m) return this.set(m, this.$y + e);
        if (o === k) return l(1);
        if (o === C) return l(7);
        var p = (n = {}, n[y] = h, n[w] = f, n[$] = v, n)[o] || 1, D = this.$d.getTime() + e * p;
        return u.w(D, this);
      }, t.subtract = function(e, r) {
        return this.add(-1 * e, r);
      }, t.format = function(e) {
        var r = this, n = this.$locale();
        if (!this.isValid()) return n.invalidDate || B;
        var a = e || "YYYY-MM-DDTHH:mm:ssZ", o = u.z(this), l = this.$H, p = this.$m, D = this.$M, S = n.weekdays, b = n.months, Y = n.meridiem, N = function(O, E, V, it) {
          return O && (O[E] || O(r, a)) || V[E].slice(0, it);
        }, Q = function(O) {
          return u.s(l % 12 || 12, O, "0");
        }, G = Y || function(T, O, E) {
          var V = T < 12 ? "AM" : "PM";
          return E ? V.toLowerCase() : V;
        };
        return a.replace(j, function(T, O) {
          return O || function(E) {
            switch (E) {
              case "YY":
                return String(r.$y).slice(-2);
              case "YYYY":
                return u.s(r.$y, 4, "0");
              case "M":
                return D + 1;
              case "MM":
                return u.s(D + 1, 2, "0");
              case "MMM":
                return N(n.monthsShort, D, b, 3);
              case "MMMM":
                return N(b, D);
              case "D":
                return r.$D;
              case "DD":
                return u.s(r.$D, 2, "0");
              case "d":
                return String(r.$W);
              case "dd":
                return N(n.weekdaysMin, r.$W, S, 2);
              case "ddd":
                return N(n.weekdaysShort, r.$W, S, 3);
              case "dddd":
                return S[r.$W];
              case "H":
                return String(l);
              case "HH":
                return u.s(l, 2, "0");
              case "h":
                return Q(1);
              case "hh":
                return Q(2);
              case "a":
                return G(l, p, !0);
              case "A":
                return G(l, p, !1);
              case "m":
                return String(p);
              case "mm":
                return u.s(p, 2, "0");
              case "s":
                return String(r.$s);
              case "ss":
                return u.s(r.$s, 2, "0");
              case "SSS":
                return u.s(r.$ms, 3, "0");
              case "Z":
                return o;
            }
            return null;
          }(T) || o.replace(":", "");
        });
      }, t.utcOffset = function() {
        return 15 * -Math.round(this.$d.getTimezoneOffset() / 15);
      }, t.diff = function(e, r, n) {
        var a, o = this, l = u.p(r), p = M(e), D = (p.utcOffset() - this.utcOffset()) * h, S = this - p, b = function() {
          return u.m(o, p);
        };
        switch (l) {
          case m:
            a = b() / 12;
            break;
          case i:
            a = b();
            break;
          case s:
            a = b() / 3;
            break;
          case C:
            a = (S - D) / 6048e5;
            break;
          case k:
            a = (S - D) / 864e5;
            break;
          case w:
            a = S / f;
            break;
          case y:
            a = S / h;
            break;
          case $:
            a = S / v;
            break;
          default:
            a = S;
        }
        return n ? a : u.a(a);
      }, t.daysInMonth = function() {
        return this.endOf(i).$D;
      }, t.$locale = function() {
        return x[this.$L];
      }, t.locale = function(e, r) {
        if (!e) return this.$L;
        var n = this.clone(), a = J(e, r, !0);
        return a && (n.$L = a), n;
      }, t.clone = function() {
        return u.w(this.$d, this);
      }, t.toDate = function() {
        return new Date(this.valueOf());
      }, t.toJSON = function() {
        return this.isValid() ? this.toISOString() : null;
      }, t.toISOString = function() {
        return this.$d.toISOString();
      }, t.toString = function() {
        return this.$d.toUTCString();
      }, d;
    }(), et = Z.prototype;
    return M.prototype = et, [["$ms", _], ["$s", $], ["$m", y], ["$H", w], ["$W", k], ["$M", i], ["$y", m], ["$D", c]].forEach(function(d) {
      et[d[1]] = function(t) {
        return this.$g(t, d[0], d[1]);
      };
    }), M.extend = function(d, t) {
      return d.$i || (d(t, Z, M), d.$i = !0), M;
    }, M.locale = J, M.isDayjs = X, M.unix = function(d) {
      return M(1e3 * d);
    }, M.en = x[I], M.Ls = x, M.p = {}, M;
  });
})(nt);
var rt = nt.exports;
const F = /* @__PURE__ */ tt(rt);
var Dt = { exports: {} };
(function(g, W) {
  (function(v, h) {
    g.exports = h();
  })(R, function() {
    return function(v, h) {
      h.prototype.isSameOrAfter = function(f, _) {
        return this.isSame(f, _) || this.isAfter(f, _);
      };
    };
  });
})(Dt);
var at = { exports: {} };
(function(g, W) {
  (function(v, h) {
    g.exports = h();
  })(R, function() {
    return function(v, h) {
      h.prototype.isSameOrBefore = function(f, _) {
        return this.isSame(f, _) || this.isBefore(f, _);
      };
    };
  });
})(at);
var gt = at.exports;
const _t = /* @__PURE__ */ tt(gt);
var ot = { exports: {} };
(function(g, W) {
  (function(v, h) {
    g.exports = h();
  })(R, function() {
    return function(v, h, f) {
      var _ = h.prototype, $ = function(s) {
        return s && (s.indexOf ? s : s.s);
      }, y = function(s, m, c, B, P) {
        var j = s.name ? s : s.$locale(), K = $(j[m]), U = $(j[c]), z = K || U.map(function(x) {
          return x.slice(0, B);
        });
        if (!P) return z;
        var I = j.weekStart;
        return z.map(function(x, q) {
          return z[(q + (I || 0)) % 7];
        });
      }, w = function() {
        return f.Ls[f.locale()];
      }, k = function(s, m) {
        return s.formats[m] || function(c) {
          return c.replace(/(\[[^\]]+])|(MMMM|MM|DD|dddd)/g, function(B, P, j) {
            return P || j.slice(1);
          });
        }(s.formats[m.toUpperCase()]);
      }, C = function() {
        var s = this;
        return {
          months: function(c) {
            return c ? c.format("MMMM") : y(s, "months");
          },
          monthsShort: function(c) {
            return c ? c.format("MMM") : y(s, "monthsShort", "months", 3);
          },
          firstDayOfWeek: function() {
            return s.$locale().weekStart || 0;
          },
          weekdays: function(c) {
            return c ? c.format("dddd") : y(s, "weekdays");
          },
          weekdaysMin: function(c) {
            return c ? c.format("dd") : y(s, "weekdaysMin", "weekdays", 2);
          },
          weekdaysShort: function(c) {
            return c ? c.format("ddd") : y(s, "weekdaysShort", "weekdays", 3);
          },
          longDateFormat: function(c) {
            return k(s.$locale(), c);
          },
          meridiem: this.$locale().meridiem,
          ordinal: this.$locale().ordinal
        };
      };
      _.localeData = function() {
        return C.bind(this)();
      }, f.localeData = function() {
        var i = w();
        return {
          firstDayOfWeek: function() {
            return i.weekStart || 0;
          },
          weekdays: function() {
            return f.weekdays();
          },
          weekdaysShort: function() {
            return f.weekdaysShort();
          },
          weekdaysMin: function() {
            return f.weekdaysMin();
          },
          months: function() {
            return f.months();
          },
          monthsShort: function() {
            return f.monthsShort();
          },
          longDateFormat: function(m) {
            return k(i, m);
          },
          meridiem: i.meridiem,
          ordinal: i.ordinal
        };
      }, f.months = function() {
        return y(w(), "months");
      }, f.monthsShort = function() {
        return y(w(), "monthsShort", "months", 3);
      }, f.weekdays = function(i) {
        return y(w(), "weekdays", null, null, i);
      }, f.weekdaysShort = function(i) {
        return y(w(), "weekdaysShort", "weekdays", 3, i);
      }, f.weekdaysMin = function(i) {
        return y(w(), "weekdaysMin", "weekdays", 2, i);
      };
    };
  });
})(ot);
var wt = ot.exports;
const St = /* @__PURE__ */ tt(wt);
var vt = { exports: {} };
(function(g, W) {
  (function(v, h) {
    g.exports = h(rt);
  })(R, function(v) {
    function h($) {
      return $ && typeof $ == "object" && "default" in $ ? $ : {
        default: $
      };
    }
    var f = h(v), _ = {
      name: "pt-br",
      weekdays: "domingo_segunda-feira_terça-feira_quarta-feira_quinta-feira_sexta-feira_sábado".split("_"),
      weekdaysShort: "dom_seg_ter_qua_qui_sex_sáb".split("_"),
      weekdaysMin: "Do_2ª_3ª_4ª_5ª_6ª_Sá".split("_"),
      months: "janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro".split("_"),
      monthsShort: "jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez".split("_"),
      ordinal: function(y) {
        return y + "º";
      },
      formats: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD/MM/YYYY",
        LL: "D [de] MMMM [de] YYYY",
        LLL: "D [de] MMMM [de] YYYY [às] HH:mm",
        LLLL: "dddd, D [de] MMMM [de] YYYY [às] HH:mm"
      },
      relativeTime: {
        future: "em %s",
        past: "há %s",
        s: "poucos segundos",
        m: "um minuto",
        mm: "%d minutos",
        h: "uma hora",
        hh: "%d horas",
        d: "um dia",
        dd: "%d dias",
        M: "um mês",
        MM: "%d meses",
        y: "um ano",
        yy: "%d anos"
      }
    };
    return f.default.locale(_, null, !0), _;
  });
})(vt);
F.locale("pt-br");
F.extend(_t);
F.extend(St);
const kt = (g) => {
  const {
    toggle: W
  } = ft(), v = L(H.IATA_ORI), h = L(H.IATA_DES), f = L(H.CITY_ORIGIN), _ = L(H.CITY), $ = L(H.CHECKIN, (/* @__PURE__ */ new Date()).toString()), y = L(H.CHECKOUT, F(/* @__PURE__ */ new Date()).add(5, "days").toString()), w = L(H.CHILDREN, "0"), k = L(H.ADULTS, "1"), C = L(H.ROOMS, "1"), i = st(() => yt({
    children: w,
    adults: k
  }), [k, w]);
  return /* @__PURE__ */ A.createElement(dt, { ...g, renderExpandedEngine: () => /* @__PURE__ */ A.createElement($t, null, /* @__PURE__ */ A.createElement(ct, null)) }, /* @__PURE__ */ A.createElement(Mt, { container: !0, direction: "row", alignItems: "center" }, /* @__PURE__ */ A.createElement(lt, { origin: {
    name: v,
    address: f
  }, destination: {
    name: h,
    address: _
  }, type: "AIRPLANE" }), /* @__PURE__ */ A.createElement(ht, { initialDate: F($).toDate(), finalDate: F(y).toDate(), dateFormat: mt.DAY_AND_MONTH }), /* @__PURE__ */ A.createElement(pt, { peopleQuantity: i, roomsQuantity: Number(C) }), /* @__PURE__ */ A.createElement(ut, { variant: "outline", color: "secondary", size: "sm", onClick: W }, "Alterar")));
};
kt.displayName = "EngineBookingPackagesCollapsable";
export {
  kt as default
};
