import e from "@emotion/styled";
const i = e.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 6px;

    & > label {
        align-self: flex-end;
    }
`;
export {
  i as Wrapper
};
