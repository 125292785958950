import type { ProductError } from "@attentions-components/hooks/dist/@types/error";
import { ErrorProvider, useError } from "@cvccorp-engines/components";
import { Navigation } from "@cvccorp-engines/navigation";
import { Engine } from "@cvccorp-engines/search";

function EngineWrapper() {
    const { throwError } = useError();
    return (
        <ErrorProvider
            onError={params => {
                throwError?.(params as ProductError);
            }}
        >
            <Engine renderNavigation={({ setProduct }) => <Navigation onClick={setProduct} />} />
        </ErrorProvider>
    );
}

export default EngineWrapper;
