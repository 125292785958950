import m, { useMemo as r } from "react";
import a from "@cvccorp-components/chui-react-components/components/Select";
import { useAutoCompleteItemSize as s } from "@cvccorp-engines/components";
import { usePax as c } from "../PaxPackages/PaxProvider/PaxProvider.js";
import l from "./getOptionsAgeInputSelect.js";
import { Container as p } from "./SelectAges.styles.js";
const u = 16, A = (e) => {
  const {
    infantAge: t,
    variant: o
  } = c(), n = s(), i = r(() => l(t, e.maxAge ?? u, o), [t, o, e.maxAge]);
  return /* @__PURE__ */ m.createElement(p, null, /* @__PURE__ */ m.createElement(a, { inputProps: {
    size: "md",
    ...e.inputProps
  }, itemSize: n, ...e, options: i }));
};
A.displayName = "SelectAge";
export {
  A as default
};
