import N, { useState as x, useEffect as w, useCallback as G } from "react";
import U from "@cvccorp-components/chui-react-components/components/Autocomplete";
import { r as j } from "../../../../OutlinePinMap-hG5rf3UN.js";
import { getBusingLocations as v } from "@cvccorp-engines/api";
import { useError as C } from "@cvccorp-engines/components";
import { useEngineBusingContext as F } from "../../../context/EngineBusingContext.js";
import { getBusingParamsForLogs as $ } from "../../../utils/getBusingParamsForLogs.js";
import { EPesqincSearchType as u } from "../Pesqinc.types.js";
const B = (n) => n.map((d) => ({
  label: d.name,
  value: d
})), Z = ({
  searchType: n,
  isUniqueDirection: d,
  onSelect: l
}) => {
  const [T, g] = x([]), {
    pesqincLocationSelected: r,
    isAtlas: R,
    persistedBusing: D,
    searchV2: b
  } = F(), {
    throwError: a
  } = C(), A = async () => {
    var t, f, I, O;
    const m = {
      isAtlas: R,
      searchV2: b
    };
    n === u.ORIGIN && ((t = r == null ? void 0 : r.destination) != null && t.id) && (m.locationId = (f = r == null ? void 0 : r.destination) == null ? void 0 : f.id), n === u.DESTINATION && ((I = r == null ? void 0 : r.origin) != null && I.id) && (m.locationId = (O = r == null ? void 0 : r.origin) == null ? void 0 : O.id);
    const s = await v(m);
    return B(s);
  };
  return w(() => {
    const m = async () => {
      if (n === u.ORIGIN && (r != null && r.origin) || n === u.DESTINATION && (r != null && r.destination)) {
        const t = [];
        return r != null && r.origin && t.push({
          label: r == null ? void 0 : r.origin.name,
          value: r == null ? void 0 : r.origin
        }), r != null && r.destination && t.push({
          label: r == null ? void 0 : r.destination.name,
          value: r == null ? void 0 : r.destination
        }), g(t);
      }
      const s = await A();
      g(s);
    };
    T.length || m();
  }, [D, r]), {
    onSearch: G(
      async (m) => {
        var f, I, O, E;
        const s = $(), t = (/* @__PURE__ */ new Date()).getTime();
        try {
          const o = {
            searchString: m,
            isAtlas: R,
            searchV2: b
          };
          n === u.ORIGIN && ((f = r == null ? void 0 : r.destination) != null && f.id) && (o.locationId = (I = r == null ? void 0 : r.destination) == null ? void 0 : I.id), n === u.DESTINATION && ((O = r == null ? void 0 : r.origin) != null && O.id) && (o.locationId = (E = r == null ? void 0 : r.origin) == null ? void 0 : E.id);
          const i = await v(o);
          if (!(i != null && i.length)) {
            const P = (/* @__PURE__ */ new Date()).getTime() - t;
            return Object.assign(s, {
              message: `EMPTYRESULT ${m}`,
              duration: P,
              durationUnit: "milisseconds"
            }), g([]), a == null ? void 0 : a(s);
          }
          return g(B(i));
        } catch {
          const i = (/* @__PURE__ */ new Date()).getTime() - t;
          return Object.assign(s, {
            message: `ERROR ${m}`,
            duration: i,
            durationUnit: "milisseconds",
            level: 4
          }), g([]), a == null ? void 0 : a(s);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [d, n, r]
    ),
    options: T,
    renderOption: ({
      option: m
    }) => {
      var s;
      return /* @__PURE__ */ N.createElement(U.Option, { fullWidth: !0, label: m == null ? void 0 : m.label, supportText: (s = m == null ? void 0 : m.value) == null ? void 0 : s.name, onSelect: () => l(m.value, n), addonBefore: /* @__PURE__ */ N.createElement(j, null), style: {
        display: "flex"
      } });
    },
    onSelect: (m) => {
      l(m, n);
    }
  };
};
export {
  Z as default
};
