import u, { useState as O, useMemo as P } from "react";
import R from "@cvccorp-components/chui-react-components/components/Autocomplete";
import { getTicketsLocations as $ } from "@cvccorp-engines/api";
import { useError as k, Pesqinc as M } from "@cvccorp-engines/components";
import { getTicketsParamsForLogs as _ } from "../../utils/getTicketInitialValue.js";
import { LocationContainer as x } from "./styled.js";
const A = (d) => {
  const {
    error: p,
    value: o,
    onSelect: i,
    supportText: f,
    supportTextProps: T,
    onClear: r,
    label: b = "Destino",
    inputProps: v
  } = d, [m, g] = O([]);
  function c(e) {
    return e ? `${e.provider !== null ? `${e.provider} - ` : ""}${e != null && e.cityName ? e.cityName : ""} - ${e != null && e.stateName ? e.stateName : ""}` : "";
  }
  const {
    throwError: a
  } = k(), E = async (e) => {
    var y, N;
    const s = _(), l = {
      channel: "WEB",
      friendlyMessage: "EMPTYRESULT",
      _message: `EMPTYRESULT - ${e}`,
      operation: "location",
      productType: "services",
      packageGroup: "STANDALONE",
      provider: "LA",
      tags: [],
      _level_name: "info",
      ...s
    };
    try {
      const n = await $(e);
      if (((y = n == null ? void 0 : n.cities) == null ? void 0 : y.length) > 0) {
        const S = (N = n == null ? void 0 : n.cities) == null ? void 0 : N.map((t) => ({
          label: c({
            cityName: t.cityName,
            stateName: t.stateName,
            provider: t.name,
            cityId: t.cityId,
            countryName: t.countryName,
            description: t.description
          }),
          value: {
            cityName: t.cityName,
            stateName: t.stateName,
            provider: t.name,
            cityId: t.cityId,
            countryName: t.countryName,
            description: t.description
          }
        }));
        g(S);
      } else
        return Object.assign(l, {
          _message: `EMPTYRESULT ${e}`
        }), a == null || a(l), [];
    } catch {
      return Object.assign(l, {
        _message: `ERROR ${e}`
      }), a == null || a(l), [];
    }
  }, L = P(() => {
    const e = m;
    return o && e.push({
      value: o,
      label: c(o)
    }), e;
  }, [m, o]);
  return /* @__PURE__ */ u.createElement(x, null, /* @__PURE__ */ u.createElement(M, { label: p || b, supportText: f, supportTextProps: T, error: p, onSearch: E, onSelect: function(s) {
    if (!s) return r ? r == null ? void 0 : r() : i(o);
    i(s);
  }, inputProps: v, options: L, value: o, renderOption: ({
    option: e
  }) => /* @__PURE__ */ u.createElement(R.Option, { fullWidth: !0, supportText: c(e.value), label: c(e.value), onSelect: () => i(e.value) }) }));
};
A.displayName = "SingleTickets";
export {
  A as default
};
