import t from "@emotion/styled";
import { Grid as n } from "../EngineTicketsCollapsable/styled.js";
const a = t.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    gap: 12px;
    container-type: inline-size;
    /* padding: 30px 16px 40px; */
    align-items: flex-start;
    @container (min-width: 899px) {
        display: grid;
        grid-template-columns: ${({
  twoItems: i
}) => i ? "2fr 1fr 180px" : "1fr 1fr 180px"};
        gap: 12px;
    }
`, p = t.div`
    margin-left: ${({
  theme: i
}) => i.spacing.size[5]};
    container-type: inline-size;

    @container (max-width: 1024px) {
        margin: 0;
    }
`, o = t(n)`
    &&&:has(> *) {
        margin-bottom: 24px;
    }
`;
export {
  o as CancelButtonContainer,
  a as MotorContainer,
  p as buttonContainer
};
