import S, { createContext as M, useCallback as m, useMemo as y, useContext as A } from "react";
import { useIsomorphicLayoutEffect as E } from "@cvccorp-components/chui-react-components";
import { gtm as l, GTMProducts as N, GTMProductTower as f, redirectToSearch as v } from "@cvccorp-engines/components";
import { d as n } from "../dayjs-CxYy674R.js";
import w from "../hooks/useTicketSearchHistory/useTicketSearchHistory.js";
import { useTicketsStore as x } from "../store/TicketsStore.js";
import C from "../utils/formatUrlToSearch.js";
const T = M(void 0);
function I(o) {
  const {
    setErrors: a,
    setDestination: p,
    setDate: h,
    ...t
  } = x(), {
    searchHistory: s,
    addValue: c
  } = w();
  E(() => {
    if (s.length && !t.destination) {
      const e = s[0];
      p(e.destination), n(/* @__PURE__ */ new Date()).isAfter(e.date) || h(n(e.date).toString());
    }
  }, []);
  const d = m(() => {
    let e = !1;
    const r = !t.destination, i = !t.date;
    return e = r || i, [e, {
      destination: r,
      date: i
    }];
  }, [t.date, t.destination]), u = m(() => {
    const [e, r] = d();
    if (a(r), e)
      return;
    const i = C(t).replaceAll("+-+", " - ").replaceAll("+", " ");
    c({
      date: n(t.date).toString(),
      destination: t.destination
    }), l.onEngineSearch({
      product: N.TICKET,
      product_tower: t.destination.countryName === "BRASIL" ? f.NATIONAL : f.INTERNATIONAL,
      destination_city: t.destination.cityName,
      checkin_date: l.formatDate(t.date)
    }), v(i);
  }, [a, t, d, c]), k = y(() => ({
    handleSearch: u
  }), [u]);
  return /* @__PURE__ */ S.createElement(T.Provider, { ...o, value: k });
}
I.displayName = "MotorTicketsProvider";
function b() {
  const o = A(T);
  if (!o)
    throw new Error("useMotorTickets must be used within a MotorTicketsProvider");
  return o;
}
export {
  I as default,
  b as useMotorTicketsContext
};
