import { GTMProducts as c, GTMProductTower as r, gtm as e } from "@cvccorp-engines/components";
import { d as i } from "../dayjs-CxYy674R.js";
const a = (t) => (t == null ? void 0 : t.type) === "AIRPORT" ? t.description.split(",")[0] : "", m = (t) => {
  const n = i(t.calendarDates.startDate), o = i(t.calendarDates.endDate);
  return {
    product: c.PACKAGE,
    product_tower: [t.destination.country, t.origin.country].every((d) => d === "Brasil") ? r.NATIONAL : r.INTERNATIONAL,
    origin_city: t.origin.city,
    destination_city: t.destination.city,
    origin_airport_name: a(t.origin),
    destination_airport_name: a(t.destination),
    checkin_date: e.formatDate(t.calendarDates.startDate),
    checkout_date: e.formatDate(t.calendarDates.endDate),
    duration_days: o.diff(n, "days").toString()
  };
};
export {
  m as formatStateToGTM
};
