import { NAVIGATION_PRODUCTS as C, NAVIGATION_CONFIG as I, NAVIGATION_LINKS as T } from "../../../constants/products.js";
const S = {
  [C.AIR]: T[C.AIR],
  [C.HOTEL]: T[C.HOTEL],
  [C.PACKAGES]: T[C.PACKAGES],
  [C.PACKAGE_FULL]: T[C.PACKAGE_FULL],
  [C.CRU]: T[C.CRU],
  [C.CARS]: T[C.CARS],
  [C.CVC_ME_LEVA]: T[C.CVC_ME_LEVA],
  [C.CLUB]: T[C.CLUB],
  [C.DISNEY]: T[C.DISNEY],
  [C.APP]: T[C.APP],
  [C.CVC_PLUS]: T[C.CVC_PLUS],
  [C.TICKETS]: T[C.TICKETS],
  [C.TRAVEL_SECURITY]: T[C.TRAVEL_SECURITY],
  [C.TOURS]: T[C.TOURS],
  [C.PARTNERSHIPS]: T[C.PARTNERSHIPS],
  [C.TICKET_TRIP]: T[C.TICKET_TRIP],
  [C.BUSING]: T[C.BUSING],
  [C.BUSING_CHARTER]: T[C.BUSING_CHARTER]
}, R = [C.PACKAGES, C.AIR, C.HOTEL, C.CRU, C.CARS, C.TICKETS, C.BUSING, C.CVC_ME_LEVA, C.TRAVEL_SECURITY, C.DISNEY, C.TOURS, C.APP, C.TICKET_TRIP, C.PARTNERSHIPS, C.CVC_PLUS].map((A) => ({
  ...I.get(A),
  href: S[A]
})).filter(Boolean);
export {
  S as PRODUCTS_LINK_CVC,
  R as defaultProductsCVC
};
