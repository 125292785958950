import p, { createContext as E, useCallback as S, useEffect as T, useMemo as F } from "react";
import { useIsomorphicLayoutEffect as v } from "@cvccorp-components/chui-react-components";
import { gtm as O, redirectToSearch as M, DateFormat as h } from "@cvccorp-engines/components";
import { d as c } from "../dayjs-CxYy674R.js";
import _ from "../hooks/useFlightHistorySearch/useFlightHistorySearch.js";
import { useFlightStore as x } from "../store/FlightStore.js";
import { formatStateToGTM as y } from "../utils/formatStateToGTM.js";
import A from "../utils/formatUrlToSearch.js";
import I from "../utils/getInitialValues.js";
import N from "../utils/validateSearch.js";
const b = E(void 0), n = (r) => ({
  startDate: c(r.startDate).format(h.YEAR_MONTH_DATE_TIMEZONE),
  endDate: r.endDate ? c(r.endDate).format(h.YEAR_MONTH_DATE_TIMEZONE) : void 0
});
function C(r) {
  const {
    setErrors: d,
    setValues: o,
    ...t
  } = x(), {
    searchHistory: m,
    addValue: l
  } = _();
  v(() => {
    if (m.length && !t.destination) {
      const e = m[0], a = c(/* @__PURE__ */ new Date()).isAfter(e.calendarDates.startDate);
      o({
        ...e,
        calendarDates: a ? t.calendarDates : n(e.calendarDates),
        multidestinations: e.multidestinations ? Object.entries(e.multidestinations).reduce((i, [g, s]) => {
          var D;
          return i[g] = {
            ...s,
            calendarDates: (D = s.calendarDates) != null && D.startDate && !a ? n(s.calendarDates) : void 0
          }, i;
        }, {}) : void 0
      });
    }
  }, []);
  const f = S(() => {
    const [e, a] = N(t);
    if (d({
      ...a
    }), e) return;
    const i = A(t);
    l({
      backToFirstOrigin: t.backToFirstOrigin,
      calendarDates: n(t.calendarDates),
      choice: t.choice,
      classe: t.classe,
      destination: t.destination,
      multidestinations: t.multidestinations,
      origin: t.origin,
      pax: t.pax
    }), O.onEngineSearch(y(t)), M(i);
  }, [t, d, l]);
  T(() => {
    const e = I();
    o(e);
  }, [o]);
  const u = F(() => ({
    handleSearch: f
  }), [f]);
  return /* @__PURE__ */ p.createElement(b.Provider, { ...r, value: u });
}
C.displayName = "EngineFlightProvider";
export {
  b as EngineFlightContext,
  C as default
};
