import { determineColor as m } from "@cvccorp-engines/components";
import { d as r } from "../../../../dayjs-CxYy674R.js";
function p(s) {
  if (!s.length) return [];
  const i = s.sort((t, o) => r(t.date).valueOf() - r(o.date).valueOf()), f = r(i[0].date).startOf("month").add(2, "months"), n = [];
  s.forEach((t) => {
    r(t.date).isSameOrBefore(f) && n.push(t);
  });
  const a = n.reduce((t, {
    bestPriceWithTax: o
  }) => {
    const e = Math.ceil(o);
    return e < t ? e : t;
  }, 1 / 0);
  return n.map(({
    date: t,
    bestPriceWithTax: o,
    promotional: e
  }) => ({
    date: t,
    subtitle: new Intl.NumberFormat("pt-br").format(Math.ceil(o)),
    subtitleProps: {
      style: {
        fontWeight: 700,
        color: m(e, o <= a)
      }
    }
  }));
}
export {
  p as default
};
