import e, { useState as m } from "react";
import { PaxBusing as i, DEFAULT_BUSING as s } from "@cvccorp-engines/pax";
import { useEngineBusingContext as u } from "../../context/EngineBusingContext.js";
import p from "../Calendar/Calendar.js";
import f from "../Pesqinc/Pesqinc.js";
import { EngineContainer as c } from "./DefaultLocationForm.styles.js";
const x = () => {
  const {
    paxValue: n,
    setPaxValue: o,
    errors: r
  } = u(), [t, a] = m(!1);
  return /* @__PURE__ */ e.createElement(c, null, /* @__PURE__ */ e.createElement(f, null), /* @__PURE__ */ e.createElement(p, null), /* @__PURE__ */ e.createElement(i, { open: t, value: n, error: r.pax, onToggle: () => a(!t), onClear: () => o(s), onConfirm: (l) => {
    o(l), a(!1);
  } }));
};
x.displayName = "DefaultLocationForm";
export {
  x as default
};
