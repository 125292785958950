import { Grid as p } from "@cvccorp-components/chui-react-components";
import r from "@emotion/styled";
const m = r(p)`
    gap: 32px;

    @media (max-width: 600px) {
        gap: 12px;
    }
`;
export {
  m as Grid
};
