function s(e, n) {
  const {
    allowEmpty: a = !0
  } = n || {}, t = new URLSearchParams();
  return Object.entries(e).forEach(([c, r]) => {
    try {
      if (!r && !a) return;
      t.append(c, r ? r == null ? void 0 : r.trim() : "");
    } catch {
      return "";
    }
  }), t;
}
export {
  s as default
};
