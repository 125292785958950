import { GTMProducts as n, GTMProductTower as e, gtm as r } from "@cvccorp-engines/components";
import { d as i } from "../dayjs-CxYy674R.js";
const s = (t) => {
  const o = i(t.calendarDates.startDate), a = i(t.calendarDates.endDate);
  return {
    hotel_name: t.destination.type === "HOTEL" ? t.destination.description.split("-")[0].trim() : "",
    destination_city: t.destination.city,
    product: n.HOTEL,
    product_tower: t.destination.country === "Brasil" ? e.NATIONAL : e.INTERNATIONAL,
    checkin_date: r.formatDate(o.toDate()),
    checkout_date: r.formatDate(a.toDate()),
    duration_days: a.diff(o, "days").toString()
  };
};
export {
  s as formatStateToGTM
};
