import i from "react";
import { OptionsBar as o } from "@cvccorp-engines/components";
import { u as a } from "../../EngineBookingPackagesContext-D4tagYeS.js";
const r = [{
  type: "originAndDestination",
  label: "Origem x destino",
  value: "originAndDestination"
}, {
  type: "origin",
  label: "Origem",
  value: "origin"
}, {
  type: "destination",
  label: "Destino",
  value: "destination"
}], s = () => {
  const {
    searchType: t,
    setSearchType: n
  } = a();
  return /* @__PURE__ */ i.createElement(o, { variant: "button", component: "radio", options: r, value: t, onChange: (e) => n(e), isChecked: (e) => e.type === t });
};
s.displayName = "SearchOptions";
export {
  s as default
};
