"use client";
import a, { useRef as b } from "react";
import O from "@cvccorp-components/chui-react-components/components/InputDatePickerRange";
import { d as W } from "../../dayjs-CxYy674R.js";
import z from "../InputLoadingOverlay/InputLoadingOverlay.js";
import { Wrapper as E } from "./InputDatePickerRange.styles.js";
const I = (i) => {
  const {
    value: s,
    min: p,
    max: l,
    options: u,
    inputGroupProps: d,
    inputOneProps: m,
    inputTwoProps: c,
    inputRefs: f,
    content: P,
    isLoading: D = !1,
    error: e = {},
    showRangeCount: g = !0,
    datePickerProps: h,
    onClear: C,
    onFocus: R,
    onChange: r,
    onChangeCalendar: y,
    disableDates: k = () => !1,
    openCalendar: v,
    onToggle: w
  } = i, n = p ?? /* @__PURE__ */ new Date(), x = l ?? W(n).add(1, "year").toDate(), T = b(null);
  function o(t) {
    r == null || r(t);
  }
  return /* @__PURE__ */ a.createElement(E, { ref: T }, /* @__PURE__ */ a.createElement(O, { inputGroupProps: {
    fullWidth: !0,
    ...d
  }, inputOneProps: {
    fullWidth: !0,
    label: "Início",
    placeholder: "",
    supportText: e.startDate,
    status: e.startDate ? "danger" : void 0,
    size: "md",
    ...m
  }, inputTwoProps: {
    label: "Fim",
    placeholder: "",
    supportText: e.endDate,
    status: e.endDate ? "danger" : void 0,
    fullWidth: !0,
    size: "md",
    ...c
  }, datePickerProps: {
    options: u,
    onFocus: R,
    maxDate: x,
    minDate: n,
    disable: k,
    size: "sm",
    ...h
  }, inputRefs: f, value: s, showRangeCount: g, onConfirm: o, onClear: C, onChangeCalendar: y, content: P, openCalendar: v, onToggle: w, onChange: (t) => {
    Array.isArray(t) && o(t);
  } }), D ? /* @__PURE__ */ a.createElement(z, null) : void 0);
};
I.displayName = "DatePickerRange";
export {
  I as default
};
