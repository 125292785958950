import o, { useMemo as t } from "react";
import { InputGroup as a } from "@cvccorp-engines/components";
import { useEngineBusingContext as s } from "../../context/EngineBusingContext.js";
import { EPesqincSearchType as i } from "./Pesqinc.types.js";
import n from "./SingleBusing/SingleBusing.js";
const u = {
  ORIGIN: "Preencha o campo de origem",
  DESTINATION: "Preencha o campo de destino"
}, m = () => {
  const {
    errors: e
  } = s(), r = t(() => e.sameLocation ? {
    origin: "Origem e Destino não podem ser iguais",
    destination: "Origem e Destino não podem ser iguais"
  } : {
    origin: e.origin ? "Preencha o campo de origem" : void 0,
    destination: e.destination ? "Preencha o campo de destino" : void 0
  }, [e]);
  return /* @__PURE__ */ o.createElement(a, { status: e.origin || e.destination || e.sameLocation ? "danger" : void 0 }, /* @__PURE__ */ o.createElement(n, { searchType: i.ORIGIN, label: "Origem", placeholder: "De onde vai sair?", error: r.origin }), /* @__PURE__ */ o.createElement(n, { searchType: i.DESTINATION, label: "Destino", placeholder: "Para onde vai?", error: r.destination }));
};
m.displayName = "PesqincFull";
export {
  m as default,
  u as errorByType
};
