var U = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function Q(w) {
  return w && w.__esModule && Object.prototype.hasOwnProperty.call(w, "default") ? w.default : w;
}
var X = { exports: {} };
(function(w, q) {
  (function(k, m) {
    w.exports = m();
  })(U, function() {
    var k = 1e3, m = 6e4, h = 36e5, v = "millisecond", _ = "second", y = "minute", D = "hour", b = "day", A = "week", u = "month", o = "quarter", l = "year", c = "date", C = "Invalid Date", F = /^(\d{4})[-/]?(\d{1,2})?[-/]?(\d{0,2})[Tt\s]*(\d{1,2})?:?(\d{1,2})?:?(\d{1,2})?[.:]?(\d+)?$/, j = /\[([^\]]+)]|Y{1,4}|M{1,4}|D{1,2}|d{1,4}|H{1,2}|h{1,2}|a|A|m{1,2}|s{1,2}|Z{1,2}|SSS/g, P = {
      name: "en",
      weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"),
      months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"),
      ordinal: function(t) {
        var e = ["th", "st", "nd", "rd"], r = t % 100;
        return "[" + t + (e[(r - 20) % 10] || e[r] || e[0]) + "]";
      }
    }, z = function(t, e, r) {
      var n = String(t);
      return !n || n.length >= e ? t : "" + Array(e + 1 - n.length).join(r) + t;
    }, B = {
      s: z,
      z: function(t) {
        var e = -t.utcOffset(), r = Math.abs(e), n = Math.floor(r / 60), s = r % 60;
        return (e <= 0 ? "+" : "-") + z(n, 2, "0") + ":" + z(s, 2, "0");
      },
      m: function f(t, e) {
        if (t.date() < e.date()) return -f(e, t);
        var r = 12 * (e.year() - t.year()) + (e.month() - t.month()), n = t.clone().add(r, u), s = e - n < 0, i = t.clone().add(r + (s ? -1 : 1), u);
        return +(-(r + (e - n) / (s ? n - i : i - n)) || 0);
      },
      a: function(t) {
        return t < 0 ? Math.ceil(t) || 0 : Math.floor(t);
      },
      p: function(t) {
        return {
          M: u,
          y: l,
          w: A,
          d: b,
          D: c,
          h: D,
          m: y,
          s: _,
          ms: v,
          Q: o
        }[t] || String(t || "").toLowerCase().replace(/s$/, "");
      },
      u: function(t) {
        return t === void 0;
      }
    }, T = "en", x = {};
    x[T] = P;
    var E = "$isDayjsObject", G = function(t) {
      return t instanceof J || !(!t || !t[E]);
    }, N = function f(t, e, r) {
      var n;
      if (!t) return T;
      if (typeof t == "string") {
        var s = t.toLowerCase();
        x[s] && (n = s), e && (x[s] = e, n = s);
        var i = t.split("-");
        if (!n && i.length > 1) return f(i[0]);
      } else {
        var d = t.name;
        x[d] = t, n = d;
      }
      return !r && n && (T = n), n || !r && T;
    }, M = function(t, e) {
      if (G(t)) return t.clone();
      var r = typeof e == "object" ? e : {};
      return r.date = t, r.args = arguments, new J(r);
    }, a = B;
    a.l = N, a.i = G, a.w = function(f, t) {
      return M(f, {
        locale: t.$L,
        utc: t.$u,
        x: t.$x,
        $offset: t.$offset
      });
    };
    var J = function() {
      function f(e) {
        this.$L = N(e.locale, null, !0), this.parse(e), this.$x = this.$x || e.x || {}, this[E] = !0;
      }
      var t = f.prototype;
      return t.parse = function(e) {
        this.$d = function(r) {
          var n = r.date, s = r.utc;
          if (n === null) return /* @__PURE__ */ new Date(NaN);
          if (a.u(n)) return /* @__PURE__ */ new Date();
          if (n instanceof Date) return new Date(n);
          if (typeof n == "string" && !/Z$/i.test(n)) {
            var i = n.match(F);
            if (i) {
              var d = i[2] - 1 || 0, $ = (i[7] || "0").substring(0, 3);
              return s ? new Date(Date.UTC(i[1], d, i[3] || 1, i[4] || 0, i[5] || 0, i[6] || 0, $)) : new Date(i[1], d, i[3] || 1, i[4] || 0, i[5] || 0, i[6] || 0, $);
            }
          }
          return new Date(n);
        }(e), this.init();
      }, t.init = function() {
        var e = this.$d;
        this.$y = e.getFullYear(), this.$M = e.getMonth(), this.$D = e.getDate(), this.$W = e.getDay(), this.$H = e.getHours(), this.$m = e.getMinutes(), this.$s = e.getSeconds(), this.$ms = e.getMilliseconds();
      }, t.$utils = function() {
        return a;
      }, t.isValid = function() {
        return this.$d.toString() !== C;
      }, t.isSame = function(e, r) {
        var n = M(e);
        return this.startOf(r) <= n && n <= this.endOf(r);
      }, t.isAfter = function(e, r) {
        return M(e) < this.startOf(r);
      }, t.isBefore = function(e, r) {
        return this.endOf(r) < M(e);
      }, t.$g = function(e, r, n) {
        return a.u(e) ? this[r] : this.set(n, e);
      }, t.unix = function() {
        return Math.floor(this.valueOf() / 1e3);
      }, t.valueOf = function() {
        return this.$d.getTime();
      }, t.startOf = function(e, r) {
        var n = this, s = !!a.u(r) || r, i = a.p(e), d = function(L, g) {
          var H = a.w(n.$u ? Date.UTC(n.$y, g, L) : new Date(n.$y, g, L), n);
          return s ? H : H.endOf(b);
        }, $ = function(L, g) {
          return a.w(n.toDate()[L].apply(n.toDate("s"), (s ? [0, 0, 0, 0] : [23, 59, 59, 999]).slice(g)), n);
        }, p = this.$W, S = this.$M, O = this.$D, Y = "set" + (this.$u ? "UTC" : "");
        switch (i) {
          case l:
            return s ? d(1, 0) : d(31, 11);
          case u:
            return s ? d(1, S) : d(0, S + 1);
          case A:
            var W = this.$locale().weekStart || 0, I = (p < W ? p + 7 : p) - W;
            return d(s ? O - I : O + (6 - I), S);
          case b:
          case c:
            return $(Y + "Hours", 0);
          case D:
            return $(Y + "Minutes", 1);
          case y:
            return $(Y + "Seconds", 2);
          case _:
            return $(Y + "Milliseconds", 3);
          default:
            return this.clone();
        }
      }, t.endOf = function(e) {
        return this.startOf(e, !1);
      }, t.$set = function(e, r) {
        var n, s = a.p(e), i = "set" + (this.$u ? "UTC" : ""), d = (n = {}, n[b] = i + "Date", n[c] = i + "Date", n[u] = i + "Month", n[l] = i + "FullYear", n[D] = i + "Hours", n[y] = i + "Minutes", n[_] = i + "Seconds", n[v] = i + "Milliseconds", n)[s], $ = s === b ? this.$D + (r - this.$W) : r;
        if (s === u || s === l) {
          var p = this.clone().set(c, 1);
          p.$d[d]($), p.init(), this.$d = p.set(c, Math.min(this.$D, p.daysInMonth())).$d;
        } else d && this.$d[d]($);
        return this.init(), this;
      }, t.set = function(e, r) {
        return this.clone().$set(e, r);
      }, t.get = function(e) {
        return this[a.p(e)]();
      }, t.add = function(e, r) {
        var n, s = this;
        e = Number(e);
        var i = a.p(r), d = function(O) {
          var Y = M(s);
          return a.w(Y.date(Y.date() + Math.round(O * e)), s);
        };
        if (i === u) return this.set(u, this.$M + e);
        if (i === l) return this.set(l, this.$y + e);
        if (i === b) return d(1);
        if (i === A) return d(7);
        var $ = (n = {}, n[y] = m, n[D] = h, n[_] = k, n)[i] || 1, p = this.$d.getTime() + e * $;
        return a.w(p, this);
      }, t.subtract = function(e, r) {
        return this.add(-1 * e, r);
      }, t.format = function(e) {
        var r = this, n = this.$locale();
        if (!this.isValid()) return n.invalidDate || C;
        var s = e || "YYYY-MM-DDTHH:mm:ssZ", i = a.z(this), d = this.$H, $ = this.$m, p = this.$M, S = n.weekdays, O = n.months, Y = n.meridiem, W = function(g, H, V, rt) {
          return g && (g[H] || g(r, s)) || V[H].slice(0, rt);
        }, I = function(g) {
          return a.s(d % 12 || 12, g, "0");
        }, Z = Y || function(L, g, H) {
          var V = L < 12 ? "AM" : "PM";
          return H ? V.toLowerCase() : V;
        };
        return s.replace(j, function(L, g) {
          return g || function(H) {
            switch (H) {
              case "YY":
                return String(r.$y).slice(-2);
              case "YYYY":
                return a.s(r.$y, 4, "0");
              case "M":
                return p + 1;
              case "MM":
                return a.s(p + 1, 2, "0");
              case "MMM":
                return W(n.monthsShort, p, O, 3);
              case "MMMM":
                return W(O, p);
              case "D":
                return r.$D;
              case "DD":
                return a.s(r.$D, 2, "0");
              case "d":
                return String(r.$W);
              case "dd":
                return W(n.weekdaysMin, r.$W, S, 2);
              case "ddd":
                return W(n.weekdaysShort, r.$W, S, 3);
              case "dddd":
                return S[r.$W];
              case "H":
                return String(d);
              case "HH":
                return a.s(d, 2, "0");
              case "h":
                return I(1);
              case "hh":
                return I(2);
              case "a":
                return Z(d, $, !0);
              case "A":
                return Z(d, $, !1);
              case "m":
                return String($);
              case "mm":
                return a.s($, 2, "0");
              case "s":
                return String(r.$s);
              case "ss":
                return a.s(r.$s, 2, "0");
              case "SSS":
                return a.s(r.$ms, 3, "0");
              case "Z":
                return i;
            }
            return null;
          }(L) || i.replace(":", "");
        });
      }, t.utcOffset = function() {
        return 15 * -Math.round(this.$d.getTimezoneOffset() / 15);
      }, t.diff = function(e, r, n) {
        var s, i = this, d = a.p(r), $ = M(e), p = ($.utcOffset() - this.utcOffset()) * m, S = this - $, O = function() {
          return a.m(i, $);
        };
        switch (d) {
          case l:
            s = O() / 12;
            break;
          case u:
            s = O();
            break;
          case o:
            s = O() / 3;
            break;
          case A:
            s = (S - p) / 6048e5;
            break;
          case b:
            s = (S - p) / 864e5;
            break;
          case D:
            s = S / h;
            break;
          case y:
            s = S / m;
            break;
          case _:
            s = S / k;
            break;
          default:
            s = S;
        }
        return n ? s : a.a(s);
      }, t.daysInMonth = function() {
        return this.endOf(u).$D;
      }, t.$locale = function() {
        return x[this.$L];
      }, t.locale = function(e, r) {
        if (!e) return this.$L;
        var n = this.clone(), s = N(e, r, !0);
        return s && (n.$L = s), n;
      }, t.clone = function() {
        return a.w(this.$d, this);
      }, t.toDate = function() {
        return new Date(this.valueOf());
      }, t.toJSON = function() {
        return this.isValid() ? this.toISOString() : null;
      }, t.toISOString = function() {
        return this.$d.toISOString();
      }, t.toString = function() {
        return this.$d.toUTCString();
      }, f;
    }(), R = J.prototype;
    return M.prototype = R, [["$ms", v], ["$s", _], ["$m", y], ["$H", D], ["$W", b], ["$M", u], ["$y", l], ["$D", c]].forEach(function(f) {
      R[f[1]] = function(t) {
        return this.$g(t, f[0], f[1]);
      };
    }), M.extend = function(f, t) {
      return f.$i || (f(t, J, M), f.$i = !0), M;
    }, M.locale = N, M.isDayjs = G, M.unix = function(f) {
      return M(1e3 * f);
    }, M.en = x[T], M.Ls = x, M.p = {}, M;
  });
})(X);
var tt = X.exports;
const K = /* @__PURE__ */ Q(tt);
var st = { exports: {} };
(function(w, q) {
  (function(k, m) {
    w.exports = m();
  })(U, function() {
    return function(k, m) {
      m.prototype.isSameOrAfter = function(h, v) {
        return this.isSame(h, v) || this.isAfter(h, v);
      };
    };
  });
})(st);
var et = { exports: {} };
(function(w, q) {
  (function(k, m) {
    w.exports = m();
  })(U, function() {
    return function(k, m) {
      m.prototype.isSameOrBefore = function(h, v) {
        return this.isSame(h, v) || this.isBefore(h, v);
      };
    };
  });
})(et);
var it = et.exports;
const ot = /* @__PURE__ */ Q(it);
var nt = { exports: {} };
(function(w, q) {
  (function(k, m) {
    w.exports = m();
  })(U, function() {
    return function(k, m, h) {
      var v = m.prototype, _ = function(o) {
        return o && (o.indexOf ? o : o.s);
      }, y = function(o, l, c, C, F) {
        var j = o.name ? o : o.$locale(), P = _(j[l]), z = _(j[c]), B = P || z.map(function(x) {
          return x.slice(0, C);
        });
        if (!F) return B;
        var T = j.weekStart;
        return B.map(function(x, E) {
          return B[(E + (T || 0)) % 7];
        });
      }, D = function() {
        return h.Ls[h.locale()];
      }, b = function(o, l) {
        return o.formats[l] || function(c) {
          return c.replace(/(\[[^\]]+])|(MMMM|MM|DD|dddd)/g, function(C, F, j) {
            return F || j.slice(1);
          });
        }(o.formats[l.toUpperCase()]);
      }, A = function() {
        var o = this;
        return {
          months: function(c) {
            return c ? c.format("MMMM") : y(o, "months");
          },
          monthsShort: function(c) {
            return c ? c.format("MMM") : y(o, "monthsShort", "months", 3);
          },
          firstDayOfWeek: function() {
            return o.$locale().weekStart || 0;
          },
          weekdays: function(c) {
            return c ? c.format("dddd") : y(o, "weekdays");
          },
          weekdaysMin: function(c) {
            return c ? c.format("dd") : y(o, "weekdaysMin", "weekdays", 2);
          },
          weekdaysShort: function(c) {
            return c ? c.format("ddd") : y(o, "weekdaysShort", "weekdays", 3);
          },
          longDateFormat: function(c) {
            return b(o.$locale(), c);
          },
          meridiem: this.$locale().meridiem,
          ordinal: this.$locale().ordinal
        };
      };
      v.localeData = function() {
        return A.bind(this)();
      }, h.localeData = function() {
        var u = D();
        return {
          firstDayOfWeek: function() {
            return u.weekStart || 0;
          },
          weekdays: function() {
            return h.weekdays();
          },
          weekdaysShort: function() {
            return h.weekdaysShort();
          },
          weekdaysMin: function() {
            return h.weekdaysMin();
          },
          months: function() {
            return h.months();
          },
          monthsShort: function() {
            return h.monthsShort();
          },
          longDateFormat: function(l) {
            return b(u, l);
          },
          meridiem: u.meridiem,
          ordinal: u.ordinal
        };
      }, h.months = function() {
        return y(D(), "months");
      }, h.monthsShort = function() {
        return y(D(), "monthsShort", "months", 3);
      }, h.weekdays = function(u) {
        return y(D(), "weekdays", null, null, u);
      }, h.weekdaysShort = function(u) {
        return y(D(), "weekdaysShort", "weekdays", 3, u);
      }, h.weekdaysMin = function(u) {
        return y(D(), "weekdaysMin", "weekdays", 2, u);
      };
    };
  });
})(nt);
var at = nt.exports;
const ut = /* @__PURE__ */ Q(at);
var ct = { exports: {} };
(function(w, q) {
  (function(k, m) {
    w.exports = m(tt);
  })(U, function(k) {
    function m(_) {
      return _ && typeof _ == "object" && "default" in _ ? _ : {
        default: _
      };
    }
    var h = m(k), v = {
      name: "pt-br",
      weekdays: "domingo_segunda-feira_terça-feira_quarta-feira_quinta-feira_sexta-feira_sábado".split("_"),
      weekdaysShort: "dom_seg_ter_qua_qui_sex_sáb".split("_"),
      weekdaysMin: "Do_2ª_3ª_4ª_5ª_6ª_Sá".split("_"),
      months: "janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro".split("_"),
      monthsShort: "jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez".split("_"),
      ordinal: function(y) {
        return y + "º";
      },
      formats: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD/MM/YYYY",
        LL: "D [de] MMMM [de] YYYY",
        LLL: "D [de] MMMM [de] YYYY [às] HH:mm",
        LLLL: "dddd, D [de] MMMM [de] YYYY [às] HH:mm"
      },
      relativeTime: {
        future: "em %s",
        past: "há %s",
        s: "poucos segundos",
        m: "um minuto",
        mm: "%d minutos",
        h: "uma hora",
        hh: "%d horas",
        d: "um dia",
        dd: "%d dias",
        M: "um mês",
        MM: "%d meses",
        y: "um ano",
        yy: "%d anos"
      }
    };
    return h.default.locale(v, null, !0), v;
  });
})(ct);
K.locale("pt-br");
K.extend(ot);
K.extend(ut);
export {
  K as d
};
