const g = ({
  level: o = 6,
  message: e = "",
  operation: a = "location",
  ...c
} = {}) => ({
  _app_name: "cvccorp-engines-booking-packages",
  productType: "bookingPackages",
  productGroupType: "PACKAGE",
  _message: e,
  level: o,
  operation: a,
  ...c
});
export {
  g as getBookingPackagesParamsForLogs
};
