import r, { useRef as L, useEffect as F, useMemo as x, Fragment as I } from "react";
import { useResponsive as M } from "@cvccorp-components/chui-react-components";
import { useAutoFocus as T, getCalendarDatesAsDate as W, InputGroup as j, InputDatePickerRange as q } from "@cvccorp-engines/components";
import { d } from "../../dayjs-CxYy674R.js";
import { PaxPackages as B } from "@cvccorp-engines/pax";
import { useHotelStore as G } from "../../store/HotelStore/HotelStore.js";
import H from "../Pesqinc/Pesqinc.js";
import { LocationAndDateWrapper as z } from "./LocationAndDate.styles.js";
const K = d().add(1, "d").toDate(), Q = d(/* @__PURE__ */ new Date()).add(1.5, "year").toDate();
function U() {
  const {
    pax: R,
    openPax: E,
    destination: C,
    calendarDates: l,
    errors: e,
    setPax: v,
    clearPax: A,
    setOpenPax: D,
    clearDates: k,
    setCalendarDates: S,
    setDestination: u
  } = G(), {
    register: m,
    focusField: O
  } = T(["datePickerRange"]), {
    isMobile: f
  } = M(), p = L(null);
  F(() => {
    var t;
    m("datePickerRange", {
      el: (t = p.current) == null ? void 0 : t.inputOneRef,
      action: f ? "focus" : "click"
    });
  }, [m, f]);
  const n = x(() => W(l) || [], [l]), y = (t) => {
    if (!t) return;
    const [a, o] = t;
    S({
      startDate: a == null ? void 0 : a.toJSON(),
      endDate: o == null ? void 0 : o.toJSON()
    });
  }, N = x(() => {
    var t, a;
    return {
      endDate: (t = n[1]) == null ? void 0 : t.toJSON(),
      startDate: (a = n[0]) == null ? void 0 : a.toJSON()
    };
  }, [n]), b = () => {
    var a, o, s, c, g, h;
    const t = {
      startDate: "",
      endDate: ""
    };
    return (a = e == null ? void 0 : e.calendarDates) != null && a.startDate && (t.startDate = "Preencha a data de ida"), (o = e == null ? void 0 : e.calendarDates) != null && o.endDate && (t.endDate = "Preencha a data de volta"), (s = e == null ? void 0 : e.calendarDates) != null && s.startDate && ((c = e == null ? void 0 : e.calendarDates) != null && c.endDate) && (t.startDate = "Preencha a data de ida", t.endDate = "Preencha a data de volta"), !((g = e == null ? void 0 : e.calendarDates) != null && g.startDate) && !((h = e == null ? void 0 : e.calendarDates) != null && h.endDate) && (t.startDate = "", t.endDate = ""), t;
  }, w = (t) => {
    if ((n == null ? void 0 : n.length) === 2) return !1;
    if (n[0]) {
      const a = d(n[0]), o = d(t), s = a.add(29, "day"), c = a.subtract(29, "day");
      return o.isAfter(s) || o.isBefore(c);
    }
    return !1;
  }, J = () => e != null && e.destination ? "Preencha o campo de destino" : "", i = b(), P = J();
  return /* @__PURE__ */ r.createElement(I, null, /* @__PURE__ */ r.createElement(H, { supportText: P, error: P, value: C, onSelect: (t) => {
    u(t), O("datePickerRange");
  }, onClear: () => u(void 0) }), /* @__PURE__ */ r.createElement(z, null, /* @__PURE__ */ r.createElement(j, { fullWidth: !0, status: i.startDate || i.endDate ? "danger" : void 0 }, /* @__PURE__ */ r.createElement(q, { value: n, disableDates: w, max: Q, min: K, showRangeCount: !0, error: i, onChange: y, onClear: k, inputRefs: p })), /* @__PURE__ */ r.createElement(B, { open: E, value: R, error: e.pax, calendarDates: N, onConfirm: (t) => {
    v(t), D(!1);
  }, onClear: A, onToggle: D })));
}
U.displayName = "LocationAndDate";
export {
  U as default
};
