import C, { createContext as F, useCallback as h, useMemo as M, useContext as v } from "react";
import { useIsomorphicLayoutEffect as T } from "@cvccorp-components/chui-react-components";
import { DateFormat as y, gtm as B, redirectToSearch as w } from "@cvccorp-engines/components";
import { d as D } from "../../dayjs-CxYy674R.js";
import A from "../../hooks/usePackageSearchFullHistory/usePackageSearchFullHistory.js";
import { usePackageFullStoreStore as H } from "../../store/EnginePackageFullStore/EnginePackageFullStore.js";
import { formatStateToGTM as N } from "../../utils/packageFullFormatStateToGTM.js";
import O from "../../utils/packageFullFormatUrlToSearch.js";
import P from "../../utils/packageFullValidateChildrenPaxDate.js";
const k = F(void 0);
function R(o) {
  const {
    setErrors: c,
    setErrorsPax: l,
    setCalendarDates: d,
    setDestination: m,
    setPax: u,
    setOrigin: f,
    ...e
  } = H(), {
    searchHistory: i,
    addValue: p
  } = A();
  T(() => {
    if (!e.destination && i.length) {
      const t = i[0];
      m(t.destination), f(t.origin), u(t.pax), D(/* @__PURE__ */ new Date()).isAfter(t.calendarDates) || d(D(t.calendarDates).format(y.YEAR_MONTH_DATE_TIMEZONE));
    }
  }, [i, e.destination, f, m, u, d]);
  const x = h(() => {
    let t = !1;
    const r = !e.pax || P(e.pax)[0], n = !e.destination, a = !e.origin, s = !e.calendarDates;
    return t = n || a || s || r, [t, {
      destination: n,
      origin: a,
      pax: r,
      calendarDates: {
        startDate: s,
        endDate: !1
      }
    }];
  }, [e.calendarDates, e.destination, e.origin, e.pax]), g = h(() => {
    const t = e.calendarDates, [r, n] = x(), [a, s] = P(e.pax, t);
    if (c({
      ...n,
      pax: a
    }), l(s), r || a)
      return;
    const S = O(e);
    p({
      calendarDates: e.calendarDates,
      destination: e.destination,
      origin: e.origin,
      pax: e.pax
    }), B.onEngineSearch(N(e)), w(S);
  }, [e, c, l, x, p]), E = M(() => ({
    handleSearch: g
  }), [g]);
  return /* @__PURE__ */ C.createElement(k.Provider, { ...o, value: E });
}
R.displayName = "MotorPackageFullProvider";
function Y() {
  const o = v(k);
  if (!o)
    throw new Error("Chamada do hook useMotorPackageFullContext inválida! Certifique-se que ele está sendo chamado dentro do contexto MotorPackageFullContext");
  return o;
}
export {
  R as default,
  Y as useMotorPackageFullContext
};
