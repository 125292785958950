import s, { createContext as j, useState as _, useMemo as B, useContext as Y, useRef as g, useCallback as q, useEffect as x } from "react";
import J from "@cvccorp-components/chui-react-icons/icons/outline/system/OutlineArrowLeft";
import Q from "@cvccorp-components/chui-react-icons/icons/outline/system/OutlineArrowRight";
import { useTheme as X } from "@emotion/react";
import { NAVIGATION_PRODUCTS as c, NAVIGATION_CONFIG as Z, NAVIGATION_LINKS as ee } from "./constants/products.js";
import { useWindow as te, useIsomorphicLayoutEffect as y } from "@cvccorp-components/chui-react-components";
import * as ne from "@cvccorp-components/chui-react-icons/icons";
import { Container as re, Nav as oe, Button as K, Hidden as V, NavigationButton as ce, NavigationLink as se, ProductWrapper as le } from "./components/Navigation/Navigation.styles.js";
import { PRODUCTS_LINK_CVC as ae, defaultProductsCVC as ie } from "./components/Navigation/products/cvc.js";
import { defaultProductsSubmarino as ue } from "./components/Navigation/products/submarino.js";
import fe from "./components/InlineSVG/InlineSVG.js";
const z = j({}), $ = (o) => o === "submarino" ? c.AIR : c.AIR, D = (o) => {
  if (!o) return null;
  if (typeof o != "string") return o;
  const a = ne[o];
  return a || (() => /* @__PURE__ */ s.createElement(fe, { src: o }));
}, de = ({
  children: o,
  product: a,
  navigationItems: E,
  navigationType: N = "link"
}) => {
  const {
    name: d
  } = X(), h = te(), [R, p] = _(""), [v, A] = _(a || $(d));
  y(() => {
    const i = h.location.pathname;
    p(i);
  }, [h]), y(() => {
    const {
      product: i
    } = k(a);
    A(i);
  }, [N, a]);
  const I = (i) => i.id === "busing" ? "Ônibus" : i.label, C = B(() => {
    const m = d === "cvc" ? ie : ue;
    return (E != null && E.length ? E : m).map((r) => {
      const f = Z.get(r.id);
      return f ? {
        href: r.href ?? ee[r.id],
        icon: D(r.icon ?? f.icon),
        id: f.id,
        label: r.label ? I(r) : f.label,
        headerTitleMessages: r == null ? void 0 : r.headerTitleMessages
      } : {
        ...r,
        label: I(r),
        icon: D(r.icon)
      };
    }).filter((r) => r.href && r.label);
  }, [d, E]);
  function k(i) {
    const m = i || $(d);
    let b = !1, r = m;
    if (N === "link") {
      const f = new URLSearchParams(h.location.search).get("context"), {
        pathname: P
      } = h.location;
      p(P);
      const L = P.startsWith("/p/") ? P.replace(/^\/p/, "") : P, l = C.find((G) => L === G.href);
      if (f === c.RESORTS && (l == null ? void 0 : l.id) === c.HOTEL)
        return {
          checked: !0,
          product: c.RESORTS
        };
      if (L.includes(ae[c.PACKAGE_FULL])) return {
        checked: !0,
        product: c.PACKAGE_COMPLETE
      };
      if ([c.CRU, c.DISNEY].includes(l == null ? void 0 : l.id))
        return {
          checked: !0,
          product: m
        };
      r = (l == null ? void 0 : l.id) ?? m, b = !!l;
    }
    return {
      product: r,
      checked: b
    };
  }
  const w = B(() => ({
    pathname: R,
    currentProduct: v,
    products: C,
    setCurrentProduct: A,
    navigationType: N
  }), [R, v, C, A, N]);
  return /* @__PURE__ */ s.createElement(z.Provider, { value: w }, o);
};
de.displayName = "EngineNavigationProvider";
const he = () => {
  const o = Y(z);
  if (!o.setCurrentProduct) throw new Error("useEngineNavigation should be used as a child of EngineNavigationProvider");
  return o;
}, W = [c.PACKAGE_COMPLETE, c.PACKAGES], H = [c.BUSING, c.BUSING_CHARTER], U = {
  [c.PACKAGE_COMPLETE]: W,
  [c.PACKAGES]: W,
  [c.BUSING]: H,
  [c.BUSING_CHARTER]: H
}, me = 120, Ee = -120, M = ({
  icon: o,
  label: a
}) => /* @__PURE__ */ s.createElement(le, null, o ? /* @__PURE__ */ s.createElement(o, { scale: "md" }) : void 0, a);
M.displayName = "ProcuctWrapper";
function Ce(o) {
  const {
    onClick: a,
    className: E = "",
    ...N
  } = o, {
    currentProduct: d,
    products: h,
    navigationType: R
  } = he(), [p, v] = _(!1), [A, I] = _(!1), {
    name: C = "cvc"
  } = X(), k = g(/* @__PURE__ */ new Map()), w = g(!1), i = g(null), m = g(null), b = g(null), r = g(null), f = q((e, ...t) => {
    if (!(t != null && t.length)) return;
    const n = new IntersectionObserver((u) => {
      u.forEach((S) => {
        e(S.isIntersecting, S.target);
      });
    }, {
      root: document.body,
      threshold: 1
    });
    return t.forEach((u) => u && n.observe(u)), n;
  }, []), P = (e) => {
    var t, n, u;
    (u = (t = r.current) == null ? void 0 : t.scroll) == null || u.call(t, {
      left: ((n = r.current) == null ? void 0 : n.scrollLeft) + e,
      behavior: "smooth"
    });
  }, L = (e) => {
    var t, n;
    (n = (t = k.current.get(e)) == null ? void 0 : t.scrollIntoView) == null || n.call(t, {
      block: "center",
      inline: "center",
      behavior: "smooth"
    });
  };
  x(() => {
    const e = f((t, n) => {
      n.classList.contains("last") ? v(!t) : I(!t);
    }, b.current, m.current);
    return () => {
      e == null || e.disconnect();
    };
  }, [f]), x(() => {
    var e, t;
    w.current || (w.current = !0, (t = (e = i.current) == null ? void 0 : e.scrollIntoView) == null || t.call(e, {
      inline: "center",
      block: "center"
    }));
  }, []);
  const l = B(() => {
    const e = (n, u) => n ? n.some((O) => O === u) : void 0, t = h.find((n) => n.id && (d === n.id && !!n.id || e(U == null ? void 0 : U[d], n.id)));
    return (t == null ? void 0 : t.label) ?? "";
  }, [h, d]), T = (e) => e.startsWith("/"), G = (e, t) => {
    const n = e.id, u = l === e.label, S = `${C}-${e.label + n}`;
    return /* @__PURE__ */ s.createElement(ce, { key: S, ref: (O) => (k.current.set(n, O), O), onClick: () => {
      L(n), t(n);
    }, underline: u, id: S, type: "button" }, /* @__PURE__ */ s.createElement(M, { label: e.label, icon: e.icon }));
  }, F = (e) => {
    const t = l === e.label;
    return /* @__PURE__ */ s.createElement(se, { key: `${C}-${e.label}`, underline: t, id: `${C}-${e.label}`, href: e.href, target: T(e.href ?? "") ? void 0 : "_blank", "aria-current": t ? "page" : void 0, rel: "noreferrer" }, /* @__PURE__ */ s.createElement(M, { icon: e.icon, label: e.label }));
  };
  return /* @__PURE__ */ s.createElement(re, { className: `${E} chui-engines-navigation`, ...N }, /* @__PURE__ */ s.createElement(oe, { ref: r }, /* @__PURE__ */ s.createElement(K, { left: 0, show: A, onClick: () => P(Ee), icon: /* @__PURE__ */ s.createElement(J, null), size: "sm", color: "white", shadow: !0 }), /* @__PURE__ */ s.createElement(V, { className: "first", ref: b }), h.map((e) => R === "button" && a && T(e.href ?? "") ? G(e, a) : F(e)), /* @__PURE__ */ s.createElement(V, { className: "last", ref: m }), /* @__PURE__ */ s.createElement(K, { show: p, onClick: () => P(me), right: 0, icon: /* @__PURE__ */ s.createElement(Q, null), size: "sm", color: "white", shadow: !0 })));
}
Ce.displayName = "Navigation";
export {
  de as E,
  Ce as N,
  $ as g,
  he as u
};
