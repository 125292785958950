import e from "react";
import { useIsomorphicLayoutEffect as T, Grid as v } from "@cvccorp-components/chui-react-components";
import { useCollapsableEngine as I, getCalendarDatesAsDate as P, FormContainer as i, OptionsBar as S, CollapsableEngineButton as _ } from "@cvccorp-engines/components";
import { d as j } from "../../dayjs-CxYy674R.js";
import "../../context/EngineHotelContext/EngineHotelContext.js";
import J from "../../context/EngineHotelContext/useEngineHotel.js";
import "../../context/EngineResortsContext/EngineResortsContext.js";
import { useEngineResortsContext as w } from "../../context/EngineResortsContext/useEngineResortsContext.js";
import "../../store/HotelStore/HotelStore.js";
import { useResortsStore as G } from "../../store/ResortsStore/ResortsStore.js";
import { useHotelsResortsStore as V, OptionHotelsResortsBarValue as b } from "../../store/HotelResortsStore/HotelsResortsStore.js";
import { choices as R } from "../EnginesUnified/EnginesUnified.js";
import g from "../LocationAndDate/LocationAndDate.js";
import H from "../ResortDefaultLocationForm/index.js";
import { Form as X, Container as n, ButtonContainer as $, ResortButtonContainer as q, HOTEL_CONTAINER_EXPANDED_CLASSNAME as z } from "./Form.styles.js";
function K({
  product: o
}) {
  const {
    choice: r,
    setChoice: a
  } = V(), {
    handleSearch: M
  } = J(), {
    handleSearch: N
  } = w(), {
    value: O
  } = I(), {
    destination: m,
    setDestination: c,
    calendarDates: k,
    setCalendarDates: A,
    errors: d,
    clearDates: p,
    setOpenPax: F
  } = G(), t = O ? z : "";
  T(() => {
    o && [b.HOTELS, b.RESORTS].includes(o) && a(o);
  }, []);
  const u = j().add(1, "d").toDate(), E = P(k), h = () => F(!0), D = (f) => {
    if (!f) return;
    const [l, s] = f;
    A({
      startDate: l == null ? void 0 : l.toJSON(),
      endDate: s == null ? void 0 : s.toJSON()
    });
  }, C = {
    hotel: {
      handleSearch: M,
      renderMobile: () => /* @__PURE__ */ e.createElement(n, { className: t }, /* @__PURE__ */ e.createElement(g, null)),
      renderDesktop: () => /* @__PURE__ */ e.createElement(n, { className: t }, /* @__PURE__ */ e.createElement(g, null), /* @__PURE__ */ e.createElement($, null, /* @__PURE__ */ e.createElement(i.Submit, null))),
      title: "Hotel"
    },
    resorts: {
      handleSearch: N,
      renderMobile: () => /* @__PURE__ */ e.createElement(n, { className: t }, /* @__PURE__ */ e.createElement(H, { errors: d, destination: m, onDestinationChange: c, onOpenPax: h, dates: E, onDateClear: p, onDateChange: D, min: u })),
      renderDesktop: () => /* @__PURE__ */ e.createElement(n, { className: t }, /* @__PURE__ */ e.createElement(H, { errors: d, destination: m, onDestinationChange: c, onOpenPax: h, dates: E, onDateClear: p, onDateChange: D, min: u }, /* @__PURE__ */ e.createElement(q, null, /* @__PURE__ */ e.createElement(i.Submit, null)))),
      title: "Resort"
    }
  }, {
    handleSearch: L,
    renderMobile: x,
    renderDesktop: B,
    title: y
  } = C[r] ?? C.hotel;
  return /* @__PURE__ */ e.createElement(X, { formId: "form-hotel", onSearch: L, renderMobile: () => /* @__PURE__ */ e.createElement(i.Mobile, { title: y, label: "Destino" }, /* @__PURE__ */ e.createElement(S, { options: R, value: r, onChange: a }), x()), renderDesktop: () => /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement(v, { container: !0, justifyContent: "space-between" }, /* @__PURE__ */ e.createElement(S, { options: R, value: r, onChange: a }), /* @__PURE__ */ e.createElement(_, null)), B()) });
}
K.displayName = "Form";
export {
  K as default
};
