import e from "react";
import a from "./components/Form/Form.js";
import { a as n } from "./EngineBookingPackagesContext-D4tagYeS.js";
const r = ({
  isAtlas: o
}) => /* @__PURE__ */ e.createElement(n, { isAtlas: o }, /* @__PURE__ */ e.createElement(a, null));
r.displayName = "EngineBookingPackages";
export {
  r as default
};
