import { FormContainer as e } from "@cvccorp-engines/components";
import i from "@emotion/styled";
const t = "packageEngine_container__expanded", a = "packageFullEngine_container__expanded", o = i.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    gap: 12px;
    container-type: inline-size;
    align-items: flex-start;
    @container (min-width: 899px) {
        display: grid;
        grid-template-columns: 1fr 1fr 120px;
        gap: 12px;
    }
`, c = i.div`
    margin-left: ${({
  theme: n
}) => n.spacing.size[5]};
    container-type: inline-size;

    @container (max-width: 1024px) {
        margin: 0;
    }
`, d = i.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    gap: 12px;
    container-type: inline-size;
    align-items: flex-start;
    @container (min-width: 899px) {
        display: grid;
        grid-template-columns: 1fr 1fr 130px;
        gap: 12px;
    }
`, l = i.div`
    margin-left: ${({
  theme: n
}) => n.spacing.size[5]};
    container-type: inline-size;

    @container (max-width: 1024px) {
        margin: 0;
    }
`, s = i(e)`
    & .${t}, & .${a} {
        @container (min-width: 899px) {
            grid-template-columns: 1fr 1fr 180px;
        }
    }
`;
export {
  l as ButtonContainer,
  s as Form,
  t as PACKAGE_CONTAINER_EXPANDED_CLASSNAME,
  a as PACKAGE_FULL_CONTAINER_EXPANDED_CLASSNAME,
  o as PackageContainer,
  d as PackageFullContainer,
  c as buttonContainer
};
