"use client";
import C, { useState as _, useMemo as h } from "react";
import { getCarsLocations as x } from "@cvccorp-engines/api";
import { getParams as u, useError as y, useFormMobile as E, Pesqinc as N } from "@cvccorp-engines/components";
import { mapCarsLocationsToCarLocationOptions as G } from "../../utils/mapCarsLocationsToCarLocationOptions.js";
function M(m) {
  const {
    error: l,
    label: d,
    value: s,
    inputProps: f,
    supportText: O,
    supportTextProps: R,
    onClear: n,
    onSelect: a
  } = m, [p, g] = _([]), L = u("packageGroup") ? String(u("packageGroup")).toUpperCase() : "STANDALONE", {
    throwError: r
  } = y(), {
    firstInputRef: P
  } = E(), b = async (e) => {
    var c;
    try {
      const o = await x(e);
      if ((c = o == null ? void 0 : o.locations) != null && c.length) g(G(o.locations ?? []));
      else {
        const t = i("Nenhuma cidade encontrada com o texto buscado", e, 200, "success");
        Object.assign(t, {
          _message: `EMPTYRESULT ${e}`
        }), r == null || r(t);
      }
    } catch (o) {
      const t = i("Ocorreu um erro ao tentar buscar a cidade", e, (o == null ? void 0 : o.message) || 500, "error");
      return Object.assign(t, {
        _message: `ERROR ${e}`,
        errorResponse: {
          error: o.message
        }
      }), r == null || r(t), [];
    }
  }, k = (e) => {
    if (!e) {
      n ? n == null || n() : a == null || a(s);
      return;
    }
    a == null || a(e);
  }, T = h(() => {
    const e = p;
    return s && e.push({
      value: s,
      label: s.description
    }), e;
  }, [p, s]), i = (e, c, o, t) => ({
    friendlyMessage: e,
    _message: `ERROR ${c}`,
    operation: "location",
    error: "Request failed with status code " + o,
    productType: "car",
    _level_name: t,
    packageGroup: L
  });
  return /* @__PURE__ */ C.createElement(N, { oneLineOption: !0, placeholder: "Cidade ou aeroporto", label: d, supportText: O, supportTextProps: R, error: l, value: s, onSearch: b, options: T, onSelect: k, inputProps: {
    inputRef: P,
    ...f
  } });
}
M.displayName = "CarsLocation";
export {
  M as default
};
