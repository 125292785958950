import a, { useMemo as h, useRef as D, useEffect as b, Fragment as v } from "react";
import { useResponsive as F } from "@cvccorp-components/chui-react-components";
import { useFormMobile as g, useAutoFocus as M, InputDatePicker as x } from "@cvccorp-engines/components";
import A from "../SingleTickets/SingleTickets.js";
function y(u) {
  const {
    errors: e,
    max: s,
    min: d,
    destination: m,
    date: o,
    onDestinationChange: t,
    onDateChange: f,
    onDateClear: p
  } = u, {
    firstInputRef: P
  } = g(), {
    isMobile: r
  } = F(), {
    register: n,
    focusField: k
  } = M(["datePicker"]), E = h(() => o ? {
    month: o.getMonth(),
    year: o.getFullYear()
  } : void 0, [o]), c = D(null);
  b(() => {
    n("datePicker", {
      el: c.current,
      action: r ? "focus" : "click"
    });
  }, [n, r]);
  const i = () => e != null && e.destination ? "Preencha o campo de destino" : "", R = () => e != null && e.date ? "Preencha o campo de data" : "";
  return /* @__PURE__ */ a.createElement(v, null, /* @__PURE__ */ a.createElement(A, { supportText: i(), error: i(), label: "Destino", value: m, onSelect: (l) => {
    l && (t == null || t(l), k("datePicker"));
  }, inputProps: {
    inputRef: P
  }, onClear: () => {
    t == null || t(void 0);
  } }), /* @__PURE__ */ a.createElement(x, { label: "Escolha a data", value: o, onChange: f, onClear: p, calendarDateVisible: E, max: s, min: d, error: R(), inputRef: c }));
}
y.displayName = "LocationAndDate";
export {
  y as default
};
