import e from "react";
import { InputMonthPicker as i } from "@cvccorp-components/chui-react-components";
import { u as c } from "../../../EngineBookingPackagesContext-D4tagYeS.js";
import d from "./CalendarMonthPickerSwitch.js";
const m = () => {
  const {
    calendarMonthPickerDateSelected: n,
    setCalendarMonthPickerDateSelected: a,
    errors: o,
    calendarIsOpen: l,
    setCalendarIsOpen: s
  } = c(), t = o.dates.startDate, r = 3;
  return /* @__PURE__ */ e.createElement(i, { fullWidth: !0, label: "Escolha os meses", size: "md", open: l, value: n, inputProps: {
    status: t ? "danger" : void 0
  }, supportText: t ? "Preencha a data" : void 0, onChange: a, onToggleMonthPicker: s, monthPickerProps: {
    limitSelectionCount: r,
    renderAddonBefore: () => /* @__PURE__ */ e.createElement("span", null, "Selecione ", /* @__PURE__ */ e.createElement("strong", null, "até ", r, " meses"), " para realizar sua busca."),
    monthPickerNavigationProps: {
      renderAddonBefore: () => /* @__PURE__ */ e.createElement(d, null)
    }
  } });
};
m.displayName = "CalendarMonthPicker";
export {
  m as default
};
