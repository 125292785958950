import e from "react";
import a from "@cvccorp-components/chui-react-components/components/DatePicker/DatePickerDivider";
import r from "@cvccorp-components/chui-react-components/components/Grid";
import h from "@cvccorp-components/chui-react-components/components/Spinner";
import { useFieldErrors as P, InputDatePicker as I } from "@cvccorp-engines/components";
import { useCalendarPicker as b } from "./useCalendarPicker.js";
function k(o) {
  const {
    min: l,
    max: c,
    errors: m,
    options: s,
    isMobile: t,
    isLoading: i,
    currentDate: d,
    initialDate: u,
    priceWrapperDirection: n,
    calendarPickerRef: E,
    clearDates: g,
    handleChange: p,
    handleDisableDates: f,
    handleChangeCalendar: C
  } = b(o), {
    getErrorMessage: D
  } = P({
    errors: m
  });
  return /* @__PURE__ */ e.createElement(I, { showRangeCount: !0, range: !1, label: "Escolha a data", max: c, min: l, options: s, isLoading: i, value: d[0], calendarDateVisible: u, error: D("startDate"), onClear: g, onChange: p, disableDates: f, onChangeCalendar: C, inputRef: E, content: i ? /* @__PURE__ */ e.createElement(r, { container: !0, alignItems: "center" }, /* @__PURE__ */ e.createElement(r, { item: !0 }, /* @__PURE__ */ e.createElement(h, { color: "secondary" })), /* @__PURE__ */ e.createElement(r, { item: !0 }, "Carregando preços e datas disponíveis")) : /* @__PURE__ */ e.createElement(r, { container: !0, spacing: t ? 0 : 2 }, /* @__PURE__ */ e.createElement(r, { item: !0 }, /* @__PURE__ */ e.createElement(r, { alignItems: "center", container: !0, direction: n, style: t ? {
    fontSize: 13,
    marginInlineEnd: 16
  } : {} }, "Preço normal", /* @__PURE__ */ e.createElement(a, { variant: "regular" }))), /* @__PURE__ */ e.createElement(r, { item: !0 }, /* @__PURE__ */ e.createElement(r, { alignItems: "center", container: !0, direction: n, style: t ? {
    fontSize: 13,
    marginInlineEnd: 16
  } : {} }, "Menor Preço", /* @__PURE__ */ e.createElement(a, { variant: "good" }))), /* @__PURE__ */ e.createElement(r, { item: !0 }, /* @__PURE__ */ e.createElement(r, { alignItems: "center", container: !0, direction: n, style: t ? {
    fontSize: 13,
    marginInlineEnd: 16
  } : {} }, "Preço promocional", /* @__PURE__ */ e.createElement(a, { variant: "bad" })))) });
}
k.displayName = "CalendarPicker";
export {
  k as default
};
