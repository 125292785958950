import r, { useRef as j, useEffect as J, useMemo as W, Fragment as $ } from "react";
import { useResponsive as B } from "@cvccorp-components/chui-react-components";
import { useFormMobile as G, useAutoFocus as q, useFieldErrors as z, InputGroup as H, InputDatePickerRange as K, gtm as Q } from "@cvccorp-engines/components";
import { d as E } from "../../dayjs-CxYy674R.js";
import { PaxPackages as U } from "@cvccorp-engines/pax";
import { usePackageStore as V } from "../../store/PackageStore/PackageStore.js";
import k from "../SinglePackage/SinglePackage.js";
import { LocationAndDateWrapper as X } from "./styled.js";
function Y({
  max: v,
  min: D,
  dates: t = [],
  errors: a,
  origin: u,
  isMult: b = !1,
  destination: s,
  onReverse: f,
  onDateClear: A,
  onDateChange: m,
  onOriginChange: c,
  onDestinationChange: o
}) {
  const {
    openPax: S,
    pax: y,
    setOpenPax: i,
    clearPax: F,
    setPax: I
  } = V(), {
    firstInputRef: w
  } = G(), {
    isMobile: n
  } = B(), {
    register: d,
    focusField: P
  } = q(["destination", "datePickerRange"]), {
    getErrorMessage: l
  } = z({
    errors: a
  }), R = j(null);
  J(() => {
    var e;
    d("datePickerRange", {
      el: (e = R.current) == null ? void 0 : e.inputOneRef,
      action: n ? "focus" : "click"
    });
  }, [d, n]);
  const M = W(() => {
    var e, p;
    return {
      endDate: (e = t[1]) == null ? void 0 : e.toJSON(),
      startDate: (p = t[0]) == null ? void 0 : p.toJSON()
    };
  }, [t]), L = () => {
    Q.pushEvent("pacote_home_engajamento", {
      category: "pacote_busca",
      action: "click-swap",
      label: `${u == null ? void 0 : u.iata} - ${s == null ? void 0 : s.iata}`
    }), f == null || f(s, u);
  }, N = (e) => {
    if ((t == null ? void 0 : t.length) === 2) return !1;
    if (t[0]) {
      const p = E(t[0]), x = E(e), T = p.add(29, "day"), _ = p.subtract(29, "day");
      return x.isAfter(T) || x.isBefore(_);
    }
    return !1;
  };
  return /* @__PURE__ */ r.createElement($, null, /* @__PURE__ */ r.createElement(H, { hasError: a.origin || a.destination || a.originDestination, fullWidth: !0, onReverse: L, status: a.origin || a.destination || a.originDestination ? "danger" : void 0, hideIcon: !0, hideClearIcon: !1 }, /* @__PURE__ */ r.createElement(k, { supportText: l("origin"), error: l("origin"), label: "Origem", placeholder: "De onde você vai sair?", value: u, onSelect: (e) => {
    c == null || c(e), P("destination");
  }, onClear: () => {
    c == null || c(void 0);
  }, inputProps: {
    inputRef: w
  } }), /* @__PURE__ */ r.createElement(k, { supportText: l("destination"), error: l("destination"), value: s, placeholder: "Para onde vai?", onSelect: (e) => {
    o == null || o(e), P("datePickerRange");
  }, onClear: () => {
    o == null || o(void 0);
  }, inputProps: {
    inputRef: (e) => d("destination", {
      action: "focus",
      el: e
    })
  } })), /* @__PURE__ */ r.createElement(X, { isMult: b }, /* @__PURE__ */ r.createElement(K, { value: t, max: v, min: D, showRangeCount: !0, onChange: (e) => {
    m == null || m(e);
  }, error: {
    startDate: l("startDate"),
    endDate: l("endDate")
  }, onClear: A, disableDates: N, inputRefs: R }), /* @__PURE__ */ r.createElement(U, { infantAge: 0, open: S, error: a.pax, calendarDates: M, onConfirm: (e) => {
    I(e), i(!1);
  }, onClear: F, onToggle: i, value: y })));
}
Y.displayName = "LocationAndDate";
export {
  Y as default
};
