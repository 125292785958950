import C from "react";
import { CalendarDatePicker as h } from "@cvccorp-engines/components";
import { useEngineBusingCharterContext as g } from "../../../context/EngineBusingCharterContext.js";
import p from "./useFetchCalendar.js";
const D = ({
  minDate: n,
  maxDate: r
}) => {
  var t;
  const {
    setCalendarDateSelected: o,
    calendarDateSelected: a,
    errors: e
  } = g(), {
    isLoading: l,
    options: i,
    initialDate: d,
    handleChangeCalendar: c
  } = p(), s = (t = e == null ? void 0 : e.dates) == null ? void 0 : t.startDate;
  return /* @__PURE__ */ C.createElement(h, { label: "Escolha a data", isLoading: l, options: i, onChange: (m) => o([new Date(m)]), calendarDateVisible: d, value: a.length ? a[0] : void 0, error: s ? "Preencha a data" : void 0, onChangeCalendar: c, min: n, max: r });
};
D.displayName = "CalendarPicker";
export {
  D as default
};
