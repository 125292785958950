import { NavigationItemConfig } from "@cvccorp-engines/navigation";

export const getEngineItems = async (): Promise<Array<NavigationItemConfig>> => {
    try {
        const url = process.env.CONTEXTAPI || "corp-platform-context.k8s-cvc.com.br";
        const finalUrl = `https://${url}/api/Engine?brand=cvc`;
        const response = await fetch(finalUrl).then(res => res.json());
        return response?.navigation?.items ?? [];
    } catch (err) {
        console.log("Falha na requisição de navigation items: ", err);
        return [];
    }
};
