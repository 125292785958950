function o(e) {
  const t = {};
  e.forEach((r) => {
    if (t[r.year]) return t[r.year].push(r.month);
    t[r.year] = [r.month];
  });
  let a = "";
  return Object.keys(t).forEach((r, n) => {
    a += `${r}-${t[r].join(",")}${Object.keys(t).length === n + 1 ? "" : ";"}`;
  }), a;
}
export {
  o as default
};
