import { useRef as U, useMemo as S } from "react";
import { getBusingCharterCalendar as j } from "@cvccorp-engines/api";
import { useError as F, useFetch as I } from "@cvccorp-engines/components";
import { d as L } from "../../../../dayjs-CxYy674R.js";
import { formatPaxForService as R } from "@cvccorp-engines/pax";
import { useEngineBusingCharterContext as V } from "../../../context/EngineBusingCharterContext.js";
import { getBusingCharterParamsForLogs as $ } from "../../../utils/getBusingParamsForLogs.js";
import w from "./formatOptions.js";
const A = 60 * 5 * 1e3, Q = () => {
  var T, C;
  const {
    pesqincLocationSelected: a,
    calendarDateSelected: b,
    paxValue: u,
    startDate: n,
    setStartDate: D,
    isAtlas: v
  } = V(), {
    throwError: r
  } = F(), d = U(), g = (n == null ? void 0 : n.length) === 2, s = (T = a == null ? void 0 : a.origin) == null ? void 0 : T.id, i = (C = a == null ? void 0 : a.destination) == null ? void 0 : C.id, x = S(() => R(u), [u]), y = async () => {
    var h;
    if (!s && !i) return;
    const t = $({
      operation: "calendarLocation"
    }), e = (/* @__PURE__ */ new Date()).getTime();
    try {
      const m = await j({
        originId: s,
        destinationId: i,
        rooms: x,
        startDate: g ? `${n[0]}-${(h = (+(n[1] ?? "0") + 1).toString()) == null ? void 0 : h.padStart(2, "0")}-01` : void 0,
        numberOfMonths: g ? 2 : void 0,
        isAtlas: v
      }), l = (/* @__PURE__ */ new Date()).getTime() - e;
      return Object.assign(t, {
        message: m.calendar ? "SUCCESS" : "EMPTYRESULT",
        duration: l,
        durationUnit: "milisseconds"
      }), r == null || r(t), m;
    } catch (m) {
      const l = (/* @__PURE__ */ new Date()).getTime() - e;
      return Object.assign(t, {
        message: "Erro ao buscar calendário",
        duration: l,
        durationUnit: "milisseconds",
        errorResponse: m,
        level: 4
      }), r == null || r(t), null;
    }
  }, P = (t, e) => {
    d.current && clearTimeout(d.current), d.current = setTimeout(() => {
      D([e.toString(), t.toString().padStart(2, "0")]);
    }, 700);
  }, B = [s, i, b, u, n], {
    data: f,
    isLoading: c,
    previous: E
  } = I(y, B, {
    cacheTime: A,
    enabled: !!(s && i)
  }), p = Object.values(E).filter((t) => !!(t != null && t.calendar)).flatMap((t) => t == null ? void 0 : t.calendar), M = S(() => c ? [] : w(p), [p, c]), o = (() => {
    var e;
    if (!f || c) return;
    const {
      date: t
    } = ((e = f.calendar) == null ? void 0 : e[0]) || {};
    return L(t);
  })();
  return {
    isLoading: c,
    options: M,
    initialDate: {
      month: o == null ? void 0 : o.month(),
      year: o == null ? void 0 : o.year()
    },
    handleChangeCalendar: P
  };
};
export {
  Q as default
};
