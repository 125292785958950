import e from "react";
import { useWindow as y, Typography as A } from "@cvccorp-components/chui-react-components";
import { E as N } from "../../EngineFlight-POsukU71.js";
import { _ as b, a as g, e as x, l as _ } from "../../PesqincFight-DY1Yl5dT.js";
import "../../index-C86aQ7OP.js";
import { useQueryParam as s, EngineQueryParams as d, CollapsableEngineContainer as $, TripDestination as w, DateIntervalLabel as P, DateFormat as T, CollapsableEngineButton as L, getQueryParam as h } from "@cvccorp-engines/components";
import { d as u } from "../../dayjs-CxYy674R.js";
import { PaxShort as S } from "@cvccorp-engines/pax";
import { EngineContainer as F, Grid as Q, TravelClassContainer as z } from "./styled.js";
var I = ["size", "className"], E = function(n) {
  var t, r, i = n.size, o = i === void 0 ? "medium" : i, m = n.className, c = m === void 0 ? "" : m, l = b(n, I), v = l, f = ["chui-icon", c].join(" "), D = v.color || "#000014", C = g({}, l, {
    color: void 0
  }), p = x[o] || _[o];
  return /* @__PURE__ */ e.createElement("svg", g({
    xmlns: "http://www.w3.org/2000/svg",
    fill: D,
    viewBox: "0 0 24 24",
    width: (t = l.width) != null ? t : p,
    height: (r = l.height) != null ? r : p,
    className: f
  }, C), /* @__PURE__ */ e.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M12 2.351a.5.5 0 0 1 .471.332l2.27 6.377 6.461.296a.5.5 0 0 1 .298.883l-5.08 4.244 1.737 6.578a.5.5 0 0 1-.77.538L12 17.854 6.612 21.6a.5.5 0 0 1-.769-.539l1.737-6.578L2.5 10.24a.5.5 0 0 1 .298-.883l6.46-.296 2.271-6.377A.5.5 0 0 1 12 2.351m0 1.99-1.912 5.37a.5.5 0 0 1-.448.332l-5.512.253 4.337 3.624a.5.5 0 0 1 .163.511l-1.47 5.572 4.557-3.168a.5.5 0 0 1 .57 0l4.558 3.168-1.471-5.572a.5.5 0 0 1 .163-.511l4.337-3.624-5.512-.253a.5.5 0 0 1-.448-.332z",
    clipRule: "evenodd"
  }));
};
E.displayName = "OutlineStar";
const M = (a) => {
  const {
    pathname: n
  } = a.location, t = n.split("/").filter((r) => r.length === 3).length / 2;
  return {
    originAddress: h(`MCO${t}`, a, "").split("-")[0],
    destinationAddress: h(`MCD${t}`, a, "").split("-")[0]
  };
}, O = (a) => {
  const {
    pathname: n
  } = a.location, t = n.split("/");
  if (t.length <= 4) return {
    origin: {
      name: "",
      address: ""
    },
    destination: {
      name: "",
      address: ""
    }
  };
  const {
    destinationAddress: r,
    originAddress: i
  } = M(a);
  return {
    origin: {
      name: t.at(-2),
      address: i
    },
    destination: {
      name: t.at(-1),
      address: r
    }
  };
}, j = (a) => {
  const n = s("Date1", (/* @__PURE__ */ new Date()).toString()), t = s("Date2", u(/* @__PURE__ */ new Date()).add(7, "days").toString()), r = s("CLA", ""), i = s(d.ADT, 0), o = s(d.CHD, 0), m = s(d.INF, 0), c = y(), l = () => r === "exe" ? "Executiva & Primeira" : r === "eco" ? "Econômica & Premium" : "Todas as Classes";
  return /* @__PURE__ */ e.createElement($, { ...a, renderExpandedEngine: () => /* @__PURE__ */ e.createElement(F, null, /* @__PURE__ */ e.createElement(N, null)) }, /* @__PURE__ */ e.createElement(Q, { container: !0, direction: "row", alignItems: "center" }, /* @__PURE__ */ e.createElement(w, { ...O(c), type: "AIRPLANE" }), /* @__PURE__ */ e.createElement(P, { dateFormat: T.DAY_AND_MONTH, initialDate: u(n).toDate(), finalDate: u(t).toDate() }), /* @__PURE__ */ e.createElement(S, { peopleQuantity: Number(i) + Number(o) + Number(m), roomsQuantity: 0 }), /* @__PURE__ */ e.createElement(z, null, /* @__PURE__ */ e.createElement(E, null), /* @__PURE__ */ e.createElement(A, { variant: "paragraph", scale: 3 }, l())), /* @__PURE__ */ e.createElement(L, null)));
};
j.displayName = "EngineFlightCollapsable";
export {
  j as default
};
