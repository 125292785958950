import n from "@emotion/styled";
const e = n.div(() => ({
  overflow: "hidden",
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  columnGap: 16,
  marginBlockEnd: 24,
  "& .chui-carousel-shelf__container": {
    gap: 16
  },
  "@container (max-width: 899px)": {
    marginBlockEnd: 12
  }
})), i = n("a")(({
  disabled: o
}) => ({
  pointerEvents: o ? "none" : void 0,
  "*": {
    cursor: o ? "auto" : "pointer"
  },
  input: {
    pointerEvents: "none"
  }
})), r = n.div`
    display: flex;
    gap: 12px;
`;
export {
  e as Container,
  i as Link,
  r as RadioContainer
};
