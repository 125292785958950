import { Spinner as r } from "@cvccorp-components/chui-react-components";
import i from "@emotion/styled";
const o = i(r)(() => ({
  "*": {
    fill: "revert-layer"
  }
})), h = i("div")(() => ({
  height: 24,
  minWidth: 24,
  svg: {
    width: 20,
    height: 20
  }
}));
export {
  o as Loading,
  h as Wrapper
};
