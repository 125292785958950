import i from "@emotion/styled";
const e = i.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    gap: 12px;
    container-type: inline-size;
    /* padding: 30px 16px 40px; */
    align-items: flex-start;
    @container (min-width: 899px) {
        display: grid;
        grid-template-columns: 1fr 1fr 180px;
        gap: 12px;
    }
`, r = i.div`
    margin-left: ${({
  theme: t
}) => t.spacing.size[5]};
    container-type: inline-size;

    @media (max-width: 600px) {
        display: none;
    }

    @container (max-width: 1024px) {
        margin: 0;
    }
`, a = i.div`
    padding-inline-start: 40px;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 3fr;
    width: 100%;
    @container (max-width: 898px) {
        padding-inline-start: 0px;
        display: flex;
        flex-direction: column;
        row-gap: 16px;
    }
`, o = i.span`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background-color: ${({
  theme: t
}) => t.colors.brand.primary[500]};
`, p = i.div`
    .destination {
        position: relative;
        left: 0;
        color: ${({
  theme: t
}) => t.component.button.color.primary.filled.default.fontColor};
        text-align: center;
        display: flex;
        align-items: top;
        justify-content: center;
        height: 100%;
        transform: translateY(16px);
        ::before {
            content: ${({
  index: t
}) => `"${t}"`};
            position: absolute;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
            border-radius: 12px;
            background-color: ${({
  theme: t
}) => t.colors.brand.primary[500]};
        }
        ::after {
            content: ${({
  isLast: t
}) => t ? void 0 : '""'};
            height: calc(100% + 24px + 12px);
            z-index: 0;
            width: 3px;
            background-color: ${({
  theme: t
}) => t.colors.brand.primary[500]};
        }

        @container (max-width: 688px) {
            display: none;
        }
    }
    display: grid;
    grid-template-columns: 30px 1fr;
    column-gap: 10px;

    @container (max-width: 898px) {
        margin-bottom: ${({
  isLast: t
}) => t ? 16 : 32}px;
    }

    @container (max-width: 688px) {
        grid-template-columns: 1fr;
    }
`, d = i.div`
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    column-gap: 12px;
    row-gap: 8px;
    align-items: flex-start;
    @container (min-width: 899px) {
        display: grid;
        grid-template-columns: 3fr 4fr;
        /* column-gap: ${({
  theme: t
}) => t.spacing.size[8]}; */
    }
`, l = i.div(({
  isMult: t = !1
}) => ({
  display: "grid",
  gridTemplateColumns: t ? "1fr" : "1fr 1fr",
  rowGap: 8,
  alignItems: "flex-start",
  gap: 12,
  width: "100%",
  ".date-picker": {
    display: "flex",
    alignItems: "flex-start",
    gap: 8,
    width: "100%"
  },
  "@container (min-width: 899px)": {
    gridTemplateColumns: "1fr 1fr"
  },
  "@container (max-width: 688px)": {
    gridTemplateColumns: "1fr"
  }
}));
export {
  a as AddDestinationButtonWrapper,
  o as DestinationCircle,
  d as LocationAndDateContainer,
  l as LocationAndDateWrapper,
  e as MotorContainer,
  p as MultiDestinationWrapper,
  r as buttonContainer
};
