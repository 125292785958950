import n, { useState as v, useMemo as b } from "react";
import O from "@cvccorp-components/chui-react-components/components/AutoCompleteTag";
import { useFormMobile as w, useAutoCompleteItemSize as z } from "@cvccorp-engines/components";
import { useToursOptions as M } from "../../context/ToursOptionsContext/ToursOptionContext.js";
import { LocationContainer as A } from "./MultiToursLocation.styles.js";
const C = (i) => {
  const {
    error: t,
    value: e,
    supportText: l,
    supportTextProps: a,
    label: p = "Destino",
    onSelect: u
  } = i, {
    options: s,
    getOptions: c,
    getInitialOptions: m
  } = M(), {
    firstInputRef: f
  } = w(), [d, h] = v(!1), S = b(() => {
    const o = [...s];
    return e ? (e.forEach((r) => {
      const T = {
        label: r.description,
        value: r
      };
      o.push(T);
    }), [...o]) : o;
  }, [s, e]), g = z();
  return /* @__PURE__ */ n.createElement(A, null, /* @__PURE__ */ n.createElement(O, { label: d ? "" : p, itemSize: g, supportText: l ?? t, loading: !1, onSearch: (o) => {
    h((o == null ? void 0 : o.length) > 0), o.length >= 3 ? c(o) : m();
  }, inputProps: {
    supportTextProps: a,
    inputRef: f,
    fullWidth: !0,
    size: "md"
  }, onSelect: (o) => {
    o && u(o);
  }, options: S, status: t ? "danger" : void 0, value: e, maxSize: 5, dropdownAttributes: {
    style: {
      width: 530,
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  } }));
};
C.displayName = "MultiToursLocation";
export {
  C as default
};
