import n from "@emotion/styled";
const e = n.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    gap: 12px;
    container-type: inline-size;
    align-items: flex-start;

    @container (min-width: 899px) {
        display: grid;
        grid-template-columns: ${(i) => i.shortSize ? "1fr 1fr" : "1.5fr 1fr 1fr"};
        gap: 12px;
    }
`, r = n.div`
    margin-left: ${({
  theme: i
}) => i.spacing.size[5]};
    container-type: inline-size;

    @container (max-width: 1024px) {
        margin: 0;
    }
`, a = n.div`
    display: flex;

    & > button {
        min-width: unset;
    }
`;
export {
  r as ButtonContainer,
  a as ElementsContainer,
  e as EngineContainer
};
