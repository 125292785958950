"use client";
import t, { useMemo as M, useRef as F, useEffect as O } from "react";
import { useResponsive as L } from "@cvccorp-components/chui-react-components";
import { useFormMobile as b, useAutoFocus as w, getCalendarDatesAsDate as J, InputGroup as N, InputDatePickerRange as W } from "@cvccorp-engines/components";
import { d as j } from "../../dayjs-CxYy674R.js";
import { PaxTravelInsurance as q } from "@cvccorp-engines/pax";
import { useEnginesConfigContext as B } from "@cvccorp-engines/provider";
import { useTravelInsuranceStore as G } from "../../store/TravelInsuranceStore.js";
import Q from "../PesqincTravelInsurance/PesqincTravelInsurance.js";
import { LocationAndDateWrapper as U } from "./LocationAndDate.styles.js";
const Z = () => {
  const r = j((/* @__PURE__ */ new Date()).toLocaleString("en-US", {
    timeZone: "America/Sao_Paulo"
  })), s = r.hour() >= 23 ? 2 : 1;
  return r.add(s, "days").toDate();
}, _ = Z();
function te() {
  const {
    pax: r,
    destination: s,
    calendarDates: i,
    errors: e,
    openPax: h,
    clearPax: v,
    setOpenPax: d,
    setPax: x,
    setCalendarDates: C,
    setDestination: l,
    clearDates: E
  } = G(), {
    firstInputRef: k
  } = b(), {
    isMobile: u
  } = L(), {
    register: D,
    focusField: I
  } = w(["datePickerRange"]), {
    travelInsurance: {
      maxDate: S,
      minDateRange: A
    }
  } = B(), T = M(() => J(i) || [], [i]), m = F(null);
  O(() => {
    var a;
    D("datePickerRange", {
      el: (a = m.current) == null ? void 0 : a.inputOneRef,
      action: u ? "focus" : "click"
    });
  }, [D, u]);
  const y = (a) => {
    if (!a) return;
    const [n, o] = a;
    C({
      startDate: n == null ? void 0 : n.toJSON(),
      endDate: o == null ? void 0 : o.toJSON()
    });
  }, c = () => {
    var n, o, f, g, P, R;
    const a = {
      startDate: "",
      endDate: ""
    };
    return (n = e == null ? void 0 : e.calendarDates) != null && n.startDate && (a.startDate = "Preencha a data de ida"), (o = e == null ? void 0 : e.calendarDates) != null && o.endDate && (a.endDate = "Preencha a data de volta"), (f = e == null ? void 0 : e.calendarDates) != null && f.startDate && ((g = e == null ? void 0 : e.calendarDates) != null && g.endDate) && (a.startDate = "Preencha a data de ida", a.endDate = "Preencha a data de volta"), !((P = e == null ? void 0 : e.calendarDates) != null && P.startDate) && !((R = e == null ? void 0 : e.calendarDates) != null && R.endDate) && !(e != null && e.date) && (a.startDate = "", a.endDate = ""), a;
  }, p = () => e != null && e.destination ? "Preencha o campo de destino" : "";
  return /* @__PURE__ */ t.createElement(t.Fragment, null, /* @__PURE__ */ t.createElement(Q, { placeholder: "Para onde você vai?", supportText: p(), error: p(), value: s, onSelect: (a) => {
    l(a), I("datePickerRange");
  }, inputProps: {
    inputRef: k
  }, onClear: () => l(void 0) }), /* @__PURE__ */ t.createElement(U, null, /* @__PURE__ */ t.createElement(N, { fullWidth: !0, status: c().startDate || c().endDate ? "danger" : void 0 }, /* @__PURE__ */ t.createElement(W, { value: T, max: S, min: _, showRangeCount: !0, error: c(), onChange: y, onClear: E, hasMinRange: 3, datePickerProps: {
    minRange: A
  }, inputRefs: m })), /* @__PURE__ */ t.createElement(q, { onConfirm: (a) => {
    x(a), d(!1);
  }, open: h, onToggle: d, error: e.pax, onClear: v, value: r })));
}
export {
  te as default
};
