import { useMemo as e } from "react";
import { useEngineSearchHistory as o, LocalStorageKeys as s } from "@cvccorp-engines/components";
import { d as t } from "../../dayjs-CxYy674R.js";
const m = () => {
  const r = o(s.LAST_SEARCH_PACKAGE_FULL);
  return e(() => ({
    ...r,
    searchHistory: r.searchHistory.map((a) => ({
      ...a,
      calendarDates: t(a.calendarDates).toDate()
    }))
  }), [r]);
};
export {
  m as default
};
