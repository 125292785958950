import s from "react";
import { InputDatePickerRange as D } from "@cvccorp-engines/components";
import { useEngineBusingContext as r } from "../../../context/EngineBusingContext.js";
const g = ({
  minDate: c,
  maxDate: l
}) => {
  var t, n, d, i;
  const {
    setCalendarDateSelected: e,
    calendarDateSelected: o,
    errors: a
  } = r(), m = ((t = a == null ? void 0 : a.dates) == null ? void 0 : t.startDate) || ((n = a == null ? void 0 : a.dates) == null ? void 0 : n.endDate);
  return /* @__PURE__ */ s.createElement(D, { value: o, onChange: e, error: m ? {
    startDate: (d = a == null ? void 0 : a.dates) != null && d.startDate ? "Preencha a data de ida" : void 0,
    endDate: (i = a == null ? void 0 : a.dates) != null && i.endDate ? "Preencha a data de volta" : void 0
  } : void 0, onClear: () => e([]), min: c, max: l });
};
g.displayName = "CalendarRangePicker";
export {
  g as default
};
