import { getParams as e, DateFormat as l } from "@cvccorp-engines/components";
import { d as h } from "../dayjs-CxYy674R.js";
const g = () => e("inter") ?? "false", D = () => {
  const t = e("CheckIn");
  return t ? t.concat("T03:00:00.000Z") ?? "" : "";
}, b = () => {
  const t = e("CheckOut");
  return t ? t.concat("T03:00:00.000Z") ?? "" : "";
}, N = () => {
  const t = e("Rooms"), s = e("Adults"), n = e("Children"), c = e("ChildAges");
  if (!t) return [{
    adult: 2,
    children: 0,
    childrenDateOfBirth: []
  }];
  const o = s == null ? void 0 : s.split(","), r = n == null ? void 0 : n.split(","), i = c ? c.split(";") : new Array(Number(t)).fill(void 0).map(() => "");
  return new Array(Number(t)).fill(void 0).map((p, a) => {
    const m = Number(r == null ? void 0 : r[a]);
    return {
      adult: Number(o == null ? void 0 : o[a]),
      children: Number(r == null ? void 0 : r[a]),
      childrenDateOfBirth: m > 0 ? i[a].split(",").map((u) => h(/* @__PURE__ */ new Date()).subtract(Number(u), "years").format(l.YEAR_MONTH_DATE)) : []
    };
  });
};
export {
  g as getResortsDestinationParam,
  b as getResortsEndDateParam,
  N as getResortsPaxParam,
  D as getResortsStartDateParam
};
