import s from "../../libs/fetcher.js";
import "../../libs/cookies.js";
import n from "../../utils/QueryString.js";
const e = {
  productType: "PAC"
}, g = async ({
  searchString: t,
  isAtlas: r
} = {}) => {
  try {
    const {
      data: o
    } = await s.get("/api/hotels/pesqinc/", {
      headers: {
        isAtlas: r
      },
      params: {
        ...t && {
          q: n.text(t).toString()
        },
        ...e
      }
    });
    return o.locations.sort((i, a) => a.hits - i.hits), o;
  } catch {
    throw new Error("Error in Locations");
  }
};
export {
  g as getBookingPackagesLocations
};
