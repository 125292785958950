"use client";
import n, { useMemo as f } from "react";
import E from "@cvccorp-components/chui-react-components/components/InputDatePicker";
import { d as w } from "../../dayjs-CxYy674R.js";
import z from "../InputLoadingOverlay/InputLoadingOverlay.js";
import { Container as A } from "./styled.js";
function L(p) {
  const {
    max: o,
    min: a,
    label: c,
    value: d,
    error: i,
    options: m,
    content: y,
    inputRef: C,
    isLoading: D = !1,
    calendarDateVisible: t,
    range: g = !1,
    showRangeCount: h = !0,
    onClear: x,
    onChange: r,
    onConfirm: P,
    onChangeCalendar: k,
    disableDates: s = () => !1
  } = p, {
    minDate: u,
    maxDate: l
  } = f(() => {
    const e = a ?? /* @__PURE__ */ new Date();
    return {
      minDate: e,
      maxDate: o ?? w(e).add(1, "year").toDate()
    };
  }, [a, o]), v = (e) => {
    e && (r == null || r(e.toString()));
  }, I = f(() => ({
    options: m,
    maxDate: l,
    minDate: u,
    size: "sm",
    month: t == null ? void 0 : t.month,
    year: t == null ? void 0 : t.year,
    disable: s
  }), [s, t, l, u, m]);
  return /* @__PURE__ */ n.createElement(A, null, /* @__PURE__ */ n.createElement(E, { size: "md", range: g, label: c, onClear: x, datePickerProps: I, inputRef: C, status: i ? "danger" : void 0, value: d, fullWidth: !0, supportText: i, showRangeCount: h, onChangeCalendar: k, onChange: (e) => {
    Array.isArray(e) || v(e);
  }, onConfirm: P, content: y }), D ? /* @__PURE__ */ n.createElement(z, null) : void 0);
}
L.displayName = "InputDatePicker";
export {
  L as default
};
