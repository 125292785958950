import e, { useState as l } from "react";
import { PaxPackages as m } from "@cvccorp-engines/pax";
import { u as s, d as i } from "../../EngineBookingPackagesContext-D4tagYeS.js";
import u from "../Calendar/Calendar.js";
import { P as f } from "../../Pesqinc-ZkaQM47P.js";
import { EngineContainer as p } from "./DefaultLocationForm.styles.js";
const c = () => {
  const {
    paxValue: n,
    setPaxValue: a
  } = s(), [o, t] = l(!1);
  return /* @__PURE__ */ e.createElement(p, null, /* @__PURE__ */ e.createElement(f, null), /* @__PURE__ */ e.createElement(u, null), /* @__PURE__ */ e.createElement(m, { open: o, value: n, onToggle: () => t(!o), onClear: () => a(i), onConfirm: (r) => {
    a(r), t(!1);
  } }));
};
c.displayName = "DefaultLocationForm";
export {
  c as default
};
