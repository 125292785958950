import { useMemo as r } from "react";
import { useEngineSearchHistory as s, LocalStorageKeys as o } from "@cvccorp-engines/components";
import { d as e } from "../../dayjs-CxYy674R.js";
const d = () => {
  const a = s(o.LAST_SEARCH_HOTEL);
  return r(() => ({
    ...a,
    searchHistory: a.searchHistory.map((t) => ({
      ...t,
      calendarDates: {
        startDate: e(t.calendarDates.startDate).toDate(),
        endDate: e(t.calendarDates.endDate).toDate()
      }
    }))
  }), [a]);
};
export {
  d as default
};
