import { Grid as t } from "@cvccorp-components/chui-react-components";
import n from "@emotion/styled";
const e = n.div`
    display: grid;
    width: 100%;
    container-type: inline-size;
    grid-template-columns: repeat(1, 1fr);
    column-gap: ${({
  theme: i
}) => i.spacing.size[5]};
    grid-gap: ${({
  theme: i
}) => i.spacing.size[5]};

    @container (min-width: 899px) {
        display: grid;
        grid-template-columns: 1.8fr 300px;
        column-gap: ${({
  theme: i
}) => i.spacing.size[5]};
        grid-gap: initial;
    }
`, o = n.div`
    margin-left: ${({
  theme: i
}) => i.spacing.size[5]};
    container-type: inline-size;
    @container (max-width: 1024px) {
        margin: 0;
    }
`, p = n(t)`
    &&& {
        margin-bottom: 25px;
    }

    & > div {
        margin-block-end: 0px;
    }
`;
export {
  o as ButtonContainer,
  p as Grid,
  e as MotorContainer
};
