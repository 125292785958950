import { getBaseHostname as c, encodeSearchURL as h } from "@cvccorp-engines/components";
import { d as m } from "../dayjs-CxYy674R.js";
function P(e) {
  var a, i, o, r, s, n, d;
  const t = new URL(`${c()}/ingresso/search`);
  return t.searchParams.set("originCode", ((a = e.destination) == null ? void 0 : a.cityId) || ""), t.searchParams.set("destinationCode", ((i = e.destination) == null ? void 0 : i.cityId) || ""), t.searchParams.set("location", `${(o = e.destination) == null ? void 0 : o.cityName} - ${(r = e.destination) == null ? void 0 : r.stateName} - ${(s = e.destination) == null ? void 0 : s.countryName} - ` || ""), t.searchParams.set("startDate", m(e.date).format("YYYY-MM-DD") || ""), (n = e.destination) != null && n.provider && t.searchParams.set("provider", (d = e.destination) == null ? void 0 : d.provider), t.searchParams.set("language", "pt-br"), h(t);
}
export {
  P as default
};
