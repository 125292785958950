"use client";
import e from "react";
import { FormContainer as t, CollapsableEngineButton as o } from "@cvccorp-engines/components";
import a from "../../context/useEngineCarContext.js";
import n from "../LocationAndDate/LocationAndDate.js";
import { MotorContainer as r, CancelButtonContainer as m, ButtonContainer as i } from "./Form.styled.js";
function c() {
  const {
    handleSearch: l
  } = a();
  return /* @__PURE__ */ e.createElement(t, { formId: "form-car", onSearch: l, renderMobile: () => /* @__PURE__ */ e.createElement(t.Mobile, { title: "Carro", label: "Destino" }, /* @__PURE__ */ e.createElement(r, null, /* @__PURE__ */ e.createElement(n, null))), renderDesktop: () => /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement(m, { container: !0, justifyContent: "flex-end" }, /* @__PURE__ */ e.createElement(o, null)), /* @__PURE__ */ e.createElement(r, null, /* @__PURE__ */ e.createElement(n, null), /* @__PURE__ */ e.createElement(i, null, /* @__PURE__ */ e.createElement(t.Submit, null)))) });
}
c.displayName = "SingleDestinationForm";
export {
  c as default
};
