import { getBaseHostname as st, encodeSearchURL as it } from "@cvccorp-engines/components";
var P = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function Q(y) {
  return y && y.__esModule && Object.prototype.hasOwnProperty.call(y, "default") ? y.default : y;
}
var X = { exports: {} };
(function(y, Y) {
  (function(D, l) {
    y.exports = l();
  })(P, function() {
    var D = 1e3, l = 6e4, u = 36e5, S = "millisecond", M = "second", m = "minute", g = "hour", b = "day", C = "week", c = "month", a = "quarter", $ = "year", f = "date", z = "Invalid Date", F = /^(\d{4})[-/]?(\d{1,2})?[-/]?(\d{0,2})[Tt\s]*(\d{1,2})?:?(\d{1,2})?:?(\d{1,2})?[.:]?(\d+)?$/, T = /\[([^\]]+)]|Y{1,4}|M{1,4}|D{1,2}|d{1,4}|H{1,2}|h{1,2}|a|A|m{1,2}|s{1,2}|Z{1,2}|SSS/g, R = {
      name: "en",
      weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"),
      months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"),
      ordinal: function(t) {
        var e = ["th", "st", "nd", "rd"], r = t % 100;
        return "[" + t + (e[(r - 20) % 10] || e[r] || e[0]) + "]";
      }
    }, I = function(t, e, r) {
      var n = String(t);
      return !n || n.length >= e ? t : "" + Array(e + 1 - n.length).join(r) + t;
    }, U = {
      s: I,
      z: function(t) {
        var e = -t.utcOffset(), r = Math.abs(e), n = Math.floor(r / 60), s = r % 60;
        return (e <= 0 ? "+" : "-") + I(n, 2, "0") + ":" + I(s, 2, "0");
      },
      m: function d(t, e) {
        if (t.date() < e.date()) return -d(e, t);
        var r = 12 * (e.year() - t.year()) + (e.month() - t.month()), n = t.clone().add(r, c), s = e - n < 0, i = t.clone().add(r + (s ? -1 : 1), c);
        return +(-(r + (e - n) / (s ? n - i : i - n)) || 0);
      },
      a: function(t) {
        return t < 0 ? Math.ceil(t) || 0 : Math.floor(t);
      },
      p: function(t) {
        return {
          M: c,
          y: $,
          w: C,
          d: b,
          D: f,
          h: g,
          m,
          s: M,
          ms: S,
          Q: a
        }[t] || String(t || "").toLowerCase().replace(/s$/, "");
      },
      u: function(t) {
        return t === void 0;
      }
    }, W = "en", x = {};
    x[W] = R;
    var q = "$isDayjsObject", G = function(t) {
      return t instanceof N || !(!t || !t[q]);
    }, E = function d(t, e, r) {
      var n;
      if (!t) return W;
      if (typeof t == "string") {
        var s = t.toLowerCase();
        x[s] && (n = s), e && (x[s] = e, n = s);
        var i = t.split("-");
        if (!n && i.length > 1) return d(i[0]);
      } else {
        var h = t.name;
        x[h] = t, n = h;
      }
      return !r && n && (W = n), n || !r && W;
    }, w = function(t, e) {
      if (G(t)) return t.clone();
      var r = typeof e == "object" ? e : {};
      return r.date = t, r.args = arguments, new N(r);
    }, o = U;
    o.l = E, o.i = G, o.w = function(d, t) {
      return w(d, {
        locale: t.$L,
        utc: t.$u,
        x: t.$x,
        $offset: t.$offset
      });
    };
    var N = function() {
      function d(e) {
        this.$L = E(e.locale, null, !0), this.parse(e), this.$x = this.$x || e.x || {}, this[q] = !0;
      }
      var t = d.prototype;
      return t.parse = function(e) {
        this.$d = function(r) {
          var n = r.date, s = r.utc;
          if (n === null) return /* @__PURE__ */ new Date(NaN);
          if (o.u(n)) return /* @__PURE__ */ new Date();
          if (n instanceof Date) return new Date(n);
          if (typeof n == "string" && !/Z$/i.test(n)) {
            var i = n.match(F);
            if (i) {
              var h = i[2] - 1 || 0, p = (i[7] || "0").substring(0, 3);
              return s ? new Date(Date.UTC(i[1], h, i[3] || 1, i[4] || 0, i[5] || 0, i[6] || 0, p)) : new Date(i[1], h, i[3] || 1, i[4] || 0, i[5] || 0, i[6] || 0, p);
            }
          }
          return new Date(n);
        }(e), this.init();
      }, t.init = function() {
        var e = this.$d;
        this.$y = e.getFullYear(), this.$M = e.getMonth(), this.$D = e.getDate(), this.$W = e.getDay(), this.$H = e.getHours(), this.$m = e.getMinutes(), this.$s = e.getSeconds(), this.$ms = e.getMilliseconds();
      }, t.$utils = function() {
        return o;
      }, t.isValid = function() {
        return this.$d.toString() !== z;
      }, t.isSame = function(e, r) {
        var n = w(e);
        return this.startOf(r) <= n && n <= this.endOf(r);
      }, t.isAfter = function(e, r) {
        return w(e) < this.startOf(r);
      }, t.isBefore = function(e, r) {
        return this.endOf(r) < w(e);
      }, t.$g = function(e, r, n) {
        return o.u(e) ? this[r] : this.set(n, e);
      }, t.unix = function() {
        return Math.floor(this.valueOf() / 1e3);
      }, t.valueOf = function() {
        return this.$d.getTime();
      }, t.startOf = function(e, r) {
        var n = this, s = !!o.u(r) || r, i = o.p(e), h = function(H, k) {
          var j = o.w(n.$u ? Date.UTC(n.$y, k, H) : new Date(n.$y, k, H), n);
          return s ? j : j.endOf(b);
        }, p = function(H, k) {
          return o.w(n.toDate()[H].apply(n.toDate("s"), (s ? [0, 0, 0, 0] : [23, 59, 59, 999]).slice(k)), n);
        }, _ = this.$W, v = this.$M, O = this.$D, L = "set" + (this.$u ? "UTC" : "");
        switch (i) {
          case $:
            return s ? h(1, 0) : h(31, 11);
          case c:
            return s ? h(1, v) : h(0, v + 1);
          case C:
            var A = this.$locale().weekStart || 0, B = (_ < A ? _ + 7 : _) - A;
            return h(s ? O - B : O + (6 - B), v);
          case b:
          case f:
            return p(L + "Hours", 0);
          case g:
            return p(L + "Minutes", 1);
          case m:
            return p(L + "Seconds", 2);
          case M:
            return p(L + "Milliseconds", 3);
          default:
            return this.clone();
        }
      }, t.endOf = function(e) {
        return this.startOf(e, !1);
      }, t.$set = function(e, r) {
        var n, s = o.p(e), i = "set" + (this.$u ? "UTC" : ""), h = (n = {}, n[b] = i + "Date", n[f] = i + "Date", n[c] = i + "Month", n[$] = i + "FullYear", n[g] = i + "Hours", n[m] = i + "Minutes", n[M] = i + "Seconds", n[S] = i + "Milliseconds", n)[s], p = s === b ? this.$D + (r - this.$W) : r;
        if (s === c || s === $) {
          var _ = this.clone().set(f, 1);
          _.$d[h](p), _.init(), this.$d = _.set(f, Math.min(this.$D, _.daysInMonth())).$d;
        } else h && this.$d[h](p);
        return this.init(), this;
      }, t.set = function(e, r) {
        return this.clone().$set(e, r);
      }, t.get = function(e) {
        return this[o.p(e)]();
      }, t.add = function(e, r) {
        var n, s = this;
        e = Number(e);
        var i = o.p(r), h = function(O) {
          var L = w(s);
          return o.w(L.date(L.date() + Math.round(O * e)), s);
        };
        if (i === c) return this.set(c, this.$M + e);
        if (i === $) return this.set($, this.$y + e);
        if (i === b) return h(1);
        if (i === C) return h(7);
        var p = (n = {}, n[m] = l, n[g] = u, n[M] = D, n)[i] || 1, _ = this.$d.getTime() + e * p;
        return o.w(_, this);
      }, t.subtract = function(e, r) {
        return this.add(-1 * e, r);
      }, t.format = function(e) {
        var r = this, n = this.$locale();
        if (!this.isValid()) return n.invalidDate || z;
        var s = e || "YYYY-MM-DDTHH:mm:ssZ", i = o.z(this), h = this.$H, p = this.$m, _ = this.$M, v = n.weekdays, O = n.months, L = n.meridiem, A = function(k, j, Z, rt) {
          return k && (k[j] || k(r, s)) || Z[j].slice(0, rt);
        }, B = function(k) {
          return o.s(h % 12 || 12, k, "0");
        }, J = L || function(H, k, j) {
          var Z = H < 12 ? "AM" : "PM";
          return j ? Z.toLowerCase() : Z;
        };
        return s.replace(T, function(H, k) {
          return k || function(j) {
            switch (j) {
              case "YY":
                return String(r.$y).slice(-2);
              case "YYYY":
                return o.s(r.$y, 4, "0");
              case "M":
                return _ + 1;
              case "MM":
                return o.s(_ + 1, 2, "0");
              case "MMM":
                return A(n.monthsShort, _, O, 3);
              case "MMMM":
                return A(O, _);
              case "D":
                return r.$D;
              case "DD":
                return o.s(r.$D, 2, "0");
              case "d":
                return String(r.$W);
              case "dd":
                return A(n.weekdaysMin, r.$W, v, 2);
              case "ddd":
                return A(n.weekdaysShort, r.$W, v, 3);
              case "dddd":
                return v[r.$W];
              case "H":
                return String(h);
              case "HH":
                return o.s(h, 2, "0");
              case "h":
                return B(1);
              case "hh":
                return B(2);
              case "a":
                return J(h, p, !0);
              case "A":
                return J(h, p, !1);
              case "m":
                return String(p);
              case "mm":
                return o.s(p, 2, "0");
              case "s":
                return String(r.$s);
              case "ss":
                return o.s(r.$s, 2, "0");
              case "SSS":
                return o.s(r.$ms, 3, "0");
              case "Z":
                return i;
            }
            return null;
          }(H) || i.replace(":", "");
        });
      }, t.utcOffset = function() {
        return 15 * -Math.round(this.$d.getTimezoneOffset() / 15);
      }, t.diff = function(e, r, n) {
        var s, i = this, h = o.p(r), p = w(e), _ = (p.utcOffset() - this.utcOffset()) * l, v = this - p, O = function() {
          return o.m(i, p);
        };
        switch (h) {
          case $:
            s = O() / 12;
            break;
          case c:
            s = O();
            break;
          case a:
            s = O() / 3;
            break;
          case C:
            s = (v - _) / 6048e5;
            break;
          case b:
            s = (v - _) / 864e5;
            break;
          case g:
            s = v / u;
            break;
          case m:
            s = v / l;
            break;
          case M:
            s = v / D;
            break;
          default:
            s = v;
        }
        return n ? s : o.a(s);
      }, t.daysInMonth = function() {
        return this.endOf(c).$D;
      }, t.$locale = function() {
        return x[this.$L];
      }, t.locale = function(e, r) {
        if (!e) return this.$L;
        var n = this.clone(), s = E(e, r, !0);
        return s && (n.$L = s), n;
      }, t.clone = function() {
        return o.w(this.$d, this);
      }, t.toDate = function() {
        return new Date(this.valueOf());
      }, t.toJSON = function() {
        return this.isValid() ? this.toISOString() : null;
      }, t.toISOString = function() {
        return this.$d.toISOString();
      }, t.toString = function() {
        return this.$d.toUTCString();
      }, d;
    }(), K = N.prototype;
    return w.prototype = K, [["$ms", S], ["$s", M], ["$m", m], ["$H", g], ["$W", b], ["$M", c], ["$y", $], ["$D", f]].forEach(function(d) {
      K[d[1]] = function(t) {
        return this.$g(t, d[0], d[1]);
      };
    }), w.extend = function(d, t) {
      return d.$i || (d(t, N, w), d.$i = !0), w;
    }, w.locale = E, w.isDayjs = G, w.unix = function(d) {
      return w(1e3 * d);
    }, w.en = x[W], w.Ls = x, w.p = {}, w;
  });
})(X);
var tt = X.exports;
const V = /* @__PURE__ */ Q(tt);
var at = { exports: {} };
(function(y, Y) {
  (function(D, l) {
    y.exports = l();
  })(P, function() {
    return function(D, l) {
      l.prototype.isSameOrAfter = function(u, S) {
        return this.isSame(u, S) || this.isAfter(u, S);
      };
    };
  });
})(at);
var et = { exports: {} };
(function(y, Y) {
  (function(D, l) {
    y.exports = l();
  })(P, function() {
    return function(D, l) {
      l.prototype.isSameOrBefore = function(u, S) {
        return this.isSame(u, S) || this.isBefore(u, S);
      };
    };
  });
})(et);
var ot = et.exports;
const ut = /* @__PURE__ */ Q(ot);
var nt = { exports: {} };
(function(y, Y) {
  (function(D, l) {
    y.exports = l();
  })(P, function() {
    return function(D, l, u) {
      var S = l.prototype, M = function(a) {
        return a && (a.indexOf ? a : a.s);
      }, m = function(a, $, f, z, F) {
        var T = a.name ? a : a.$locale(), R = M(T[$]), I = M(T[f]), U = R || I.map(function(x) {
          return x.slice(0, z);
        });
        if (!F) return U;
        var W = T.weekStart;
        return U.map(function(x, q) {
          return U[(q + (W || 0)) % 7];
        });
      }, g = function() {
        return u.Ls[u.locale()];
      }, b = function(a, $) {
        return a.formats[$] || function(f) {
          return f.replace(/(\[[^\]]+])|(MMMM|MM|DD|dddd)/g, function(z, F, T) {
            return F || T.slice(1);
          });
        }(a.formats[$.toUpperCase()]);
      }, C = function() {
        var a = this;
        return {
          months: function(f) {
            return f ? f.format("MMMM") : m(a, "months");
          },
          monthsShort: function(f) {
            return f ? f.format("MMM") : m(a, "monthsShort", "months", 3);
          },
          firstDayOfWeek: function() {
            return a.$locale().weekStart || 0;
          },
          weekdays: function(f) {
            return f ? f.format("dddd") : m(a, "weekdays");
          },
          weekdaysMin: function(f) {
            return f ? f.format("dd") : m(a, "weekdaysMin", "weekdays", 2);
          },
          weekdaysShort: function(f) {
            return f ? f.format("ddd") : m(a, "weekdaysShort", "weekdays", 3);
          },
          longDateFormat: function(f) {
            return b(a.$locale(), f);
          },
          meridiem: this.$locale().meridiem,
          ordinal: this.$locale().ordinal
        };
      };
      S.localeData = function() {
        return C.bind(this)();
      }, u.localeData = function() {
        var c = g();
        return {
          firstDayOfWeek: function() {
            return c.weekStart || 0;
          },
          weekdays: function() {
            return u.weekdays();
          },
          weekdaysShort: function() {
            return u.weekdaysShort();
          },
          weekdaysMin: function() {
            return u.weekdaysMin();
          },
          months: function() {
            return u.months();
          },
          monthsShort: function() {
            return u.monthsShort();
          },
          longDateFormat: function($) {
            return b(c, $);
          },
          meridiem: c.meridiem,
          ordinal: c.ordinal
        };
      }, u.months = function() {
        return m(g(), "months");
      }, u.monthsShort = function() {
        return m(g(), "monthsShort", "months", 3);
      }, u.weekdays = function(c) {
        return m(g(), "weekdays", null, null, c);
      }, u.weekdaysShort = function(c) {
        return m(g(), "weekdaysShort", "weekdays", 3, c);
      }, u.weekdaysMin = function(c) {
        return m(g(), "weekdaysMin", "weekdays", 2, c);
      };
    };
  });
})(nt);
var ct = nt.exports;
const ft = /* @__PURE__ */ Q(ct);
var dt = { exports: {} };
(function(y, Y) {
  (function(D, l) {
    y.exports = l(tt);
  })(P, function(D) {
    function l(M) {
      return M && typeof M == "object" && "default" in M ? M : {
        default: M
      };
    }
    var u = l(D), S = {
      name: "pt-br",
      weekdays: "domingo_segunda-feira_terça-feira_quarta-feira_quinta-feira_sexta-feira_sábado".split("_"),
      weekdaysShort: "dom_seg_ter_qua_qui_sex_sáb".split("_"),
      weekdaysMin: "Do_2ª_3ª_4ª_5ª_6ª_Sá".split("_"),
      months: "janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro".split("_"),
      monthsShort: "jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez".split("_"),
      ordinal: function(m) {
        return m + "º";
      },
      formats: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD/MM/YYYY",
        LL: "D [de] MMMM [de] YYYY",
        LLL: "D [de] MMMM [de] YYYY [às] HH:mm",
        LLLL: "dddd, D [de] MMMM [de] YYYY [às] HH:mm"
      },
      relativeTime: {
        future: "em %s",
        past: "há %s",
        s: "poucos segundos",
        m: "um minuto",
        mm: "%d minutos",
        h: "uma hora",
        hh: "%d horas",
        d: "um dia",
        dd: "%d dias",
        M: "um mês",
        MM: "%d meses",
        y: "um ano",
        yy: "%d anos"
      }
    };
    return u.default.locale(S, null, !0), S;
  });
})(dt);
V.locale("pt-br");
V.extend(ut);
V.extend(ft);
function lt(y = {}) {
  var S, M;
  const Y = new URL(`${st()}/tours/search`), D = ((S = y.destinations) == null ? void 0 : S.map((m) => m.zoneId).join(",")) || "", l = ((M = y.destinations) == null ? void 0 : M.map((m) => m.description).join(",")) || "", u = y.date ? V(y.date).format("YYYY-MM") : "";
  return u && Y.searchParams.append("date", u), D && Y.searchParams.append("zoneIds", D), l && Y.searchParams.append("descriptions", l), y.allDestiny && Y.searchParams.append("allDestiny", String(y.allDestiny)), it(Y);
}
export {
  V as d,
  lt as f
};
