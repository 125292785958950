import s from "react";
import { InputDatePickerRange as D } from "@cvccorp-engines/components";
import { useEngineBusingCharterContext as g } from "../../../context/EngineBusingCharterContext.js";
const p = ({
  minDate: i,
  maxDate: c
}) => {
  var e, t, n, d;
  const {
    setCalendarDateSelected: l,
    calendarDateSelected: o,
    errors: a
  } = g(), m = ((e = a == null ? void 0 : a.dates) == null ? void 0 : e.startDate) || ((t = a == null ? void 0 : a.dates) == null ? void 0 : t.endDate);
  return /* @__PURE__ */ s.createElement(D, { value: o, onChange: (r) => {
    l(r);
  }, error: m ? {
    startDate: (n = a == null ? void 0 : a.dates) != null && n.startDate ? "Preencha a data de ida" : void 0,
    endDate: (d = a == null ? void 0 : a.dates) != null && d.endDate ? "Preencha a data de volta" : void 0
  } : void 0, min: i, max: c });
};
p.displayName = "CalendarRangePicker";
export {
  p as default
};
