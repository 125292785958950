import n from "react";
import { InputDatePickerRange as b, CalendarDatePickerContent as v } from "@cvccorp-engines/components";
import { u as w, b as m } from "../../../EngineBookingPackagesContext-D4tagYeS.js";
import "../CalendarMonthPicker/CalendarMonthPicker.js";
import E from "../CalendarMonthPicker/CalendarMonthPickerSwitch.js";
const x = ({
  minDate: u,
  maxDate: D
}) => {
  var l, o, r, s;
  const {
    setCalendarDateSelected: d,
    calendarDateSelected: a,
    errors: e,
    calendarIsOpen: p,
    setCalendarIsOpen: f
  } = w(), C = ((l = e == null ? void 0 : e.dates) == null ? void 0 : l.startDate) || ((o = e == null ? void 0 : e.dates) == null ? void 0 : o.endDate), P = (g) => {
    var i;
    if (((i = a.filter((t) => t)) == null ? void 0 : i.length) === 2) return !1;
    if (a[0]) {
      const t = m(a[0]), c = m(g), h = t.add(29, "day"), k = t.subtract(29, "day");
      return c.isAfter(h) || c.isBefore(k);
    }
    return !1;
  };
  return /* @__PURE__ */ n.createElement(b, { value: a, onChange: d, content: /* @__PURE__ */ n.createElement(v, null), showRangeCount: !0, datePickerProps: {
    renderAddonBefore: () => /* @__PURE__ */ n.createElement(E, null)
  }, min: u, max: D, inputOneProps: {
    label: "Ida"
  }, inputTwoProps: {
    label: "Volta"
  }, error: C ? {
    startDate: (r = e == null ? void 0 : e.dates) != null && r.startDate ? "Preencha a data de ida" : void 0,
    endDate: (s = e == null ? void 0 : e.dates) != null && s.endDate ? "Preencha a data de volta" : void 0
  } : void 0, onClear: () => d([]), disableDates: P, openCalendar: p, onToggle: f });
};
x.displayName = "CalendarRangePicker";
export {
  x as default
};
