import l from "react";
import { Pesqinc as m } from "@cvccorp-engines/components";
import { useEngineBusingCharterContext as u } from "../../../context/EngineBusingCharterContext.js";
import g from "./useFetchSingleBusingCharter.js";
const p = ({
  searchType: e,
  isUniqueDirection: t,
  ...n
}) => {
  const {
    pesqincLocationSelected: o,
    setPesqincLocationSelected: r
  } = u(), {
    options: i,
    onSearch: s,
    renderOption: c,
    onSelect: a
  } = g({
    searchType: e,
    isUniqueDirection: t,
    onSelect: r
  });
  return /* @__PURE__ */ l.createElement(m, { ...n, onSearch: s, options: i, renderOption: c, onSelect: a, value: o[e.toLowerCase()] });
};
p.displayName = "SingleBusingCharter";
export {
  p as default
};
