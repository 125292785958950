import { useMemo as t } from "react";
import { useEngineSearchHistory as e, LocalStorageKeys as s } from "@cvccorp-engines/components";
import { d as a } from "../../dayjs-CxYy674R.js";
const y = () => {
  const r = e(s.LAST_SEARCH_TICKETS);
  return t(() => ({
    ...r,
    searchHistory: r.searchHistory.map((o) => ({
      ...o,
      date: a(o.date).toDate()
    }))
  }), [r]);
};
export {
  y as default
};
