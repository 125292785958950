import A, { createContext as g, useCallback as u, useMemo as C } from "react";
import { useIsomorphicLayoutEffect as M } from "@cvccorp-components/chui-react-components";
import { gtm as _, redirectToSearch as N, DateFormat as x } from "@cvccorp-engines/components";
import { d as s } from "../../dayjs-CxYy674R.js";
import { validateChildrenPaxDate as P } from "@cvccorp-engines/pax";
import B from "../../hooks/useHotelSearchHistory/useHotelSearchHistory.js";
import { useHotelStore as O } from "../../store/HotelStore/HotelStore.js";
import { formatStateToGTM as R } from "../../utils/formatStateToGTM.js";
import I from "../../utils/formatUrlToSearch.js";
const L = g(void 0), S = (o) => ({
  startDate: s(o.startDate).format(x.YEAR_MONTH_DATE_TIMEZONE),
  endDate: s(o.endDate).format(x.YEAR_MONTH_DATE_TIMEZONE)
});
function U(o) {
  var D, f;
  const {
    setErrors: i,
    setDestination: T,
    setCalendarDates: H,
    setPax: v,
    ...t
  } = O(), {
    addValue: c,
    searchHistory: l
  } = B();
  M(() => {
    if (!t.destination && l.length) {
      const {
        calendarDates: e,
        destination: a,
        pax: r
      } = l[0];
      T(a), v(r), s(/* @__PURE__ */ new Date()).isAfter(e.startDate) || H(S(e));
    }
  }, []);
  const d = u(() => {
    var E, h;
    let e = !1;
    const a = !t.pax || P(t.pax)[0], r = !t.destination, n = !((E = t.calendarDates) != null && E.endDate), p = !((h = t.calendarDates) != null && h.startDate);
    return e = r || n || p || a, [e, {
      destination: r,
      pax: a,
      calendarDates: {
        endDate: n,
        startDate: p
      }
    }];
  }, [(D = t.calendarDates) == null ? void 0 : D.endDate, (f = t.calendarDates) == null ? void 0 : f.startDate, t.destination, t.pax]), m = u(() => {
    const [e, a] = d();
    if (i({
      ...a
    }), e)
      return;
    c({
      calendarDates: S(t.calendarDates),
      destination: t.destination,
      pax: t.pax
    }), _.onEngineSearch(R(t));
    const r = I(t).replaceAll("+,+", ", ").replaceAll("+", " ");
    N(r);
  }, [d, i, c, t]), y = C(() => ({
    handleSearch: m
  }), [m]);
  return /* @__PURE__ */ A.createElement(L.Provider, { ...o, value: y });
}
U.displayName = "EngineHotelProvider";
export {
  L as EngineHotelContext,
  U as default
};
