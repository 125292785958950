"use client";
import r, { useCallback as h } from "react";
import k from "@cvccorp-components/chui-react-components/components/Carousel/CarouselShelf";
import v from "@cvccorp-components/chui-react-components/components/Chip";
import y from "@cvccorp-components/chui-react-components/components/Radio";
import { useTheme as E } from "@emotion/react";
import { Container as C, Link as b } from "./OptionsBar.styles.js";
const g = (m) => {
  const {
    checked: a,
    value: o,
    children: c,
    variant: n
  } = m;
  return n === "link" ? /* @__PURE__ */ r.createElement(b, { disabled: a, href: o, key: o, "aria-current": a ? "page" : void 0 }, c) : /* @__PURE__ */ r.createElement(r.Fragment, null, c);
};
function B(m) {
  const {
    options: a,
    value: o,
    variant: c,
    onChange: n,
    isChecked: s,
    component: u = "chip",
    className: f = ""
  } = m, {
    name: d
  } = E(), p = h((i, l) => {
    i || n == null || n(l);
  }, [n]);
  return /* @__PURE__ */ r.createElement(C, { className: f }, /* @__PURE__ */ r.createElement(k, null, a.map((i) => {
    const {
      label: l,
      value: e
    } = i, t = (s == null ? void 0 : s(i)) || o === e;
    return u === "radio" ? /* @__PURE__ */ r.createElement(y, { name: e, onClick: () => p(t, e), checked: t, color: "primary", size: "md", key: e, label: l }) : /* @__PURE__ */ r.createElement(g, { checked: t, value: e, key: e, variant: c }, /* @__PURE__ */ r.createElement(v, { onClick: () => p(t, e), checked: t, color: d === "cvc" ? "secondary" : "primary", variant: "filter", key: e }, l));
  })));
}
B.displayName = "OptionsBar";
export {
  B as default
};
