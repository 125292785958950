import { GTMProducts as d, gtm as a } from "@cvccorp-engines/components";
function m(t) {
  var e, n, o, c;
  const i = {
    product: d.BUSING_CHARTER
  };
  return t.searchType === "originAndDestination" && Object.assign(i, {
    checkin_date: a.formatDate(t.calendarDates[0]),
    origin_city: (e = t.origin.city) == null ? void 0 : e.name,
    destination_city: (n = t.destination.city) == null ? void 0 : n.name
  }), t.searchType === "origin" && Object.assign(i, {
    origin_city: (o = t.origin.city) == null ? void 0 : o.name,
    checkin_date: a.formatDate(t.calendarDates[0]),
    checkout_date: a.formatDate(t.calendarDates[1])
  }), t.searchType === "destination" && Object.assign(i, {
    destination_city: (c = t.destination.city) == null ? void 0 : c.name,
    checkin_date: a.formatDate(t.calendarDates[0]),
    checkout_date: a.formatDate(t.calendarDates[1])
  }), t.searchType === "excursion" && Object.assign(i, {
    tourCode: t.code
  }), i;
}
export {
  m as default
};
