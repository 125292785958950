function t(r) {
  const a = new URL(r, "http://example.com"), n = new URLSearchParams(a.search), e = {};
  return n.forEach((o, c) => {
    e[c] = o;
  }), e;
}
function s(r) {
  const a = new URL(r, window.location.origin), n = new URLSearchParams(a.search), e = new URL(window.location.href);
  n.forEach((o, c) => {
    e.searchParams.set(c, o);
  }), window.history.replaceState(null, "", e.toString());
}
function i(r) {
  return String(process.env.DEBUGGER) === "true" ? (console.log(r), console.log(t(r)), s(r)) : window.open(r, "_self");
}
export {
  i as default
};
