"use client";
import e from "react";
import { FormContainer as t, CollapsableEngineButton as m } from "@cvccorp-engines/components";
import { u as i } from "../../EngineBookingPackagesContext-D4tagYeS.js";
import { OptionsContainer as c } from "../../EngineBookingPackages.styles.js";
import n from "../DefaultLocationForm/DefaultLocationForm.js";
import r from "../SearchOptions/SearchOptions.js";
import { MotorContainer as l, Grid as u, ButtonContainer as s } from "./Form.styled.js";
function p() {
  const {
    handleSearch: o,
    searchType: a
  } = i();
  return /* @__PURE__ */ e.createElement(c, null, /* @__PURE__ */ e.createElement(t, { formId: "form-booking-packages", onSearch: o, renderMobile: () => /* @__PURE__ */ e.createElement(t.Mobile, { title: "Pacotes", label: a === "origin" ? "Origem" : "Destino" }, /* @__PURE__ */ e.createElement(r, null), /* @__PURE__ */ e.createElement(l, null, /* @__PURE__ */ e.createElement(n, null))), renderDesktop: () => /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement(u, { container: !0, justifyContent: "space-between", alignItems: "center" }, /* @__PURE__ */ e.createElement(r, null), /* @__PURE__ */ e.createElement(m, null)), /* @__PURE__ */ e.createElement(l, null, /* @__PURE__ */ e.createElement(n, null), /* @__PURE__ */ e.createElement(s, null, /* @__PURE__ */ e.createElement(t.Submit, null)))) }));
}
p.displayName = "Form";
export {
  p as default
};
