import c from "react";
import { Input as a } from "@cvccorp-components/chui-react-components";
import { useEngineBusingCharterContext as i } from "../../../context/EngineBusingCharterContext.js";
const p = {
  excursion: {
    label: "Excursão",
    placeholder: "Digite o código",
    mask: "##########"
  }
}, u = () => {
  const {
    searchType: t,
    setPesqincInputValue: o,
    errors: e,
    pesqincInputValue: r
  } = i(), s = p[t] || {};
  return /* @__PURE__ */ c.createElement(a, { fullWidth: !0, size: "md", onChange: (n) => o(n.target.value), supportText: e.code ? "Preencha o código de excursão" : void 0, status: e.code ? "danger" : void 0, value: r, ...s });
};
u.displayName = "PesqincInput";
export {
  u as default
};
