import e, { memo as i } from "react";
import { ErrorProvider as m } from "@cvccorp-engines/components";
import a from "../context/EngineSearchProvider/EngineSearchProvider.js";
import E from "./EngineWrapper.js";
const r = (n) => {
  const {
    onError: o,
    renderNavigation: t
  } = n;
  return /* @__PURE__ */ e.createElement(m, { onError: o }, /* @__PURE__ */ e.createElement(a, { renderNavigation: t }, /* @__PURE__ */ e.createElement(E, null)));
};
r.displayName = "Engine";
const d = i(r);
export {
  d as default
};
