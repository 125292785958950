import ct, { createContext as dt, useState as W, useMemo as nt, useCallback as ft, useContext as lt } from "react";
import { useIsomorphicLayoutEffect as ht } from "@cvccorp-components/chui-react-components";
import { getParams as d, useParsedLocalStorage as mt, LocalStorageKeys as gt, redirectToSearch as pt } from "@cvccorp-engines/components";
import { formatPaxForService as yt, validateChildrenPaxDate as Dt } from "@cvccorp-engines/pax";
import $t from "./utils/formatUrlToSearch.js";
import { getParamsPackageV2 as J } from "./utils/getParamsPackageV2.js";
var Q = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function et(M) {
  return M && M.__esModule && Object.prototype.hasOwnProperty.call(M, "default") ? M.default : M;
}
var rt = { exports: {} };
(function(M, F) {
  (function(w, p) {
    M.exports = p();
  })(Q, function() {
    var w = 1e3, p = 6e4, a = 36e5, O = "millisecond", $ = "second", S = "minute", x = "hour", T = "day", E = "week", g = "month", u = "quarter", D = "year", f = "date", U = "Invalid Date", b = /^(\d{4})[-/]?(\d{1,2})?[-/]?(\d{0,2})[Tt\s]*(\d{1,2})?:?(\d{1,2})?:?(\d{1,2})?[.:]?(\d+)?$/, L = /\[([^\]]+)]|Y{1,4}|M{1,4}|D{1,2}|d{1,4}|H{1,2}|h{1,2}|a|A|m{1,2}|s{1,2}|Z{1,2}|SSS/g, q = {
      name: "en",
      weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"),
      months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"),
      ordinal: function(t) {
        var e = ["th", "st", "nd", "rd"], r = t % 100;
        return "[" + t + (e[(r - 20) % 10] || e[r] || e[0]) + "]";
      }
    }, z = function(t, e, r) {
      var n = String(t);
      return !n || n.length >= e ? t : "" + Array(e + 1 - n.length).join(r) + t;
    }, B = {
      s: z,
      z: function(t) {
        var e = -t.utcOffset(), r = Math.abs(e), n = Math.floor(r / 60), i = r % 60;
        return (e <= 0 ? "+" : "-") + z(n, 2, "0") + ":" + z(i, 2, "0");
      },
      m: function l(t, e) {
        if (t.date() < e.date()) return -l(e, t);
        var r = 12 * (e.year() - t.year()) + (e.month() - t.month()), n = t.clone().add(r, g), i = e - n < 0, o = t.clone().add(r + (i ? -1 : 1), g);
        return +(-(r + (e - n) / (i ? n - o : o - n)) || 0);
      },
      a: function(t) {
        return t < 0 ? Math.ceil(t) || 0 : Math.floor(t);
      },
      p: function(t) {
        return {
          M: g,
          y: D,
          w: E,
          d: T,
          D: f,
          h: x,
          m: S,
          s: $,
          ms: O,
          Q: u
        }[t] || String(t || "").toLowerCase().replace(/s$/, "");
      },
      u: function(t) {
        return t === void 0;
      }
    }, P = "en", C = {};
    C[P] = q;
    var V = "$isDayjsObject", G = function(t) {
      return t instanceof Z || !(!t || !t[V]);
    }, R = function l(t, e, r) {
      var n;
      if (!t) return P;
      if (typeof t == "string") {
        var i = t.toLowerCase();
        C[i] && (n = i), e && (C[i] = e, n = i);
        var o = t.split("-");
        if (!n && o.length > 1) return l(o[0]);
      } else {
        var s = t.name;
        C[s] = t, n = s;
      }
      return !r && n && (P = n), n || !r && P;
    }, v = function(t, e) {
      if (G(t)) return t.clone();
      var r = typeof e == "object" ? e : {};
      return r.date = t, r.args = arguments, new Z(r);
    }, h = B;
    h.l = R, h.i = G, h.w = function(l, t) {
      return v(l, {
        locale: t.$L,
        utc: t.$u,
        x: t.$x,
        $offset: t.$offset
      });
    };
    var Z = function() {
      function l(e) {
        this.$L = R(e.locale, null, !0), this.parse(e), this.$x = this.$x || e.x || {}, this[V] = !0;
      }
      var t = l.prototype;
      return t.parse = function(e) {
        this.$d = function(r) {
          var n = r.date, i = r.utc;
          if (n === null) return /* @__PURE__ */ new Date(NaN);
          if (h.u(n)) return /* @__PURE__ */ new Date();
          if (n instanceof Date) return new Date(n);
          if (typeof n == "string" && !/Z$/i.test(n)) {
            var o = n.match(b);
            if (o) {
              var s = o[2] - 1 || 0, c = (o[7] || "0").substring(0, 3);
              return i ? new Date(Date.UTC(o[1], s, o[3] || 1, o[4] || 0, o[5] || 0, o[6] || 0, c)) : new Date(o[1], s, o[3] || 1, o[4] || 0, o[5] || 0, o[6] || 0, c);
            }
          }
          return new Date(n);
        }(e), this.init();
      }, t.init = function() {
        var e = this.$d;
        this.$y = e.getFullYear(), this.$M = e.getMonth(), this.$D = e.getDate(), this.$W = e.getDay(), this.$H = e.getHours(), this.$m = e.getMinutes(), this.$s = e.getSeconds(), this.$ms = e.getMilliseconds();
      }, t.$utils = function() {
        return h;
      }, t.isValid = function() {
        return this.$d.toString() !== U;
      }, t.isSame = function(e, r) {
        var n = v(e);
        return this.startOf(r) <= n && n <= this.endOf(r);
      }, t.isAfter = function(e, r) {
        return v(e) < this.startOf(r);
      }, t.isBefore = function(e, r) {
        return this.endOf(r) < v(e);
      }, t.$g = function(e, r, n) {
        return h.u(e) ? this[r] : this.set(n, e);
      }, t.unix = function() {
        return Math.floor(this.valueOf() / 1e3);
      }, t.valueOf = function() {
        return this.$d.getTime();
      }, t.startOf = function(e, r) {
        var n = this, i = !!h.u(r) || r, o = h.p(e), s = function(Y, A) {
          var N = h.w(n.$u ? Date.UTC(n.$y, A, Y) : new Date(n.$y, A, Y), n);
          return i ? N : N.endOf(T);
        }, c = function(Y, A) {
          return h.w(n.toDate()[Y].apply(n.toDate("s"), (i ? [0, 0, 0, 0] : [23, 59, 59, 999]).slice(A)), n);
        }, m = this.$W, y = this.$M, k = this.$D, _ = "set" + (this.$u ? "UTC" : "");
        switch (o) {
          case D:
            return i ? s(1, 0) : s(31, 11);
          case g:
            return i ? s(1, y) : s(0, y + 1);
          case E:
            var H = this.$locale().weekStart || 0, j = (m < H ? m + 7 : m) - H;
            return s(i ? k - j : k + (6 - j), y);
          case T:
          case f:
            return c(_ + "Hours", 0);
          case x:
            return c(_ + "Minutes", 1);
          case S:
            return c(_ + "Seconds", 2);
          case $:
            return c(_ + "Milliseconds", 3);
          default:
            return this.clone();
        }
      }, t.endOf = function(e) {
        return this.startOf(e, !1);
      }, t.$set = function(e, r) {
        var n, i = h.p(e), o = "set" + (this.$u ? "UTC" : ""), s = (n = {}, n[T] = o + "Date", n[f] = o + "Date", n[g] = o + "Month", n[D] = o + "FullYear", n[x] = o + "Hours", n[S] = o + "Minutes", n[$] = o + "Seconds", n[O] = o + "Milliseconds", n)[i], c = i === T ? this.$D + (r - this.$W) : r;
        if (i === g || i === D) {
          var m = this.clone().set(f, 1);
          m.$d[s](c), m.init(), this.$d = m.set(f, Math.min(this.$D, m.daysInMonth())).$d;
        } else s && this.$d[s](c);
        return this.init(), this;
      }, t.set = function(e, r) {
        return this.clone().$set(e, r);
      }, t.get = function(e) {
        return this[h.p(e)]();
      }, t.add = function(e, r) {
        var n, i = this;
        e = Number(e);
        var o = h.p(r), s = function(k) {
          var _ = v(i);
          return h.w(_.date(_.date() + Math.round(k * e)), i);
        };
        if (o === g) return this.set(g, this.$M + e);
        if (o === D) return this.set(D, this.$y + e);
        if (o === T) return s(1);
        if (o === E) return s(7);
        var c = (n = {}, n[S] = p, n[x] = a, n[$] = w, n)[o] || 1, m = this.$d.getTime() + e * c;
        return h.w(m, this);
      }, t.subtract = function(e, r) {
        return this.add(-1 * e, r);
      }, t.format = function(e) {
        var r = this, n = this.$locale();
        if (!this.isValid()) return n.invalidDate || U;
        var i = e || "YYYY-MM-DDTHH:mm:ssZ", o = h.z(this), s = this.$H, c = this.$m, m = this.$M, y = n.weekdays, k = n.months, _ = n.meridiem, H = function(A, N, tt, ut) {
          return A && (A[N] || A(r, i)) || tt[N].slice(0, ut);
        }, j = function(A) {
          return h.s(s % 12 || 12, A, "0");
        }, I = _ || function(Y, A, N) {
          var tt = Y < 12 ? "AM" : "PM";
          return N ? tt.toLowerCase() : tt;
        };
        return i.replace(L, function(Y, A) {
          return A || function(N) {
            switch (N) {
              case "YY":
                return String(r.$y).slice(-2);
              case "YYYY":
                return h.s(r.$y, 4, "0");
              case "M":
                return m + 1;
              case "MM":
                return h.s(m + 1, 2, "0");
              case "MMM":
                return H(n.monthsShort, m, k, 3);
              case "MMMM":
                return H(k, m);
              case "D":
                return r.$D;
              case "DD":
                return h.s(r.$D, 2, "0");
              case "d":
                return String(r.$W);
              case "dd":
                return H(n.weekdaysMin, r.$W, y, 2);
              case "ddd":
                return H(n.weekdaysShort, r.$W, y, 3);
              case "dddd":
                return y[r.$W];
              case "H":
                return String(s);
              case "HH":
                return h.s(s, 2, "0");
              case "h":
                return j(1);
              case "hh":
                return j(2);
              case "a":
                return I(s, c, !0);
              case "A":
                return I(s, c, !1);
              case "m":
                return String(c);
              case "mm":
                return h.s(c, 2, "0");
              case "s":
                return String(r.$s);
              case "ss":
                return h.s(r.$s, 2, "0");
              case "SSS":
                return h.s(r.$ms, 3, "0");
              case "Z":
                return o;
            }
            return null;
          }(Y) || o.replace(":", "");
        });
      }, t.utcOffset = function() {
        return 15 * -Math.round(this.$d.getTimezoneOffset() / 15);
      }, t.diff = function(e, r, n) {
        var i, o = this, s = h.p(r), c = v(e), m = (c.utcOffset() - this.utcOffset()) * p, y = this - c, k = function() {
          return h.m(o, c);
        };
        switch (s) {
          case D:
            i = k() / 12;
            break;
          case g:
            i = k();
            break;
          case u:
            i = k() / 3;
            break;
          case E:
            i = (y - m) / 6048e5;
            break;
          case T:
            i = (y - m) / 864e5;
            break;
          case x:
            i = y / a;
            break;
          case S:
            i = y / p;
            break;
          case $:
            i = y / w;
            break;
          default:
            i = y;
        }
        return n ? i : h.a(i);
      }, t.daysInMonth = function() {
        return this.endOf(g).$D;
      }, t.$locale = function() {
        return C[this.$L];
      }, t.locale = function(e, r) {
        if (!e) return this.$L;
        var n = this.clone(), i = R(e, r, !0);
        return i && (n.$L = i), n;
      }, t.clone = function() {
        return h.w(this.$d, this);
      }, t.toDate = function() {
        return new Date(this.valueOf());
      }, t.toJSON = function() {
        return this.isValid() ? this.toISOString() : null;
      }, t.toISOString = function() {
        return this.$d.toISOString();
      }, t.toString = function() {
        return this.$d.toUTCString();
      }, l;
    }(), X = Z.prototype;
    return v.prototype = X, [["$ms", O], ["$s", $], ["$m", S], ["$H", x], ["$W", T], ["$M", g], ["$y", D], ["$D", f]].forEach(function(l) {
      X[l[1]] = function(t) {
        return this.$g(t, l[0], l[1]);
      };
    }), v.extend = function(l, t) {
      return l.$i || (l(t, Z, v), l.$i = !0), v;
    }, v.locale = R, v.isDayjs = G, v.unix = function(l) {
      return v(1e3 * l);
    }, v.en = C[P], v.Ls = C, v.p = {}, v;
  });
})(rt);
var st = rt.exports;
const K = /* @__PURE__ */ et(st);
var Mt = { exports: {} };
(function(M, F) {
  (function(w, p) {
    M.exports = p();
  })(Q, function() {
    return function(w, p) {
      p.prototype.isSameOrAfter = function(a, O) {
        return this.isSame(a, O) || this.isAfter(a, O);
      };
    };
  });
})(Mt);
var at = { exports: {} };
(function(M, F) {
  (function(w, p) {
    M.exports = p();
  })(Q, function() {
    return function(w, p) {
      p.prototype.isSameOrBefore = function(a, O) {
        return this.isSame(a, O) || this.isBefore(a, O);
      };
    };
  });
})(at);
var St = at.exports;
const kt = /* @__PURE__ */ et(St);
var it = { exports: {} };
(function(M, F) {
  (function(w, p) {
    M.exports = p();
  })(Q, function() {
    return function(w, p, a) {
      var O = p.prototype, $ = function(u) {
        return u && (u.indexOf ? u : u.s);
      }, S = function(u, D, f, U, b) {
        var L = u.name ? u : u.$locale(), q = $(L[D]), z = $(L[f]), B = q || z.map(function(C) {
          return C.slice(0, U);
        });
        if (!b) return B;
        var P = L.weekStart;
        return B.map(function(C, V) {
          return B[(V + (P || 0)) % 7];
        });
      }, x = function() {
        return a.Ls[a.locale()];
      }, T = function(u, D) {
        return u.formats[D] || function(f) {
          return f.replace(/(\[[^\]]+])|(MMMM|MM|DD|dddd)/g, function(U, b, L) {
            return b || L.slice(1);
          });
        }(u.formats[D.toUpperCase()]);
      }, E = function() {
        var u = this;
        return {
          months: function(f) {
            return f ? f.format("MMMM") : S(u, "months");
          },
          monthsShort: function(f) {
            return f ? f.format("MMM") : S(u, "monthsShort", "months", 3);
          },
          firstDayOfWeek: function() {
            return u.$locale().weekStart || 0;
          },
          weekdays: function(f) {
            return f ? f.format("dddd") : S(u, "weekdays");
          },
          weekdaysMin: function(f) {
            return f ? f.format("dd") : S(u, "weekdaysMin", "weekdays", 2);
          },
          weekdaysShort: function(f) {
            return f ? f.format("ddd") : S(u, "weekdaysShort", "weekdays", 3);
          },
          longDateFormat: function(f) {
            return T(u.$locale(), f);
          },
          meridiem: this.$locale().meridiem,
          ordinal: this.$locale().ordinal
        };
      };
      O.localeData = function() {
        return E.bind(this)();
      }, a.localeData = function() {
        var g = x();
        return {
          firstDayOfWeek: function() {
            return g.weekStart || 0;
          },
          weekdays: function() {
            return a.weekdays();
          },
          weekdaysShort: function() {
            return a.weekdaysShort();
          },
          weekdaysMin: function() {
            return a.weekdaysMin();
          },
          months: function() {
            return a.months();
          },
          monthsShort: function() {
            return a.monthsShort();
          },
          longDateFormat: function(D) {
            return T(g, D);
          },
          meridiem: g.meridiem,
          ordinal: g.ordinal
        };
      }, a.months = function() {
        return S(x(), "months");
      }, a.monthsShort = function() {
        return S(x(), "monthsShort", "months", 3);
      }, a.weekdays = function(g) {
        return S(x(), "weekdays", null, null, g);
      }, a.weekdaysShort = function(g) {
        return S(x(), "weekdaysShort", "weekdays", 3, g);
      }, a.weekdaysMin = function(g) {
        return S(x(), "weekdaysMin", "weekdays", 2, g);
      };
    };
  });
})(it);
var vt = it.exports;
const wt = /* @__PURE__ */ et(vt);
var _t = { exports: {} };
(function(M, F) {
  (function(w, p) {
    M.exports = p(st);
  })(Q, function(w) {
    function p($) {
      return $ && typeof $ == "object" && "default" in $ ? $ : {
        default: $
      };
    }
    var a = p(w), O = {
      name: "pt-br",
      weekdays: "domingo_segunda-feira_terça-feira_quarta-feira_quinta-feira_sexta-feira_sábado".split("_"),
      weekdaysShort: "dom_seg_ter_qua_qui_sex_sáb".split("_"),
      weekdaysMin: "Do_2ª_3ª_4ª_5ª_6ª_Sá".split("_"),
      months: "janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro".split("_"),
      monthsShort: "jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez".split("_"),
      ordinal: function(S) {
        return S + "º";
      },
      formats: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD/MM/YYYY",
        LL: "D [de] MMMM [de] YYYY",
        LLL: "D [de] MMMM [de] YYYY [às] HH:mm",
        LLLL: "dddd, D [de] MMMM [de] YYYY [às] HH:mm"
      },
      relativeTime: {
        future: "em %s",
        past: "há %s",
        s: "poucos segundos",
        m: "um minuto",
        mm: "%d minutos",
        h: "uma hora",
        hh: "%d horas",
        d: "um dia",
        dd: "%d dias",
        M: "um mês",
        MM: "%d meses",
        y: "um ano",
        yy: "%d anos"
      }
    };
    return a.default.locale(O, null, !0), O;
  });
})(_t);
K.locale("pt-br");
K.extend(kt);
K.extend(wt);
function Ot(M) {
  const p = (/* @__PURE__ */ new Date()).getFullYear() - Number(M);
  return new Date(p, 0, 1);
}
const xt = [{
  adult: 2,
  children: 0,
  childrenDateOfBirth: []
}], ot = dt(null), bt = ({
  children: M,
  isAtlas: F
}) => {
  const [w, p] = W(() => d("SearchType") || "originAndDestination"), [a, O] = W(() => {
    const s = {
      origin: void 0,
      destination: void 0
    };
    return d("IataOri") && (s.origin = {
      IATA: d("IataOri"),
      city: J("CityOrigin"),
      state: J("StateOrigin"),
      description: J("OriginDesc"),
      id: d("OriginId") ? Number(d("OriginId")) : "",
      type: d("OriginType")
    }), d("IataDes") && (s.destination = {
      IATA: d("IataDes"),
      city: J("City"),
      state: J("State"),
      description: J("DestinationDesc"),
      id: d("DestinationId") ? Number(d("DestinationId")) : "",
      type: d("DestinationType")
    }), s;
  }), [$, S] = W(() => {
    if (d("Adults") || d("Children") || d("ChildAges") || d("Rooms")) {
      const s = [], c = (I) => I.map(Number), m = (I) => I.map((Y) => Ot(Y)), y = () => d("ChildAges").split(";").map((Y) => m(Y.split(","))), k = d("Rooms") ? Number(d("Rooms")) : 1, _ = d("Adults") ? c(d("Adults").split(",")) : [1], H = d("Children") ? c(d("Children").split(",")) : [1], j = d("ChildAges") ? y() : [];
      for (let I = 0; I < k; I++)
        s.push({
          adult: _[I],
          children: H[I],
          childrenDateOfBirth: j.length ? j[I] : j
        });
      return s;
    }
    return xt;
  }), [x, T] = W([]), [E, g] = W(!1), [u, D] = W(() => {
    const s = [];
    return d("CheckIn") && (s[0] = K(d("CheckIn")).toDate()), d("CheckOut") && (s[1] = K(d("CheckOut")).toDate()), s;
  }), [f, U] = W(), [b, L] = W(!1), [q, z] = W({
    pesqinc: !1,
    pax: !1,
    dates: {
      startDate: !1,
      endDate: !1
    }
  }), B = w === "originAndDestination", P = w === "origin", C = w === "destination", V = d("ZoneId") || "", G = d("CountryOrigin") || "", R = d("CountryDestination") || "", v = d("ZoneIdOrigin") || "", h = d("DestinationLat") || "", Z = d("DestinationLon") || "", X = nt(() => ({
    zoneId: V,
    countryOrigin: G,
    countryDestination: R,
    zoneIdOrigin: v,
    destinationLat: h,
    destinationLon: Z
  }), [V, G, R, v, h, Z]), [l, t] = mt(gt.LAST_SEARCH_BOOKING_PACKAGES);
  ht(() => {
    if (l) {
      if (a != null && a.origin || e(l.origin, "ORIGIN"), a != null && a.destination || e(l.destination, "DESTINATION"), (!d("Adults") || !d("Children") || !d("ChildAges")) && S(l.pax), !u.length) {
        const s = l.calendarDates.map((c) => K(c).toDate());
        D(s.length ? s : []);
      }
      d("SearchType") || p(l.searchType);
    }
  }, []);
  const e = (s, c) => {
    T([]), O((m) => ({
      ...m,
      [c.toLowerCase()]: s
    }));
  }, r = (s) => {
    z((c) => ({
      ...c,
      ...s
    }));
  }, n = ft(() => {
    const s = [!1, {}], c = !(a != null && a.origin), m = !(a != null && a.destination), y = !yt($).length, k = !(b ? f != null && f.length : u.length === 2);
    return B && (s[0] = [c, m, y, k].some((_) => _), s[1] = {
      pesqinc: c || m,
      pax: y,
      dates: {
        startDate: k,
        endDate: b ? !1 : u.length < 2
      }
    }), P && (s[0] = [c, y, k].some((_) => _), s[1] = {
      pesqinc: c,
      pax: y,
      dates: {
        startDate: k,
        endDate: b ? !1 : u.length < 2
      }
    }), C && (s[0] = [m, y, k].some((_) => _), s[1] = {
      pesqinc: m,
      pax: y,
      dates: {
        startDate: k,
        endDate: b ? !1 : u.length < 2
      }
    }), s;
  }, [a, $, u, B, P, C, b, f]), i = () => {
    const [s, c] = n(), [m] = Dt($);
    if (z({
      ...c,
      pax: m
    }), s || m) return;
    const y = {
      origin: a == null ? void 0 : a.origin,
      destination: a == null ? void 0 : a.destination,
      pax: $,
      calendarDates: b ? f : u,
      searchType: w,
      ...X
    }, k = $t({
      ...y,
      isMonthDates: b
    });
    t(y), pt(k);
  }, o = nt(() => ({
    searchType: w,
    setSearchType: p,
    pesqincLocationSelected: a,
    setPesqincLocationSelected: e,
    calendarDateSelected: u,
    setCalendarDateSelected: D,
    errors: q,
    setErrors: r,
    paxValue: $,
    setPaxValue: S,
    startDate: x,
    setStartDate: T,
    handleSearch: i,
    isOriginAndDestination: B,
    isOrigin: P,
    isDestination: C,
    isMonthPicker: b,
    setIsMonthPicker: L,
    calendarMonthPickerDateSelected: f,
    setCalendarMonthPickerDateSelected: U,
    isAtlas: F,
    calendarIsOpen: E,
    setCalendarIsOpen: g
  }), [w, p, a, u, q, $, x, B, P, C, b, f, F, E]);
  return /* @__PURE__ */ ct.createElement(ot.Provider, { value: o }, M);
};
bt.displayName = "EngineBookingPackagesProvider";
function Bt() {
  const M = lt(ot);
  if (!M) throw new Error("Chamada do hook useEngineBookingPackagesContext inválida! Certifique-se que ele esta sendo chamado dentro do contexto EngineBookingPackagesProvider");
  return M;
}
export {
  ot as E,
  bt as a,
  K as b,
  xt as d,
  Bt as u
};
