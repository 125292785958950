import e from "react";
import { Button as i } from "@cvccorp-components/chui-react-components";
import { useEnginesConfigContext as m } from "@cvccorp-engines/provider";
import "../../context/ErrorContext/ErrorContext.js";
import { useCollapsableEngine as n } from "../../context/CollapsableEngineContext/CollapsableEngineContext.js";
import { useFormProvider as a } from "./FormProvider.js";
function l() {
  const {
    searchButton: o
  } = m(), {
    formId: r
  } = a(), {
    toggle: t
  } = n();
  return /* @__PURE__ */ e.createElement(i, { form: r, type: "submit", color: o.color, fullWidth: !0, size: "lg", variant: "filled" }, t ? "Alterar Busca" : "Buscar");
}
l.displayName = "FormContainerSubmit";
export {
  l as default
};
