import t from "react";
import { useEngineBusingContext as n } from "../../context/EngineBusingContext.js";
import { Chip as r } from "./SearchOptions.styles.js";
const a = () => {
  const {
    isOneWay: e,
    setIsOneWay: o
  } = n();
  return /* @__PURE__ */ t.createElement(r, { color: "secondary", checked: e, onClick: () => o(!e) }, "Somente ida");
};
a.displayName = "SearchOptions";
export {
  a as default
};
