import r, { useRef as I, useEffect as M, useMemo as B } from "react";
import { useResponsive as J } from "@cvccorp-components/chui-react-components";
import { useFormMobile as L, useAutoFocus as N, InputGroup as T, InputDatePickerRange as W } from "@cvccorp-engines/components";
import { d as u } from "../../dayjs-CxYy674R.js";
import { PaxPackages as j } from "@cvccorp-engines/pax";
import { useResortsStore as G } from "../../store/ResortsStore/ResortsStore.js";
import { resortsOptions as q } from "../../utils/resortOptions.js";
import z from "../SingleResorts/SingleResorts.js";
import { LocationAndDateWrapper as H } from "./styled.js";
function C({
  onDateChange: s,
  onDateClear: E,
  max: g = u(/* @__PURE__ */ new Date()).add(1.5, "year").toDate(),
  min: k,
  onDestinationChange: i,
  destination: v,
  dates: a = []
}) {
  const {
    setOpenPax: f,
    clearPax: b,
    pax: A,
    openPax: O,
    errors: e,
    setPax: S
  } = G(), {
    firstInputRef: h
  } = L(), {
    isMobile: D
  } = J(), {
    register: m,
    focusField: w
  } = N(["datePickerRange"]), p = I(null);
  M(() => {
    var t;
    m("datePickerRange", {
      el: (t = p.current) == null ? void 0 : t.inputOneRef,
      action: D ? "focus" : "click"
    });
  }, [m, D]);
  const y = B(() => {
    var t, n;
    return {
      endDate: (t = a[1]) == null ? void 0 : t.toJSON(),
      startDate: (n = a[0]) == null ? void 0 : n.toJSON()
    };
  }, [a]), F = (t) => {
    if ((a == null ? void 0 : a.length) === 2) return !1;
    if (a[0]) {
      const n = u(a[0]), o = u(t), c = n.add(29, "day"), l = n.subtract(29, "day");
      return o.isAfter(c) || o.isBefore(l);
    }
    return !1;
  }, d = () => {
    var n, o, c, l, R, x;
    const t = {
      startDate: "",
      endDate: ""
    };
    return (n = e == null ? void 0 : e.calendarDates) != null && n.startDate && (t.startDate = "Preencha a data de ida"), (o = e == null ? void 0 : e.calendarDates) != null && o.endDate && (t.endDate = "Preencha a data de volta"), (c = e == null ? void 0 : e.calendarDates) != null && c.startDate && ((l = e == null ? void 0 : e.calendarDates) != null && l.endDate) && (t.startDate = "Preencha a data de ida", t.endDate = "Preencha a data de volta"), !((R = e == null ? void 0 : e.calendarDates) != null && R.startDate) && !((x = e == null ? void 0 : e.calendarDates) != null && x.endDate) && (t.startDate = "", t.endDate = ""), t;
  }, P = () => e != null && e.destination ? "Preencha o campo de destino" : "";
  return /* @__PURE__ */ r.createElement(r.Fragment, null, /* @__PURE__ */ r.createElement(z, { label: "Destino", options: q, error: !!P(), value: v, onSelect: (t) => {
    t && (i == null || i(t), w("datePickerRange"));
  }, inputProps: {
    inputRef: h,
    supportText: P()
  } }), /* @__PURE__ */ r.createElement(H, null, /* @__PURE__ */ r.createElement(T, { fullWidth: !0, status: d().startDate || d().endDate ? "danger" : void 0 }, /* @__PURE__ */ r.createElement(W, { value: a, max: g, min: k, showRangeCount: !0, disableDates: F, error: d(), onChange: (t) => {
    s == null || s(t);
  }, onClear: E, inputRefs: p })), /* @__PURE__ */ r.createElement(j, { open: O, value: A, error: e.pax, calendarDates: y, onConfirm: (t) => {
    S(t), f(!1);
  }, onClear: b, onToggle: f })));
}
export {
  C as default
};
