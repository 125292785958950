import { useRef as H, useMemo as S, useEffect as K } from "react";
import U from "@cvccorp-components/chui-react-components/hooks/useResponsive";
import { getAffreightmentCalendar as V } from "@cvccorp-engines/api";
import { useFetch as W, DateFormat as Y, getCalendarDatesAsDate as q, formatDateToParam as k } from "@cvccorp-engines/components";
import { d as A } from "../../dayjs-CxYy674R.js";
import { calcAge as w } from "@cvccorp-engines/pax";
import { usePackageFullStoreStore as z } from "../../store/EnginePackageFullStore/EnginePackageFullStore.js";
import G from "./formatOptions.js";
const J = 60 * 5 * 1e3;
function oe(y) {
  const {
    max: T,
    min: b,
    calendarPickerRef: x
  } = y, {
    isMobile: D
  } = U(), u = H(), {
    errors: M,
    startCheckIn: n,
    destination: t,
    origin: a,
    pax: s,
    calendarDates: d,
    clearDates: E,
    setStartCheckin: C,
    setCalendarDates: h
  } = z((e) => ({
    pax: e.pax,
    origin: e.origin,
    errors: e.errors ?? {},
    destination: e.destination,
    startCheckIn: e.startCheckIn,
    calendarDates: e.calendarDates,
    clearDates: e.clearDates,
    setStartCheckin: e.setStartCheckin,
    setCalendarDates: e.setCalendarDates
  })), m = (n == null ? void 0 : n.length) === 2, O = (e) => {
    if (!e) return h("");
    const [r] = Array.isArray(e) ? e : [e];
    h(r == null ? void 0 : r.toString());
  }, B = (e, r) => {
    u.current && clearTimeout(u.current), u.current = setTimeout(() => {
      C([r.toString(), e.toString().padStart(2, "0")]);
    }, 700);
  }, p = S(() => s != null && s.length ? s.reduce((e, r) => {
    const l = [];
    for (let c = 0; c < r.adult; c++)
      l.push(30);
    for (let c = 0; c < r.children; c++) {
      const $ = w(r.childrenDateOfBirth[c]);
      l.push($);
    }
    return e.push(l), e;
  }, []) : [], [s]), P = async () => {
    var e;
    if (!(!(a != null && a.id) || !(t != null && t.id)))
      return V({
        startCheckIn: m ? `${n[0]}-${(e = (+(n[1] ?? "0") + 1).toString()) == null ? void 0 : e.padStart(2, "0")}-01` : void 0,
        numberOfMonths: m ? 2 : void 0,
        origin: a == null ? void 0 : a.id,
        destination: t == null ? void 0 : t.id,
        rooms: p
      });
  }, R = [t == null ? void 0 : t.id, a == null ? void 0 : a.id, n, p], {
    data: g,
    isLoading: f,
    previous: _,
    invalidate: j
  } = W(P, R, {
    cacheTime: J,
    enabled: !!(a != null && a.id && (t != null && t.id)),
    onSuccess: (e) => {
      if (d) {
        const r = A(d).format(Y.YEAR_MONTH_DATE);
        (!e.calendar.length || !e.calendar.some((l) => l.date === r)) && h(void 0);
      }
    }
  }), i = Object.values(_).filter((e) => !!(e != null && e.calendar)).flatMap((e) => e == null ? void 0 : e.calendar), v = S(() => f && !m ? [] : G(i), [i, m, f]), F = (e) => !(i != null && i.some((r) => k(r == null ? void 0 : r.date) === k(e))), I = q({
    startDate: d,
    endDate: ""
  }), L = () => {
    var r;
    if (!g || f) return;
    const {
      date: e
    } = ((r = g.calendar) == null ? void 0 : r[0]) || {};
    return A(e);
  };
  K(() => {
    j();
  }, [t, a, p]);
  const N = "column", o = L();
  return {
    min: b,
    max: T,
    errors: M,
    options: v,
    isMobile: D,
    isLoading: f,
    currentDate: I,
    initialDate: {
      month: o == null ? void 0 : o.month(),
      year: o == null ? void 0 : o.year()
    },
    priceWrapperDirection: N,
    calendarPickerRef: x,
    clearDates: E,
    handleChange: O,
    setStartCheckin: C,
    handleDisableDates: F,
    handleChangeCalendar: B
  };
}
export {
  oe as useCalendarPicker
};
