import { useRef as f } from "react";
const l = 101;
function p(e, u) {
  const {
    suffix: r
  } = u || {}, o = f({}), s = (n) => {
    const t = o.current[n + (r || "")];
    if (!e.includes(n))
      return;
    if (t && !t.el.value) {
      setTimeout(() => {
        t.action === "click" && t.el.click(), t.el.focus();
      }, l);
      return;
    }
    const c = e.indexOf(n);
    if (c !== -1 && c < e.length - 1) {
      const i = e[c + 1];
      s(i);
    }
  };
  return {
    inputRefs: o,
    focusField: s,
    register: (n, t) => {
      const c = t instanceof HTMLElement ? {
        action: "focus",
        el: t
      } : t;
      o.current[n + (r || "")] = c;
    }
  };
}
export {
  p as default
};
