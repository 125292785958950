import i, { createContext as a, useMemo as c, useContext as s } from "react";
const t = a({}), u = (e) => {
  const {
    children: r,
    renderNavigation: n
  } = e, o = c(() => ({
    renderNavigation: n
  }), [n]);
  return /* @__PURE__ */ i.createElement(t.Provider, { value: o }, r);
};
u.displayName = "EngineSearchProvider";
const h = () => {
  const e = s(t);
  if (!e)
    throw new Error("useEngineSearchContext must be used within a EngineSearchProvider");
  return e;
};
export {
  u as default,
  h as useEngineSearchContext
};
