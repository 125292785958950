"use client";
import t, { useRef as N, useEffect as b } from "react";
import { useResponsive as Y } from "@cvccorp-components/chui-react-components";
import z from "@cvccorp-components/chui-react-components/components/Checkbox";
import { useAutoFocus as X, gtm as a, InputGroup as q, InputDatePicker as k } from "@cvccorp-engines/components";
import { d } from "../../dayjs-CxYy674R.js";
import { CarsTags as i } from "../../constants/dataLayerTags.js";
import { useCarsStore as J } from "../../store/CarsStore.js";
import P from "../../utils/getDisabledOfficeHours.js";
import _ from "../CarsLocation/CarsLocation.js";
import y from "../SelectHour/SelectHour.js";
import { LocationWrapper as Q, DateWrapper as A } from "./LocationAndDate.styled.js";
import Z from "./useTimeLocations.js";
const K = 30;
function se() {
  const {
    errors: r,
    pickupDate: n,
    pickupTime: G,
    returnDate: f,
    returnTime: M,
    pickupLocation: l,
    returnLocation: E,
    showReturnLocation: I,
    reverse: T,
    setPickupDate: m,
    setPickupTime: v,
    setReturnDate: C,
    setReturnTime: h,
    clearPickupDate: U,
    clearReturnDate: O,
    setReturnLocation: u,
    setPickupLocation: o,
    setShowReturnLocation: w
  } = J(), {
    register: c,
    focusField: D
  } = X(["destination", "datePickerOrigin", "selectHourOrigin", "datePickerDestination", "selectHourDestination"]), {
    isMobile: p
  } = Y(), g = N(null), R = N(null);
  b(() => {
    c("datePickerOrigin", {
      el: g.current,
      action: p ? "focus" : "click"
    }), c("datePickerDestination", {
      el: R.current,
      action: p ? "focus" : "click"
    });
  }, [c, p]);
  const x = () => {
    w(!I), a.pushEvent(i.TOGGLE_CHECKOUT_ORIGIN_OPTION);
  }, {
    pickupOptions: F,
    returnOptions: j
  } = Z();
  function H() {
    return r != null && r.pickupLocation ? "Preencha o local de retirada" : "";
  }
  function S() {
    return r != null && r.returnLocation ? "Preencha o local de devolução" : "";
  }
  const V = d(n).add(K, "days").toDate();
  return b(() => {
    if (!n || !f) return;
    const e = d(n), s = d(f), W = e.isAfter(s), B = s.diff(e, "days") > K;
    (W || B) && O();
  }, [n, f, O]), /* @__PURE__ */ t.createElement(t.Fragment, null, /* @__PURE__ */ t.createElement(Q, null, I ? /* @__PURE__ */ t.createElement(q, { fullWidth: !0, className: "input-group", onReverse: () => T == null ? void 0 : T(), status: S() || H() ? "danger" : void 0, hideIcon: !0, hideClearIcon: !1 }, /* @__PURE__ */ t.createElement(_, { label: "Local de retirada", value: l, onSelect: (e) => {
    o == null || o(e), D("destination"), a.pushEvent(i.SET_CHECKIN_ORIGIN);
  }, onClear: () => {
    o == null || o(void 0), m(void 0), v(void 0), a.pushEvent(i.SET_CHECKIN_ORIGIN);
  }, error: H() }), /* @__PURE__ */ t.createElement(_, { label: "Local de devolução", value: E, onSelect: (e) => {
    u == null || u(e), D("datePickerOrigin"), a.pushEvent(i.SET_CHECKOUT_ORIGIN);
  }, error: S(), onClear: () => {
    u == null || u(void 0), C(void 0), h(void 0), a.pushEvent(i.SET_CHECKOUT_ORIGIN);
  }, inputProps: {
    inputRef: (e) => c("destination", {
      action: "focus",
      el: e
    })
  } })) : /* @__PURE__ */ t.createElement(_, { label: "Local de retirada", value: l, onSelect: (e) => {
    o == null || o(e), D("datePickerOrigin"), a.pushEvent(i.SET_CHECKIN_ORIGIN);
  }, error: H(), onClear: () => {
    o == null || o(void 0), a.pushEvent(i.SET_CHECKIN_ORIGIN);
  } }), /* @__PURE__ */ t.createElement(z, { color: "primary", falseValue: "false", label: "Devolução em outro local ou destino", size: "md", trueValue: "true", checked: I, onChange: x })), /* @__PURE__ */ t.createElement(A, null, /* @__PURE__ */ t.createElement(k, { label: "Retirada", value: new Date(n || ""), onChange: (e) => {
    if (!e) return;
    const [s] = Array.isArray(e) ? e : [e];
    m == null || m(s), a.pushEvent(i.SET_CHECKIN_DATE);
  }, disableDates: (e) => P(e, l == null ? void 0 : l.officeHours), onClear: U, error: r == null ? void 0 : r.pickupDate, inputRef: g }), /* @__PURE__ */ t.createElement(y, { options: F, label: "Horário", value: G, placeholder: "Horário de retirada", onSelect: (e) => {
    v == null || v(e ?? ""), e && (D("datePickerDestination"), a.pushEvent(i.SET_CHECKIN_TIME));
  }, inputProps: {
    inputRef: (e) => c("selectHourOrigin", {
      action: p ? "focus" : "click",
      el: e
    })
  }, error: r.pickupTime ? "Preencha o horário de retirada" : void 0 })), /* @__PURE__ */ t.createElement(A, null, /* @__PURE__ */ t.createElement(k, { label: "Devolução", value: new Date(f || ""), onChange: (e) => {
    if (!e) return;
    const [s] = Array.isArray(e) ? e : [e];
    C == null || C(s), a.pushEvent(i.SET_CHECKOUT_DATE);
  }, disableDates: (e) => P(e, (E == null ? void 0 : E.officeHours) || (l == null ? void 0 : l.officeHours)), min: n ? d(n).add(1, "d").toDate() : void 0, max: V, onClear: O, error: r == null ? void 0 : r.returnDate, calendarDateVisible: {
    month: n ? d(n).toDate().getMonth() : void 0,
    year: n ? d(n).toDate().getFullYear() : void 0
  }, inputRef: R }), /* @__PURE__ */ t.createElement(y, { options: j, label: "Horário", value: M, placeholder: "Horário de devolução", onSelect: (e) => {
    h == null || h(e ?? ""), e && a.pushEvent(i.SET_CHECKOUT_TIME);
  }, inputProps: {
    inputRef: (e) => c("selectHourDestination", {
      action: p ? "focus" : "click",
      el: e
    })
  }, error: r.returnTime ? "Preencha o horário de devolução" : void 0 })));
}
export {
  se as default
};
