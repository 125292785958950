"use client";
import e, { useEffect as s } from "react";
import { useCollapsableEngine as u, FormContainer as r, CollapsableEngineButton as E } from "@cvccorp-engines/components";
import { useEngineTravelInsuranceContext as f } from "../../context/EngineTravelInsuranceContext.js";
import { useTravelInsuranceStore as p } from "../../store/TravelInsuranceStore.js";
import { getTravelInsuranceDestinationParam as C } from "../../utils/getParams.js";
import o from "../LocationAndDate/LocationAndDate.js";
import { Form as d, EngineContainer as l, CancelButtonContainer as g, ButtonContainer as N, TRAVEL_INSURANCE_CONTAINER_EXPANDED_CLASSNAME as I } from "./EngineTravelInsuranceForm.styles.js";
const S = () => {
  const {
    handleSearch: m
  } = f(), {
    value: i
  } = u(), t = i ? I : "", {
    setDestination: n
  } = p();
  return s(() => {
    async function c() {
      const a = await C();
      a && (n == null || n(a));
    }
    c();
  }, [n]), /* @__PURE__ */ e.createElement(d, { formId: "form-travel-insurance", onSearch: m, renderMobile: () => /* @__PURE__ */ e.createElement(r.Mobile, { title: "Seguro Viagem", label: "Destino" }, /* @__PURE__ */ e.createElement(l, { className: t }, /* @__PURE__ */ e.createElement(o, null))), renderDesktop: () => /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement(g, { container: !0, justifyContent: "flex-end" }, /* @__PURE__ */ e.createElement(E, null)), /* @__PURE__ */ e.createElement(l, { className: t }, /* @__PURE__ */ e.createElement(o, null), /* @__PURE__ */ e.createElement(N, null, /* @__PURE__ */ e.createElement(r.Submit, null)))) });
};
S.displayName = "EngineTravelInsuranceForm";
export {
  S as default
};
