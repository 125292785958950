import e from "react";
import f from "@cvccorp-components/chui-react-components/components/Divider";
import p from "@cvccorp-engines/date";
import u from "../../../utils/calcAge.js";
import g from "../../../utils/formatAgeToDate.js";
import n from "../../Counter/Counter.js";
import v from "../../Label/Label.js";
import x from "../../SelectAge/SelectAge.js";
import { usePax as A } from "../PaxProvider/PaxProvider.js";
const k = (i) => {
  const {
    room: a,
    roomIndex: r,
    onAdd: o,
    onRemove: m,
    addChildDateOfBirth: d
  } = i, {
    maxAge: c
  } = A();
  return /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement(n, { label: "Adultos", helperText: "Acima de 18 anos", value: a.adult, min: 1, max: 9, onChange: (t) => {
    t > a.adult ? o("adult", r) : m("adult", r);
  } }), /* @__PURE__ */ e.createElement(n, { label: "Crianças", max: 9, helperText: "De 0 a 17 anos", onChange: (t) => {
    t > a.children ? o("children", r) : m("children", r);
  }, value: a.children }), a.children > 0 && /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement(f, { color: "gray-light", size: "sm", variant: "line", vertical: !1 }), Array.from(Array(a.children).keys()).map((t, l) => /* @__PURE__ */ e.createElement(v, { key: l, text: "Idade da criança", helperText: "ao voltar da viagem" }, /* @__PURE__ */ e.createElement("div", null, /* @__PURE__ */ e.createElement(x, { maxAge: c, label: "Idade", onSelect: (h) => {
    const s = g(h);
    d(p(String(s)).toString(), r, l);
  }, value: String(u(a.childrenDateOfBirth[l])) || void 0, placeholder: "Idade" }))))));
};
export {
  k as default
};
