import { GTMProducts as u, GTMProductTower as r, gtm as e } from "@cvccorp-engines/components";
import { d as a } from "../dayjs-CxYy674R.js";
const s = (t) => Object.values(t.multidestinations ?? {}), p = (t) => {
  if (t.choice === "roundtrip" || t.choice === "oneway") return [t.origin, t.destination];
  const i = s(t).reduce((n, o) => (n.push(o.origin), n.push(o.destination), n), []);
  return [t.origin, t.destination].concat(i);
}, D = (t) => {
  if (t.choice === "oneway") return "";
  if (t.choice === "roundtrip") return t.calendarDates.endDate;
  const i = s(t).at(-1).calendarDates.startDate;
  return i || "";
}, c = (t) => t.type === "AIRPORT" ? t.description.split(",")[0] : "", h = (t) => {
  const i = p(t), n = t.calendarDates.startDate, o = D(t);
  return {
    product: u.FLIGHT,
    product_tower: i.every((d) => d.country === "Brasil") ? r.NATIONAL : r.INTERNATIONAL,
    checkin_date: e.formatDate(n),
    checkout_date: o ? e.formatDate(o) : "",
    duration_days: o ? a(o).diff(a(n), "days").toString() : "",
    origin_city: i.at(0).city,
    destination_city: i.at(-1).city,
    origin_airport_name: c(i.at(0)),
    destination_airport_name: c(i.at(-1))
  };
};
export {
  h as formatStateToGTM
};
