import e from "dayjs";
import { default as d } from "dayjs";
import { default as s } from "dayjs/plugin/isSameOrAfter";
import r from "dayjs/plugin/isSameOrBefore";
import { default as u } from "dayjs/plugin/isSameOrBefore";
import o from "dayjs/plugin/localeData";
import { default as O } from "dayjs/plugin/localeData";
import "dayjs/locale/pt-br";
e.locale("pt-br");
e.extend(r);
e.extend(o);
export {
  d as default,
  s as isSameOrAfter,
  u as isSameOrBefore,
  O as localeData
};
