import { FormContainer as t } from "@cvccorp-engines/components";
import n from "@emotion/styled";
import { Grid as r } from "../EngineTravelInsuranceCollapsable/styles.js";
const e = "travelInsuranceEngine_container__expanded", m = n.div`
    margin-left: ${({
  theme: i
}) => i.spacing.size[5]};
    container-type: inline-size;

    @container (max-width: 1024px) {
        margin: 0;
    }
`, s = n.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    gap: 12px;
    container-type: inline-size;
    align-items: flex-start;
    @container (min-width: 899px) {
        display: grid;
        grid-template-columns: 1fr 1fr 120px;
        gap: 12px;
    }
`, d = n(r)`
    &&&:has(> *) {
        margin-bottom: 24px;
    }
`, c = n(t)`
    & .${e} {
        @container (min-width: 899px) {
            display: grid;
            grid-template-columns: 1fr 1fr 180px;
            gap: 12px;
        }
    }
`;
export {
  m as ButtonContainer,
  d as CancelButtonContainer,
  s as EngineContainer,
  c as Form,
  e as TRAVEL_INSURANCE_CONTAINER_EXPANDED_CLASSNAME
};
