import d, { useState as h, useMemo as x } from "react";
import D from "@cvccorp-components/chui-react-components/components/Autocomplete";
import { pesqIncService as H } from "@cvccorp-engines/api";
import { getParams as g, useError as $, useFormMobile as q, Pesqinc as F } from "@cvccorp-engines/components";
import { getHotelParamsForLogs as k } from "../../utils/getHotelParams.js";
import { LocationContainer as A } from "./Pesqinc.styles.js";
const C = (b) => {
  const {
    error: l,
    value: a,
    label: O = "Destino",
    supportText: y,
    supportTextProps: L,
    onClear: r,
    onSelect: i
  } = b, [m, P] = h([]), S = g("packageGroup") ? String(g("packageGroup")).toUpperCase() : "STANDALONE", {
    throwError: t
  } = $(), {
    firstInputRef: E
  } = q();
  function c(e) {
    if (!e) return "";
    const o = e.description.indexOf(" -") > -1 ? e.description.split(" -")[0] : e.description;
    return `${e.type === "HOTEL" ? o : e.city} - ${e != null && e.state ? e.state : ""}, ${e != null && e.country ? e.country : ""}`;
  }
  const R = async (e) => {
    const T = k(), o = {
      operation: "location",
      productType: "hotel",
      packageGroup: S,
      ...T
    }, u = (/* @__PURE__ */ new Date()).getTime();
    try {
      const p = (await H("HOT", e)).locations.sort((n, s) => n.type === "CITY" && s.type === "HOTEL" ? -1 : n.type === "HOTEL" && s.type === "CITY" ? 1 : 0).map((n) => ({
        label: c(n),
        value: n
      }));
      if (p.length === 0) {
        const s = (/* @__PURE__ */ new Date()).getTime() - u;
        Object.assign(o, {
          duration: s,
          _message: `EMPTYRESULT ${e}`
        }), t == null || t(o);
      }
      P(p);
    } catch (f) {
      const p = (/* @__PURE__ */ new Date()).getTime() - u;
      return Object.assign(o, {
        duration: p,
        _message: `ERROR ${e}`,
        errorResponse: {
          error: f.message
        }
      }), t == null || t(o), [];
    }
  }, v = x(() => {
    const e = m;
    return a && e.push({
      value: a,
      label: c(a)
    }), e;
  }, [m, a]);
  return /* @__PURE__ */ d.createElement(A, null, /* @__PURE__ */ d.createElement(F, { label: l || O, placeholder: "Para onde você vai?", supportText: y, supportTextProps: L, error: l, onSearch: R, onSelect: (e) => {
    if (!e) return r ? r == null ? void 0 : r() : i(e);
    i(e);
  }, inputProps: {
    inputRef: E
  }, options: v, value: a, renderOption: ({
    option: e
  }) => /* @__PURE__ */ d.createElement(D.Option, { fullWidth: !0, supportText: c(e.value), label: c(e.value), onSelect: () => i(e.value) }) }));
};
C.displayName = "PesqIncSingleLocation";
export {
  C as default
};
