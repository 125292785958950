import n from "react";
import { u as c } from "./EngineBookingPackagesContext-D4tagYeS.js";
import { ESearchTypes as i } from "@cvccorp-engines/api";
import { InputGroup as u, gtm as g } from "@cvccorp-engines/components";
import { DefaultWrapper as h } from "./components/Pesqinc/Pesqinc.styles.js";
import t from "./components/Pesqinc/SingleBooking/SingleBooking.js";
const p = () => {
  const {
    errors: r,
    pesqincLocationSelected: e,
    setPesqincLocationSelected: o
  } = c(), d = () => {
    const a = e == null ? void 0 : e.origin, s = e == null ? void 0 : e.destination;
    g.pushEvent("pacotes_home_engajamento", {
      category: "pacotes_busca",
      action: "click-swap",
      label: `${a == null ? void 0 : a.IATA} - ${s == null ? void 0 : s.IATA}`
    }), o(a, "DESTINATION"), o(s, "ORIGIN");
  };
  return /* @__PURE__ */ n.createElement(u, { status: r.pesqinc ? "danger" : void 0, hasError: r.pesqinc, onReverse: d, hideIcon: !0, hideClearIcon: !1 }, /* @__PURE__ */ n.createElement(t, { searchType: i.ORIGIN, label: "Origem", placeholder: "De onde vai sair?", error: r.pesqinc && !(e != null && e.origin) ? l.origin : void 0 }), /* @__PURE__ */ n.createElement(t, { searchType: i.DESTINATION, label: "Destino", placeholder: "Para onde vai?", error: r.pesqinc && !(e != null && e.destination) ? l.destination : void 0 }));
};
p.displayName = "PesqincFull";
const I = {
  origin: {
    label: "Origem",
    placeholder: "De onde vai sair?",
    searchType: i.ORIGIN
  },
  destination: {
    label: "Destino",
    placeholder: "Para onde vai?",
    searchType: i.DESTINATION
  }
}, m = () => {
  const {
    searchType: r,
    errors: e
  } = c(), o = I[r];
  return /* @__PURE__ */ n.createElement(h, null, /* @__PURE__ */ n.createElement(t, { ...o, isUniqueDirection: !0, error: e.pesqinc ? l[r] : void 0 }));
};
m.displayName = "PesqincSingle";
const l = {
  origin: "Preencha o campo de origem",
  destination: "Preencha o campo de destino"
}, T = () => {
  const {
    isOriginAndDestination: r
  } = c();
  return r ? /* @__PURE__ */ n.createElement(p, null) : /* @__PURE__ */ n.createElement(m, null);
};
T.displayName = "Pesqinc";
export {
  T as P,
  m as a,
  p as b,
  l as e
};
