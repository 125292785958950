import { DEFAULT_TRAVERS as m, FlightClasses as p } from "@cvccorp-engines/pax";
import { create as _ } from "zustand";
import { FlightOptions as b } from "./FlightStore.types.js";
const n = {
  destination: !1,
  origin: !1,
  originDestination: !1,
  calendarDates: {
    endDate: !1,
    startDate: !1
  },
  multidestinations: {
    0: {
      destination: !1,
      origin: !1,
      calendarDates: {
        endDate: !1,
        startDate: !1
      }
    }
  }
}, v = _((i) => ({
  pax: m,
  classe: p.ALL,
  calendarDates: {
    startDate: "",
    endDate: ""
  },
  destination: void 0,
  origin: void 0,
  errors: n,
  multidestinations: {
    0: {}
  },
  choice: b.GOING_AND_BACK,
  backToFirstOrigin: !1,
  setValues(r) {
    return i(() => r);
  },
  setBackToFirstOrigin(r) {
    return i(() => ({
      backToFirstOrigin: r
    }));
  },
  setDestination: (r) => i((e) => ({
    destination: r,
    errors: {
      ...e.errors,
      destination: n.destination,
      originDestination: n.originDestination
    }
  })),
  setOrigin: (r) => i((e) => ({
    origin: r,
    errors: {
      ...e.errors,
      origin: n.origin,
      originDestination: n.originDestination
    }
  })),
  setCalendarDates: (r) => i((e) => ({
    calendarDates: r,
    errors: {
      ...e.errors,
      calendarDates: n.calendarDates
    }
  })),
  setPax: (r) => i((e) => ({
    pax: r,
    errorsPax: {},
    errors: {
      ...e.errors,
      pax: !1,
      baby: !1
    }
  })),
  setErrors: (r) => i(() => ({
    errors: r
  })),
  setChoice: (r) => i(() => ({
    choice: r,
    errors: n
  })),
  clearPax: () => i(() => ({
    pax: m,
    classe: p.ALL
  })),
  clearDates: () => i((r) => ({
    calendarDates: {
      endDate: void 0,
      startDate: void 0
    },
    errors: {
      ...r.errors,
      calendarDates: n.calendarDates
    }
  })),
  addMultdestinations(r) {
    return i((e) => {
      const s = {
        ...e
      };
      return s.multidestinations[Date.now()] = r, s;
    });
  },
  removeMultdestinations(r) {
    return i((e) => {
      var c, u, a, o, l, D, g, f;
      let s = r;
      const t = {
        ...e
      };
      if (r === -1) {
        const [d] = Object.entries(t.multidestinations).map(([x, O]) => ({
          ...O,
          key: Number(x)
        })) ?? [];
        d && (s = d.key, t.calendarDates = d.calendarDates, t.destination = d.destination, t.origin = d.origin, t.errors = {
          calendarDates: (u = (c = t.errors) == null ? void 0 : c.multidestinations) == null ? void 0 : u[s].calendarDates,
          destination: (o = (a = t.errors) == null ? void 0 : a.multidestinations) == null ? void 0 : o[s].destination,
          origin: (D = (l = t.errors) == null ? void 0 : l.multidestinations) == null ? void 0 : D[s].origin
        });
      }
      return (f = (g = t.errors) == null ? void 0 : g.multidestinations) == null || delete f[s], delete t.multidestinations[s], t;
    });
  },
  setMultiDestination(r, e) {
    i((s) => {
      var a, o;
      const t = {
        ...s
      }, c = Object.keys(e).reduce((l, D) => (l[D] = !1, l), {}), u = t.multidestinations[r];
      return t.multidestinations[r] = {
        ...u,
        ...e
      }, (o = (a = t.errors) == null ? void 0 : a.multidestinations) != null && o[r] && (t.errors.multidestinations[r] = {
        ...t.errors.multidestinations[r],
        ...c
      }), t;
    });
  },
  reverse() {
    return i((r) => {
      var e, s;
      return {
        destination: r.origin,
        origin: r.destination,
        errors: {
          ...r.errors,
          destination: (e = r.errors) == null ? void 0 : e.origin,
          origin: (s = r.errors) == null ? void 0 : s.destination
        }
      };
    });
  },
  setClasse(r) {
    return i((e) => ({
      classe: r,
      errors: {
        ...e.errors,
        pax: !1,
        classe: !1
      }
    }));
  }
}));
export {
  v as useFlightStore
};
