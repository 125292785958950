import { d as i } from "../../dayjs-CxYy674R.js";
import { DateFormat as n } from "../../constants/dateFormat.js";
const e = (a, t = {}) => {
  window.dataLayer = window.dataLayer || [], window.dataLayer.push({
    event: a,
    ...t
  });
}, o = {
  airline_name: "",
  checkin_date: "",
  checkin_time: "",
  checkout_date: "",
  checkout_time: "",
  destination_airport_name: "",
  destination_city: "",
  duration_days: "",
  hotel_name: "",
  origin_airport_name: "",
  origin_city: ""
}, _ = {
  pushEvent: e,
  onEngineSearch: (a) => {
    e("clique_buscar", {
      ...o,
      ...a
    });
  },
  formatDate: (a, t) => i(a, t).format(n.DAY_MONTH_YEAR)
};
export {
  _ as gtm
};
