import { Skeleton } from "@cvccorp-components/chui-react-components";
import { EngineBox } from "@cvccorp-engines/components";
import * as Styled from "./styles";

const EngineSkeleton = () => {
    return (
        <EngineBox>
            <Styled.NavigationContainer container>
                {new Array(14).fill(undefined).map((_, index) => (
                    <Styled.NavigationItemContainer container item key={index} justifyContent="center">
                        <Styled.IconSkeleton />
                        <Skeleton />
                    </Styled.NavigationItemContainer>
                ))}
            </Styled.NavigationContainer>

            <Styled.EngineFieldSkeletonContainer container>
                {new Array(3).fill(undefined).map((_, index) => (
                    <Styled.EngineFieldSkeleton key={index} />
                ))}
            </Styled.EngineFieldSkeletonContainer>
        </EngineBox>
    );
};

export default EngineSkeleton;
