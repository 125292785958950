import { GTMProducts as e, GTMProductTower as a, gtm as r } from "@cvccorp-engines/components";
import { d as o } from "../dayjs-CxYy674R.js";
const c = (t) => ({
  product: e.TRAVEL_INSURANCE,
  product_tower: t.destination.country === "Brasil" ? a.NATIONAL : a.INTERNATIONAL,
  destination_city: t.destination.city,
  checkin_date: r.formatDate(t.calendarDates.startDate),
  checkout_date: r.formatDate(t.calendarDates.endDate),
  duration_days: o(t.calendarDates.endDate).diff(o(t.calendarDates.startDate), "days").toString()
});
export {
  c as formatStateToGTM
};
