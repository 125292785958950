import r from "react";
import { useEngineBusingCharterContext as o } from "./EngineBusingCharter/context/EngineBusingCharterContext.js";
import a from "./EngineBusingCharter/components/Pesqinc/PesqincFull/PesqincFull.js";
import c from "./EngineBusingCharter/components/Pesqinc/PesqincInput/PesqincInput.js";
import { ESearchTypes as i } from "@cvccorp-engines/api";
import { DefaultWrapper as l } from "./EngineBusingCharter/components/Pesqinc/Pesqinc.styles.js";
import p from "./EngineBusingCharter/components/Pesqinc/SingleBusingCharter/SingleBusingCharter.js";
const m = {
  origin: {
    label: "Origem",
    placeholder: "De onde vai sair?",
    searchType: i.ORIGIN
  },
  destination: {
    label: "Destino",
    placeholder: "Para onde vai?",
    searchType: i.DESTINATION
  }
}, t = () => {
  const {
    searchType: e,
    errors: n
  } = o(), s = m[e];
  return /* @__PURE__ */ r.createElement(l, null, /* @__PURE__ */ r.createElement(p, { ...s, isUniqueDirection: !0, error: n[e] ? u[e] : void 0 }));
};
t.displayName = "PesqincSingle";
const u = {
  origin: "Preencha o campo de origem",
  destination: "Preencha o campo de destino"
}, d = () => {
  const {
    isOriginAndDestination: e,
    isExcursion: n
  } = o();
  return e ? /* @__PURE__ */ r.createElement(a, null) : n ? /* @__PURE__ */ r.createElement(c, null) : /* @__PURE__ */ r.createElement(t, null);
};
d.displayName = "Pesqinc";
export {
  d as P,
  t as a,
  u as e
};
