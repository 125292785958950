import e from "react";
import { Grid as J } from "@cvccorp-components/chui-react-components";
import { useCollapsableEngine as w, getCalendarDatesAsDate as W, FormContainer as o, CollapsableEngineButton as Y } from "@cvccorp-engines/components";
import { d as F } from "../../dayjs-CxYy674R.js";
import { useMotorPackageContext as $ } from "../../context/EnginePackageContext/EnginePackageContext.js";
import { useMotorPackageFullContext as q } from "../../context/EnginePackageFullContext/EnginePackageFullContext.js";
import { usePackageFullStoreStore as z } from "../../store/EnginePackageFullStore/EnginePackageFullStore.js";
import { usePackageStore as Q } from "../../store/PackageStore/PackageStore.js";
import O from "../DefaultLocationForm/index.js";
import N, { CHOICE_PACKAGE as i, CHOICE_PACKAGE_FULL as b } from "../NavigationChips/NavigationChips.js";
import M from "../PackageFullLocationAndDate/LocationAndDate.js";
import { Form as V, PackageContainer as _, buttonContainer as Z, PackageFullContainer as v, ButtonContainer as ee, PACKAGE_CONTAINER_EXPANDED_CLASSNAME as ne, PACKAGE_FULL_CONTAINER_EXPANDED_CLASSNAME as te } from "./styles.js";
const ae = 330, re = ({
  product: t
}) => {
  const {
    handleSearch: x
  } = $(), {
    handleSearch: L
  } = q(), n = z(), {
    value: l
  } = w(), {
    origin: s,
    errors: c,
    destination: m,
    calendarDates: R,
    reverse: g,
    setOrigin: C,
    setOpenPax: G,
    clearDates: d,
    setDestination: E,
    setCalendarDates: I
  } = Q(), u = F().add(1, "d").toDate(), D = F().add(ae, "d").toDate(), p = W(R), h = l ? ne : "", k = l ? te : "", A = () => G(!0), P = (f) => {
    if (!f) return;
    const [a, r] = f;
    I({
      startDate: a == null ? void 0 : a.toJSON(),
      endDate: r == null ? void 0 : r.toJSON()
    });
  }, K = () => /* @__PURE__ */ e.createElement(_, { className: h }, /* @__PURE__ */ e.createElement(O, { errors: c, origin: s, destination: m, onDestinationChange: E, onOriginChange: C, onOpenPax: A, dates: p, onReverse: g, onDateClear: d, onDateChange: P, min: u, max: D })), y = () => /* @__PURE__ */ e.createElement(_, { className: h }, /* @__PURE__ */ e.createElement(O, { errors: c, origin: s, destination: m, onDestinationChange: E, onOriginChange: C, onOpenPax: A, dates: p, onReverse: g, onDateClear: d, onDateChange: P, min: u, max: D }, /* @__PURE__ */ e.createElement(Z, null, /* @__PURE__ */ e.createElement(o.Submit, null)))), H = () => /* @__PURE__ */ e.createElement(v, { className: k }, /* @__PURE__ */ e.createElement(M, { errors: n.errors, origin: n.origin, destination: n.destination, onDestinationChange: n.setDestination, onOriginChange: n.setOrigin, onReverse: n.reverse })), T = () => /* @__PURE__ */ e.createElement(v, { className: k }, /* @__PURE__ */ e.createElement(M, { errors: n.errors, origin: n.origin, destination: n.destination, onDestinationChange: n.setDestination, onOriginChange: n.setOrigin, onReverse: n.reverse }), /* @__PURE__ */ e.createElement(ee, null, /* @__PURE__ */ e.createElement(o.Submit, null))), S = {
    [i.value]: {
      handleSearch: x,
      renderMobile: K,
      renderDesktop: y,
      title: i.label
    },
    [b.value]: {
      handleSearch: L,
      renderMobile: H,
      renderDesktop: T,
      title: b.label
    }
  }, {
    handleSearch: U,
    renderDesktop: X,
    renderMobile: j,
    title: B
  } = S[t] ?? S[i.value];
  return /* @__PURE__ */ e.createElement(V, { formId: "form-package", onSearch: U, renderMobile: () => /* @__PURE__ */ e.createElement(o.Mobile, { title: B, label: "Destino" }, /* @__PURE__ */ e.createElement(N, { product: t }), j()), renderDesktop: () => /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement(J, { container: !0, justifyContent: "space-between" }, /* @__PURE__ */ e.createElement(N, { product: t }), /* @__PURE__ */ e.createElement(Y, null)), X()) });
};
re.displayName = "Form";
export {
  re as default
};
