import a, { useState as J, useRef as z, useEffect as Q, useMemo as X } from "react";
import { useResponsive as Z } from "@cvccorp-components/chui-react-components";
import ee from "@cvccorp-components/chui-react-components/components/Button";
import { _ as te, a as F, e as ae, l as oe, P as $ } from "./PesqincFight-DY1Yl5dT.js";
import { useAutoFocus as re, useFieldErrors as ne, InputGroup as ie, InputDatePickerRange as se, InputDatePicker as le } from "@cvccorp-engines/components";
import { PaxFlights as ce } from "@cvccorp-engines/pax";
import { useTheme as ue } from "@emotion/react";
import de from "./hooks/useMergedMultDestination.js";
import { useFlightStore as me } from "./store/FlightStore.js";
import { FlightOptions as I } from "./store/FlightStore.types.js";
import { LocationAndDateWrapper as fe } from "./styled.js";
var pe = ["size", "className"], G = function(t) {
  var f, p, n = t.size, i = n === void 0 ? "medium" : n, h = t.className, x = h === void 0 ? "" : h, s = te(t, pe), g = s, v = ["chui-icon", x].join(" "), D = g.color || "#000014", E = F({}, s, {
    color: void 0
  }), r = ae[i] || oe[i];
  return /* @__PURE__ */ a.createElement("svg", F({
    xmlns: "http://www.w3.org/2000/svg",
    fill: D,
    viewBox: "0 0 24 24",
    width: (f = s.width) != null ? f : r,
    height: (p = s.height) != null ? p : r,
    className: v
  }, E), /* @__PURE__ */ a.createElement("path", {
    fill: void 0,
    d: "M10 9.53a.5.5 0 0 0-.5.5v6.59a.5.5 0 0 0 1 0v-6.59a.5.5 0 0 0-.5-.5M13.606 9.676a.5.5 0 0 1 .854.354v6.59a.5.5 0 0 1-1 0v-6.59a.5.5 0 0 1 .146-.354"
  }), /* @__PURE__ */ a.createElement("path", {
    fill: void 0,
    fillRule: "evenodd",
    d: "M21.24 4.91h-5.5v-.82a1.82 1.82 0 0 0-1.77-1.82h-4a1.82 1.82 0 0 0-1.83 1.82v.82H2.75a.5.5 0 1 0 0 1h1.49l1.21 14.16a1.81 1.81 0 0 0 1.79 1.67h9.45a1.81 1.81 0 0 0 1.81-1.66l1.19-14.16h1.55a.5.5 0 0 0 0-1zm-12.1-.82a.82.82 0 0 1 .82-.82h4a.82.82 0 0 1 .79.82v.82H9.14zm8.38 15.9a.83.83 0 0 1-.82.75H7.24a.82.82 0 0 1-.81-.75L5.24 5.91h13.42z",
    clipRule: "evenodd"
  }));
};
G.displayName = "OutlineBin";
function Ne(w) {
  const {
    errors: t = {},
    max: f,
    min: p,
    origin: n,
    destination: i,
    isMult: h = !1,
    hidePax: x = !1,
    dates: s = [],
    onRemove: g,
    onReverse: v,
    onDateClear: D,
    onDateChange: E,
    onOriginChange: r,
    onDestinationChange: u,
    firstInputRef: j
  } = w, M = ue(), [B, b] = J(!1), {
    isMobile: N
  } = Z(), _ = de(), {
    choice: d,
    pax: H,
    setPax: W,
    clearPax: U,
    classe: V,
    setClasse: q
  } = me(), m = d === I.GOING_AND_BACK, {
    register: P,
    focusField: L
  } = re(["destination", m ? "datePickerRange" : "datePicker"]), {
    getErrorMessage: c
  } = ne({
    errors: t
  }), T = z(null), C = z(null);
  Q(() => {
    var e;
    if (d === I.MULTIPLE_DESTINATION || d === I.ONLY_GOING) {
      P("datePicker", {
        el: C.current,
        action: N ? "focus" : "click"
      });
      return;
    }
    P("datePickerRange", {
      el: (e = T.current) == null ? void 0 : e.inputOneRef,
      action: N ? "focus" : "click"
    });
  }, [P, d, N]);
  const O = Array.isArray(s) ? s : [s], y = m ? O : O[0], K = m ? "Escolha as datas" : "Escolha a data", Y = X(() => {
    if (d !== I.MULTIPLE_DESTINATION) return [];
    const e = _.reduce((o, l, R) => {
      var A, S;
      if (!((A = l == null ? void 0 : l.calendarDates) != null && A.startDate)) return o;
      const k = o[(S = l.calendarDates) == null ? void 0 : S.startDate] ?? [];
      return k.push({
        currentIndex: R,
        ...l
      }), o[l.calendarDates.startDate] = k, o;
    }, {});
    return Object.entries(e).map(([o, l]) => {
      const R = l.map((k) => k.currentIndex + 1);
      return {
        date: new Date(o),
        subtitle: R.length > 1 ? "Voos" : `Voo ${R.join("")}`,
        variant: "border",
        subtitleProps: {
          weight: "bold",
          style: {
            color: M.colors.feedback.active.default[500]
          }
        }
      };
    });
  }, [d, _, M.colors.feedback.active.default]);
  return /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement(ie, { fullWidth: !0, hasError: t.origin || t.destination || t.originDestination, onReverse: () => v == null ? void 0 : v(i, n), status: t.origin || t.destination || t.originDestination ? "danger" : void 0, hideIcon: !0, hideClearIcon: !1 }, /* @__PURE__ */ a.createElement($, { supportText: c("origin"), error: c("origin"), placeholder: "De onde você vai sair?", label: "Origem", value: n != null && n.city ? n : void 0, onSelect: (e) => {
    e != null && e.city && (r == null || r(e), L("destination"));
  }, onClear: () => r == null ? void 0 : r(void 0), inputProps: {
    inputRef: j
  } }), /* @__PURE__ */ a.createElement($, { supportText: c("destination"), error: c("destination"), placeholder: "Para onde você vai?", label: "Destino", value: i != null && i.city ? i : void 0, onSelect: (e) => {
    e != null && e.city && (u == null || u(e), L(m ? "datePickerRange" : "datePicker"));
  }, onClear: () => {
    u == null || u(void 0);
  }, inputProps: {
    inputRef: (e) => P("destination", {
      action: "focus",
      el: e
    })
  } })), /* @__PURE__ */ a.createElement(fe, { isMult: h }, m ? /* @__PURE__ */ a.createElement(se, { showRangeCount: !0, max: f, min: p, value: y, onChange: (e) => {
    E(e.map((o) => o == null ? void 0 : o.toISOString()));
  }, onClear: D, error: {
    startDate: c("startDate"),
    endDate: c("endDate")
  }, inputRefs: T }) : /* @__PURE__ */ a.createElement(le, { label: K, value: y, onChange: E, onClear: D, range: m, max: f, min: p, options: Y, showRangeCount: !0, error: c("startDate"), inputRef: C }), g && /* @__PURE__ */ a.createElement(ee, { icon: /* @__PURE__ */ a.createElement(G, null), variant: "text", color: "neutral", size: "lg", type: "button", onClick: g }), !x && /* @__PURE__ */ a.createElement(ce, { open: B, onToggle: b, value: H, classe: V, onConfirm: (e) => {
    W(e), q(e.classe), b(!1);
  }, onClear: () => {
    U(), b(!1);
  }, error: t == null ? void 0 : t.pax })));
}
export {
  Ne as L,
  G as v
};
