const c = ["busing-charter", "package-full"];
function u(t, i, r) {
  const a = [], s = c.includes(r), l = s ? ["0 a 23 meses"] : ["0 a 11 meses", "1 ano"];
  for (let e = 0; e <= i; e++) {
    let n = `${e} anos`, o = String(e);
    e === 0 ? (n = l[0], o = t ? String(t) : "0") : s && e === 1 && (n = l[1], o = t ? String(t + 1) : "1"), (e !== 1 || !s) && a.push({
      label: n,
      value: o
    });
  }
  return a;
}
export {
  u as default
};
