import { useMemo as s } from "react";
import { d as t } from "../../dayjs-CxYy674R.js";
import o from "../useEngineSearchHistory/useEngineSearchHistory.js";
const D = (r) => {
  const e = o(r);
  return s(() => ({
    ...e,
    searchHistory: e.searchHistory.map((a) => ({
      ...a,
      calendarDates: {
        startDate: t(a.calendarDates.startDate).toDate(),
        endDate: t(a.calendarDates.endDate).toDate()
      }
    }))
  }), [e]);
};
export {
  D as default
};
