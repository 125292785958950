import c from "react";
import { EngineProductsName as r, OptionsBar as C } from "@cvccorp-engines/components";
import { NAVIGATION_PRODUCTS as a, useEngineNavigation as p } from "@cvccorp-engines/navigation";
import { usePackagePackageFullStoreStore as u } from "../../store/PackagePackageFullStore/PackagePackageFullStore.js";
const l = {
  product: a.PACKAGES,
  value: "/pacotes-turisticos",
  label: "Aéreo + Hotel"
}, A = {
  product: a.PACKAGE_COMPLETE,
  value: "/pacotes-estatico",
  label: r.PACKAGE_FULL
}, E = [l, A], m = ({
  product: t
}) => {
  const {
    choice: i,
    setChoice: n
  } = u(), {
    navigationType: o
  } = p(), e = o === "button";
  return /* @__PURE__ */ c.createElement(C, { variant: o, options: E, value: e ? i : t, isChecked: e ? void 0 : (s) => s.product === t, onChange: n });
};
m.displayName = "NavigationChips";
export {
  l as CHOICE_PACKAGE,
  A as CHOICE_PACKAGE_FULL,
  E as choices,
  m as default
};
