import e from "react";
import { FormContainer as n, CollapsableEngineButton as d } from "@cvccorp-engines/components";
import { d as C } from "../../dayjs-CxYy674R.js";
import { useMotorTicketsContext as E } from "../../contexts/EngineTicketsContext.js";
import { useTicketsStore as g } from "../../store/TicketsStore.js";
import c from "../DefaultLocationForm/index.js";
import { MotorContainer as u, CancelButtonContainer as p, buttonContainer as S } from "./styled.js";
function h() {
  const {
    handleSearch: D
  } = E(), {
    destination: o,
    setDestination: r,
    date: f,
    setDate: a,
    errors: i,
    clearDate: l
  } = g(), m = C().add(1, "d").toDate(), s = new Date(f ?? "");
  return /* @__PURE__ */ e.createElement(n, { formId: "form-ticket", onSearch: D, renderMobile: () => /* @__PURE__ */ e.createElement(n.Mobile, { title: "Ingresso", label: "Destino" }, /* @__PURE__ */ e.createElement(u, { twoItems: !0 }, /* @__PURE__ */ e.createElement(c, { errors: i, destination: o, onDestinationChange: r, date: s, onDateChange: (t) => {
    t && a(t.toString());
  }, onDateClear: l, min: m }))), renderDesktop: () => /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement(p, { container: !0, justifyContent: "flex-end" }, /* @__PURE__ */ e.createElement(d, null)), /* @__PURE__ */ e.createElement(u, { twoItems: !0 }, /* @__PURE__ */ e.createElement(c, { errors: i, destination: o, onDestinationChange: r, date: s, onDateChange: (t) => {
    t && a(t.toString());
  }, onDateClear: l, min: m }, /* @__PURE__ */ e.createElement(S, null, /* @__PURE__ */ e.createElement(n.Submit, null))))) });
}
h.displayName = "SingleDestinationForm";
export {
  h as default
};
