import e from "react";
import { Grid as b } from "@cvccorp-components/chui-react-components";
import { FormContainer as r, CollapsableEngineButton as S } from "@cvccorp-engines/components";
import { useEngineNavigation as D, NAVIGATION_PRODUCTS as M } from "@cvccorp-engines/navigation";
import O from "../../../components/BusingOptionsBar/BusingOptionsBar.js";
import o from "../../../EngineBusing/components/DefaultLocationForm/DefaultLocationForm.js";
import k from "../../../EngineBusing/components/SearchOptions/SearchOptions.js";
import { useEngineBusingContext as v } from "../../../EngineBusing/context/EngineBusingContext.js";
import a from "../../../EngineBusingCharter/components/DefaultLocationForm/DefaultLocationForm.js";
import F from "../../../EngineBusingCharter/components/SearchOptions/SearchOptions.js";
import { useEngineBusingCharterContext as I } from "../../../EngineBusingCharter/context/EngineBusingCharterContext.js";
import { ContainerNavigation as N, Divider as R, BusingMotorContainer as i, BusingButtonContainer as y, BusingCharterMotorContainer as l, BusingCharterButtonContainer as P } from "./styles.js";
const T = ({
  engineNavigationType: s
}) => {
  const {
    currentProduct: m
  } = D(), c = m === M.BUSING_CHARTER, {
    handleSearch: u
  } = v(), {
    handleSearch: d
  } = I(), n = {
    busing: {
      handleSearch: u,
      renderMobile: () => /* @__PURE__ */ e.createElement(i, null, /* @__PURE__ */ e.createElement(o, null)),
      renderDesktop: () => /* @__PURE__ */ e.createElement(i, null, /* @__PURE__ */ e.createElement(o, null), /* @__PURE__ */ e.createElement(y, null, /* @__PURE__ */ e.createElement(r.Submit, null))),
      SearchOptions: k,
      formId: "form-busing",
      title: "Passagem Rodoviária"
    },
    busingCharter: {
      handleSearch: d,
      renderMobile: () => /* @__PURE__ */ e.createElement(l, null, /* @__PURE__ */ e.createElement(a, null)),
      renderDesktop: () => /* @__PURE__ */ e.createElement(l, null, /* @__PURE__ */ e.createElement(a, null), /* @__PURE__ */ e.createElement(P, null, /* @__PURE__ */ e.createElement(r.Submit, null))),
      SearchOptions: F,
      formId: "form-busing-charter",
      title: "Pacote Rodoviário"
    }
  }, {
    formId: p,
    handleSearch: h,
    renderDesktop: g,
    renderMobile: E,
    SearchOptions: C,
    title: f
  } = c ? n.busingCharter : n.busing, t = (B = !1) => /* @__PURE__ */ e.createElement(b, { container: !0, justifyContent: "space-between" }, /* @__PURE__ */ e.createElement(N, null, /* @__PURE__ */ e.createElement(O, { navigationType: s }), /* @__PURE__ */ e.createElement(R, { vertical: !0, color: "secondary" }), /* @__PURE__ */ e.createElement(C, null)), B ? void 0 : /* @__PURE__ */ e.createElement(S, null));
  return /* @__PURE__ */ e.createElement(r, { formId: p, onSearch: h, renderDesktop: () => /* @__PURE__ */ e.createElement(e.Fragment, null, t(), g()), renderMobile: () => /* @__PURE__ */ e.createElement(r.Mobile, { title: f, label: "Destino" }, t(!0), E()) });
};
T.displayName = "Form";
export {
  T as default
};
