import e from "react";
import { FormContainer as t, CollapsableEngineButton as C } from "@cvccorp-engines/components";
import { useMotorToursContext as D } from "../../context/EngineToursContext.js";
import { useToursStore as f } from "../../store/ToursStore.js";
import s from "../ToursDefaultLocationForm/ToursDefaultLocationForm.js";
import { MotorContainer as u, CancelButtonContainer as E, ButtonContainer as g } from "./styled.js";
function p() {
  const {
    handleSearch: c
  } = D(), {
    destinations: n,
    date: o,
    allDestiny: r,
    errors: a,
    setAllDestiny: l,
    setDestinations: i,
    setDate: m
  } = f();
  return /* @__PURE__ */ e.createElement(t, { onSearch: c, formId: "form-tours", renderMobile: () => /* @__PURE__ */ e.createElement(t.Mobile, { title: "Circuito", label: "Destino" }, /* @__PURE__ */ e.createElement(u, { twoItems: !0 }, /* @__PURE__ */ e.createElement(s, { date: o, errors: a, allDestiny: r, destinations: n, onDateChange: m, onAllDestinyChange: l, onDestinationsChange: i }))), renderDesktop: () => /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement(E, { container: !0, justifyContent: "flex-end" }, /* @__PURE__ */ e.createElement(C, null)), /* @__PURE__ */ e.createElement(u, { twoItems: !0 }, /* @__PURE__ */ e.createElement(s, { date: o, errors: a, allDestiny: r, destinations: n, onDateChange: m, onAllDestinyChange: l, onDestinationsChange: i }, /* @__PURE__ */ e.createElement(g, null, /* @__PURE__ */ e.createElement(t.Submit, null))))) });
}
p.displayName = "SingleDestinationForm";
export {
  p as default
};
