import { useEngineNavigation as s, PRODUCTS_LINK_SUBMARINO as c, PRODUCTS_LINK_CVC as a, NAVIGATION_PRODUCTS as e } from "@cvccorp-engines/navigation";
import { useTheme as m } from "@emotion/react";
const I = (t) => t === "submarino" ? e.AIR : e.AIR;
function N() {
  const {
    currentProduct: t,
    pathname: r,
    products: o,
    setCurrentProduct: n
  } = s(), {
    name: u
  } = m();
  return {
    product: t,
    setProduct: n,
    pathname: r,
    products: o,
    productsLinks: u === "submarino" ? c : a
  };
}
export {
  N as default,
  I as getDefaultProductByTheme
};
