import { GTMProducts as o, gtm as a } from "@cvccorp-engines/components";
import { d as n } from "../../dayjs-CxYy674R.js";
function e(t) {
  const i = {
    product: o.BUSING,
    checkin_date: a.formatDate(t.calendarDates[0]),
    origin_city: t.destination.cityName,
    destination_city: t.destination.cityName,
    duration_days: t.isOneWay ? "" : n(t == null ? void 0 : t.calendarDates[0]).diff(n(t == null ? void 0 : t.calendarDates[1])).toString()
  };
  return t.isOneWay && Object.assign(i, {
    checkout_date: a.formatDate(t.calendarDates[1])
  }), i;
}
export {
  e as default
};
