import z, { createContext as J, useState as g, useCallback as Q, useMemo as W, useContext as X } from "react";
import { useIsomorphicLayoutEffect as Y } from "@cvccorp-components/chui-react-components";
import { getParams as r, useParsedLocalStorage as Z, LocalStorageKeys as $, gtm as L, redirectToSearch as q } from "@cvccorp-engines/components";
import { d as A } from "../../dayjs-CxYy674R.js";
import { formatPaxForService as tt, validateChildrenPaxDate as et, formatAgeToDate as rt } from "@cvccorp-engines/pax";
import nt from "../utils/formatGTMPayload.js";
import at from "../utils/formatUrlToSearch.js";
const ot = [{
  adult: 2,
  children: 0,
  childrenDateOfBirth: []
}], M = J(null), it = ({
  children: y,
  isAtlas: B
}) => {
  const [l, O] = g(() => r("SearchType") || "originAndDestination"), [e, U] = g(() => {
    const t = {
      origin: void 0,
      destination: void 0
    };
    return r("OriginId") && (t.origin = {
      name: r("CityOrigin"),
      city: {
        name: r("CityOrigin")
      },
      id: r("OriginId")
    }), r("DestinationId") && (t.destination = {
      name: r("City"),
      city: {
        name: r("City")
      },
      id: r("DestinationId")
    }), t;
  }), [f, v] = g(() => {
    let t = "";
    return r("TourCode") && (t = r("TourCode")), t;
  }), [h, N] = g(() => {
    if (r("Adults") || r("Children") || r("ChildAges") || r("Rooms")) {
      const t = [], n = (o) => o.map(Number), a = (o) => o.map((C) => rt(C)), d = () => r("ChildAges").split(";").map((C) => a(C.split(","))), m = r("Rooms") ? Number(r("Rooms")) : 1, i = r("Adults") ? n(r("Adults").split(",")) : [1], P = r("Children") ? n(r("Children").split(",")) : [1], c = r("ChildAges") ? d() : [];
      for (let o = 0; o < m; o++)
        t.push({
          adult: i[o],
          children: P[o],
          childrenDateOfBirth: c.length ? c[o] : c
        });
      return t;
    }
    return ot;
  }), [R, k] = g([]), [s, b] = g(() => {
    const t = [];
    return r("CheckIn") && (t[0] = A(r("CheckIn")).toDate()), r("CheckOut") && (t[1] = A(r("CheckOut")).toDate()), t;
  }), [G, V] = g({
    origin: !1,
    destination: !1,
    sameLocation: !1,
    pax: !1,
    dates: {
      startDate: !1,
      endDate: !1
    }
  }), T = l === "excursion", E = l === "originAndDestination", I = l === "origin", p = l === "destination", [D = [], _] = Z($.LAST_SEARCH_BUSING_CHARTER);
  Y(() => {
    if (D != null && D.length) {
      const t = D[0];
      if (r("SearchType") || O(t.searchType), h || N(t.pax), t.calendarDates.length && !s.length) {
        const n = t.calendarDates.map((a) => A(a).toDate());
        b(n);
      }
      t.searchType === "originAndDestination" && (e != null && e.origin || x(t.origin, "ORIGIN"), e != null && e.destination || x(t.destination, "DESTINATION")), t.searchType === "origin" && !(e != null && e.origin) && x(t.origin, "ORIGIN"), t.searchType === "destination" && !(e != null && e.destination) && x(t.destination, "DESTINATION"), t.searchType === "excursion" && !f && v(t.code);
    }
  }, []);
  const x = (t, n) => {
    k([]), U((a) => ({
      ...a,
      [n.toLowerCase()]: t
    }));
  }, H = (t) => {
    V((n) => ({
      ...n,
      ...t
    }));
  }, j = Q(() => {
    var o, C, S, w;
    const t = [!1, {}], n = !(e != null && e.origin), a = !(e != null && e.destination), d = (e == null ? void 0 : e.origin) && (e == null ? void 0 : e.destination) && ((C = (o = e == null ? void 0 : e.origin) == null ? void 0 : o.city) == null ? void 0 : C.name) === ((w = (S = e == null ? void 0 : e.destination) == null ? void 0 : S.city) == null ? void 0 : w.name), m = !f, i = !tt(h).length, P = !s.length, c = s.length !== 2;
    return E && (t[0] = [n, a, d, i, P].some((u) => u), t[1] = {
      origin: n,
      destination: a,
      sameLocation: d,
      pax: i,
      dates: {
        startDate: P,
        endDate: !1
      }
    }), I && (t[0] = [n, i, c].some((u) => u), t[1] = {
      origin: n,
      pax: i,
      dates: {
        startDate: s.length < 1,
        endDate: s.length < 2
      }
    }), p && (t[0] = [a, i, c].some((u) => u), t[1] = {
      destination: a,
      pax: i,
      dates: {
        startDate: s.length < 1,
        endDate: s.length < 2
      }
    }), T && (t[0] = [m, i].some((u) => u), t[1] = {
      code: m,
      pax: i,
      dates: {
        startDate: !1,
        endDate: !1
      }
    }), t;
  }, [e, f, h, s, T, E, I, p]), F = () => {
    const [t, n] = j(), [a] = et(h);
    if (V({
      ...n,
      pax: a
    }), t || a) return;
    const d = {
      origin: e == null ? void 0 : e.origin,
      destination: e == null ? void 0 : e.destination,
      code: f,
      pax: h,
      calendarDates: s,
      searchType: l
    }, m = at(d), i = nt(d);
    L.onEngineSearch(i), _([d, ...D]), q(m);
  }, K = W(() => ({
    searchType: l,
    setSearchType: O,
    pesqincLocationSelected: e,
    setPesqincLocationSelected: x,
    pesqincInputValue: f,
    setPesqincInputValue: v,
    calendarDateSelected: s,
    setCalendarDateSelected: b,
    errors: G,
    setErrors: H,
    paxValue: h,
    setPaxValue: N,
    startDate: R,
    setStartDate: k,
    handleSearch: F,
    isExcursion: T,
    isOriginAndDestination: E,
    isOrigin: I,
    isDestination: p,
    isAtlas: B
  }), [l, O, e, f, s, G, h, R, T, E, I, p, B]);
  return /* @__PURE__ */ z.createElement(M.Provider, { value: K }, y);
};
it.displayName = "EngineBusingCharterProvider";
function mt() {
  const y = X(M);
  if (!y) throw new Error("Chamada do hook useEngineBusingCharterContext inválida! Certifique-se que ele esta sendo chamado dentro do contexto EngineBusingCharterContext");
  return y;
}
export {
  M as EngineBusingCharterContext,
  it as EngineBusingCharterProvider,
  ot as defaultPaxValues,
  mt as useEngineBusingCharterContext
};
