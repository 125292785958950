import r from "react";
import f from "@cvccorp-components/chui-react-components/components/Divider";
import s from "@cvccorp-engines/date";
import g from "../../utils/calcAge.js";
import v from "../../utils/formatAgeToDate.js";
import d from "../Counter/Counter.js";
import p from "../Label/Label.js";
import x from "../SelectAge/SelectAge.js";
import { usePax as A } from "./PaxProvider/PaxProvider.js";
const I = () => {
  const {
    travelers: a,
    onChangeTraveler: n
  } = A(), {
    adult: o,
    children: i
  } = a;
  function m(e) {
    n({
      ...a,
      [e]: ++a[e]
    });
  }
  function c(e) {
    n({
      ...a,
      [e]: --a[e]
    });
  }
  function h(e, t) {
    const l = a;
    return l.childrenDateOfBirth[t] = e, n(l);
  }
  return /* @__PURE__ */ r.createElement(r.Fragment, null, /* @__PURE__ */ r.createElement(d, { label: "Adultos", helperText: "Acima de 18 anos", max: 9, onChange: (e) => {
    e > o ? m("adult") : c("adult");
  }, value: o }), /* @__PURE__ */ r.createElement(d, { label: "Crianças", helperText: "Até 17 anos", max: 9, onChange: (e) => {
    e > i ? m("children") : c("children");
  }, value: i }), a.children ? /* @__PURE__ */ r.createElement(r.Fragment, null, /* @__PURE__ */ r.createElement(f, { color: "gray-light", size: "sm", variant: "line", vertical: !1 }), Array.from(Array(a.children).keys()).map((e, t) => /* @__PURE__ */ r.createElement(p, { key: t, text: "Idade da criança", helperText: "ao voltar da viagem" }, /* @__PURE__ */ r.createElement("div", null, /* @__PURE__ */ r.createElement(x, { maxAge: 17, label: "Idade", onSelect: (l) => {
    const u = v(l);
    h(s(String(u)).toString(), t);
  }, value: String(g(a.childrenDateOfBirth[t])) || void 0, placeholder: "Idade" }))))) : null);
};
export {
  I as default
};
