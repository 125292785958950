import e from "react";
import { EngineBusingProvider as t } from "../EngineBusing/context/EngineBusingContext.js";
import { EngineBusingCharterProvider as o } from "../EngineBusingCharter/context/EngineBusingCharterContext.js";
import m from "./components/Form/Form.js";
import i from "./EngineProvider.js";
const a = ({
  isAtlas: r,
  engineNavigationType: n = "button"
}) => /* @__PURE__ */ e.createElement(i, null, /* @__PURE__ */ e.createElement(t, { isAtlas: r }, /* @__PURE__ */ e.createElement(o, { isAtlas: r }, /* @__PURE__ */ e.createElement(m, { engineNavigationType: n }))));
a.displayName = "Engine";
export {
  a as default
};
