import o from "../../libs/fetcher.js";
import "../../libs/cookies.js";
import { getHeadersBusing as c } from "../../resolver/getHeadersBusing.js";
const g = {
  ORIGIN: {
    isDestination: !1,
    isOrigin: !0,
    originCharter: !0
  },
  DESTINATION: {
    isDestination: !0,
    isOrigin: !1,
    destinationCharter: !0
  }
}, C = async ({
  searchString: a = "",
  size: i = "200",
  type: t,
  uniqueDirection: e,
  locationId: r,
  isAtlas: n
}) => {
  try {
    const {
      data: s
    } = await o.get("/api/static-busing/v1/cvc/charter/locations", {
      headers: c(n),
      params: {
        active: !0,
        charter: !0,
        charterTypes: "ROAD",
        ...a && {
          description: a
        },
        ...r && {
          id: r
        },
        ...e ? g[t] : {
          size: i,
          type: t
        },
        ...t === "DESTINATION" ? {
          destinationCharter: !0,
          originId: r
        } : {
          originCharter: !0,
          destinationId: r
        }
      }
    });
    return s;
  } catch {
    throw new Error("Error in Locations");
  }
}, m = async ({
  startDate: a,
  originId: i,
  destinationId: t,
  rooms: e,
  numberOfMonths: r,
  isAtlas: n
}) => {
  try {
    const {
      data: s
    } = await o.get("/api/static-busing/v1/packages/calendar", {
      headers: c(n),
      params: {
        productType: "ROAD",
        rooms: e.map((u) => u).join(" "),
        origin: i,
        destination: t,
        startCheckIn: a,
        numberOfMonths: r
      }
    });
    return s;
  } catch {
    throw new Error("Error in Calendar");
  }
};
export {
  m as getBusingCharterCalendar,
  C as getBusingCharterLocations
};
