"use client";
import u from "react";
import a from "@cvccorp-components/chui-react-components/components/Select";
import { useAutoCompleteItemSize as c } from "@cvccorp-engines/components";
const n = (t) => {
  const {
    value: o,
    placeholder: r,
    options: l,
    inputProps: p,
    label: s,
    onSelect: i,
    error: e
  } = t, m = c();
  return /* @__PURE__ */ u.createElement(a, { itemSize: m, label: s, options: l, placeholder: r, value: o, onSelect: i, inputProps: {
    ...p,
    size: "md"
  }, status: e ? "danger" : void 0, supportText: e });
};
n.displayName = "SelectHour";
export {
  n as default
};
