import { cookies as e } from "../libs/cookies.js";
const a = (t = {}) => {
  const n = e.get("transactionId");
  return {
    ...t,
    "transaction-id": n || "undefined",
    "auth-new-token": "true",
    "access-token": e.get("access_token")
  };
};
export {
  a as getHeadersAtlas
};
