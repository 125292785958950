import l, { createContext as r, useContext as n } from "react";
import "@cvccorp-components/chui-react-components/hooks/useResponsive";
import a from "../../hooks/useToggle/useToggle.js";
import "@cvccorp-components/chui-react-components";
import "../../dayjs-CxYy674R.js";
const e = r({}), i = ({
  children: o
}) => {
  const t = a();
  return /* @__PURE__ */ l.createElement(e.Provider, { value: t }, o);
};
i.displayName = "CollapsableEngineProvider";
const c = () => n(e);
export {
  i as default,
  c as useCollapsableEngine
};
