import u, { useMemo as T, useCallback as g } from "react";
import { EngineBookingPackages as S } from "@cvccorp-engines/booking-packages";
import { Engine as p } from "@cvccorp-engines/busing";
import { EngineCar as I } from "@cvccorp-engines/car";
import { EngineBox as N } from "@cvccorp-engines/components";
import { EngineFlight as l } from "@cvccorp-engines/flight";
import { EngineHotelsResorts as A } from "@cvccorp-engines/hotel";
import { NAVIGATION_PRODUCTS as e } from "@cvccorp-engines/navigation";
import { EnginePackagePackageFull as f, EnginePackageFull as d } from "@cvccorp-engines/package";
import G from "@cvccorp-engines/ticket";
import R from "@cvccorp-engines/tours";
import O from "@cvccorp-engines/travel-insurance";
import { useTheme as k } from "@emotion/react";
import { useEngineSearchContext as _ } from "../context/EngineSearchProvider/EngineSearchProvider.js";
import K, { getDefaultProductByTheme as h } from "./useEngineWrapper.js";
const B = {
  [e.AIR]: l,
  [e.HOTEL]: A,
  [e.PACKAGES]: f,
  [e.CARS]: I,
  [e.PACKAGE_COMPLETE]: f,
  [e.TICKETS]: G,
  [e.TOURS]: R,
  [e.DISNEY]: null,
  [e.TRAVEL_SECURITY]: O,
  [e.BUSING]: p,
  [e.BUSING_CHARTER]: p,
  [e.BOOKING_PACKAGES]: S
}, L = {
  [e.AIR]: l,
  [e.HOTEL]: A,
  [e.PACKAGE_COMPLETE]: d
};
function U() {
  const {
    name: n
  } = k(), r = h(n), {
    renderNavigation: t
  } = _(), o = T(() => n === "cvc" ? B : L, [n]), {
    product: i,
    pathname: E,
    productsLinks: c,
    setProduct: P
  } = K(), a = g((m) => ["/"].includes(E) ? r : n === "cvc" && E === c[e.PACKAGES] ? e.PACKAGE_COMPLETE : m, [E, r, c, n]), C = g(() => {
    const m = o[r], s = o[a(i)];
    return s || m;
  }, [o, i, r, a])();
  return /* @__PURE__ */ u.createElement(N, { className: "chui-engines" }, t == null ? void 0 : t({
    setProduct: P
  }), /* @__PURE__ */ u.createElement(C, { product: i }));
}
U.displayName = "EngineWrapper";
export {
  U as default
};
