import t from "@emotion/styled";
const e = t.div(() => ({
  borderRadius: "0.5rem",
  position: "absolute",
  top: 0,
  width: "100%",
  backgroundColor: "#e0e0e0",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 100
})), r = t.div(() => ({
  position: "absolute",
  top: 0,
  transform: "translateY(30%)"
}));
export {
  r as Indicator,
  e as Wrapper
};
