import { getParams as t } from "@cvccorp-engines/components";
const u = () => {
  var n;
  const o = t("originCode") || "", [i = "", r = "", s = ""] = ((n = t("location")) == null ? void 0 : n.split("-")) ?? [], e = {
    provider: t("provider") || "",
    description: "",
    cityId: o,
    cityName: i,
    stateName: r,
    countryName: s
  };
  return e.provider ? e : void 0;
}, l = () => {
  const o = Number(t("Adults") || 0), i = Number(t("Children") || 0), r = t("CheckIn") || "", s = t("CheckOut") || "", c = Number(t("Rooms") || 0), e = t("Location"), n = t("ZoneId") || "";
  return {
    adult: o,
    children: i,
    origDate: r,
    destDate: s,
    room: c,
    destDescription: e,
    destZoneId: n,
    durationUnit: "Milliseconds"
  };
}, m = () => {
  var o;
  return ((o = t("startDate")) == null ? void 0 : o.concat("T03:00:00.000Z")) ?? "";
};
export {
  u as getTicketDestinationInitialValue,
  m as getTicketStartDateInitialValue,
  l as getTicketsParamsForLogs
};
