import { getBaseHostname as O, formatDateToParam as C, encodeSearchURL as R } from "@cvccorp-engines/components";
import { countPaxRoom as x, calcChildAges as L } from "@cvccorp-engines/pax";
function U(e, S) {
  var n, t, i, r, s, o, c, d, p, h, P, g, l, m, u, D, y;
  const a = new URL(`${O()}/${S ? "pacotes" : "pacotes-turisticos"}/search`), {
    adultByRoom: f,
    childrenByRoom: I
  } = x(e.pax);
  return a.searchParams.append("IataOri", ((n = e.origin) == null ? void 0 : n.iata) || ""), a.searchParams.append("IataDes", ((t = e.destination) == null ? void 0 : t.iata) || ""), a.searchParams.append("CheckIn", C((i = e.calendarDates) == null ? void 0 : i.startDate) || ""), a.searchParams.append("CheckOut", C((r = e.calendarDates) == null ? void 0 : r.endDate) || ""), a.searchParams.append("Rooms", String((s = e.pax) == null ? void 0 : s.length)), a.searchParams.append("Adults", f || ""), a.searchParams.append("Children", I || ""), a.searchParams.append("ChildAges", L(e.pax)), a.searchParams.append("ZoneIdOrigin", String((o = e.origin) == null ? void 0 : o.zoneId) || ""), a.searchParams.append("ZoneId", String((c = e.destination) == null ? void 0 : c.zoneId) || ""), a.searchParams.append("OriginDesc", ((d = e.origin) == null ? void 0 : d.description) || ""), a.searchParams.append("DestinationDesc", ((p = e.destination) == null ? void 0 : p.description) || ""), a.searchParams.append("DestinationLat", String((h = e.destination) == null ? void 0 : h.latitude) || ""), a.searchParams.append("DestinationLon", String((P = e.destination) == null ? void 0 : P.longitude) || ""), a.searchParams.append("Classes", "eco"), a.searchParams.append("Direct", "false"), a.searchParams.append("CityOrigin", ((g = e.origin) == null ? void 0 : g.city) || ""), a.searchParams.append("City", ((l = e.destination) == null ? void 0 : l.city) || ""), a.searchParams.append("StateOrigin", ((m = e.origin) == null ? void 0 : m.state) || ""), a.searchParams.append("State", ((u = e.destination) == null ? void 0 : u.state) || ""), a.searchParams.append("CountryOrigin", ((D = e.origin) == null ? void 0 : D.country) || ""), a.searchParams.append("Country", ((y = e.destination) == null ? void 0 : y.country) || ""), R(a);
}
export {
  U as default
};
