import g from "react";
import { Pesqinc as p } from "@cvccorp-engines/components";
import { u as S } from "../../../EngineBookingPackagesContext-D4tagYeS.js";
import d from "./useFetchSingleBooking.js";
const f = ({
  searchType: e,
  isUniqueDirection: n,
  ...t
}) => {
  const {
    pesqincLocationSelected: i,
    setPesqincLocationSelected: c
  } = S(), {
    options: s,
    onSearch: r,
    renderOption: a,
    onSelect: l
  } = d({
    searchType: e,
    isUniqueDirection: n,
    onSelect: c
  });
  return /* @__PURE__ */ g.createElement(p, { ...t, onSearch: r, options: s, renderOption: a, onSelect: l, value: i[e.toLowerCase()], isOptionSelected: (o, m) => o ? o.id === m.value.id : !1 });
};
f.displayName = "SingleBooking";
export {
  f as default
};
