"use client";
import w, { createContext as y, useCallback as f, useMemo as A } from "react";
import { useIsomorphicLayoutEffect as x } from "@cvccorp-components/chui-react-components";
import { gtm as k, redirectToSearch as H } from "@cvccorp-engines/components";
import { d as o } from "../dayjs-CxYy674R.js";
import { CarsTags as I } from "../constants/dataLayerTags.js";
import z from "../hooks/useCarSearchHistory/useCarSearchHistory.js";
import { useCarsStore as M } from "../store/CarsStore.js";
import { formatDateAndTimeToDayJs as L } from "../utils/formatDateAndTimeToDayJS.js";
import { formatStateToGTM as b } from "../utils/formatStateToGTM.js";
import { formatUrlToSearch as j } from "../utils/formatUrlToSearch.js";
const G = y(void 0);
function J(D) {
  const {
    setErrors: n,
    setPickupDate: h,
    setPickupLocation: T,
    setPickupTime: S,
    setReturnDate: v,
    setReturnLocation: C,
    setReturnTime: R,
    setShowReturnLocation: g,
    ...e
  } = M(), {
    searchHistory: c,
    addValue: u
  } = z();
  x(() => {
    if (c.length && !e.pickupLocation) {
      const t = c[0], r = o(/* @__PURE__ */ new Date());
      T(t.pickupLocation), g(t.showReturnLocation), C(t.returnLocation), r.isAfter(t.pickupDate) || (S(t.pickupTime), h(o(t.pickupDate).toString()), v(o(t.returnDate).toString()), R(t.returnTime));
    }
  }, []);
  const s = f(() => {
    let t = !1;
    const r = {
      ...e.errors
    };
    return !e.pickupLocation || e.pickupLocation.zoneId <= 0 ? (r.pickupLocation = "Preencha o local de retirada", t = !0) : r.pickupLocation = !1, e.showReturnLocation && (!e.returnLocation || e.returnLocation.zoneId <= 0) ? (r.returnLocation = "Preencha o local de devolução", t = !0) : r.returnLocation = !1, e.pickupDate ? r.pickupDate = !1 : (r.pickupDate = "Preencha a data de retirada", t = !0), e.returnDate ? e.pickupDate && o(e.returnDate) < o(e.pickupDate) ? (r.returnDate = "A data de devolução deve ser posterior à data de retirada", t = !0) : r.returnDate = !1 : (r.returnDate = "Preencha a data de devolução", t = !0), e.pickupTime ? r.pickupTime = !1 : (r.pickupTime = !0, t = !0), e.returnTime ? r.returnTime = !1 : (r.returnTime = !0, t = !0), n({
      ...r
    }), t;
  }, [e, n]), p = f(() => {
    if (!s() && e.pickupLocation) {
      const {
        pickupLocation: t,
        returnLocation: r,
        pickupDate: m,
        returnDate: d,
        pickupTime: a,
        returnTime: i,
        showReturnLocation: P
      } = e, l = j({
        pickupLocation: t,
        returnLocation: r,
        pickupDate: m,
        returnDate: d,
        pickupTime: a,
        returnTime: i,
        showReturnLocation: e.showReturnLocation
      });
      u({
        pickupDate: L(m, a).toString(),
        pickupLocation: t,
        pickupTime: a,
        returnDate: L(d, i).toString(),
        returnLocation: r,
        returnTime: i,
        showReturnLocation: P
      }), k.pushEvent(I.SEARCH_CARS), k.onEngineSearch(b(e)), l && H(l);
    }
  }, [e, s, u]), E = A(() => ({
    handleSearch: p
  }), [p]);
  return /* @__PURE__ */ w.createElement(G.Provider, { ...D, value: E });
}
J.displayName = "EngineCarsProvider";
export {
  G as EngineCarsContext,
  J as default
};
